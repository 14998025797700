export type ApiType = {
  url: string;
  method: string;
  body: any;
  secured: boolean;
  forcedToken?: string;
};

export type ApiHeaderType = {
  method: string;
  headers?: any;
  body?: any;
};

export type ApiReturnDatas = {
  status: string,
  message: string,
  data: any
}

export const defaultReturnObject: ApiReturnDatas = {
  status: "error",
  message: "api_error",
  data: undefined
}