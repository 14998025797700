/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
//import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { getProject } from "Redux/Reducers/Project/ProjectSlice";
import {
	getFilters,
	setCurrentEvent,
	getCurrentEvent,
	getAmounts,
	loadEvents,
	setIsOpen,
	getModalAction,
	setModalAction,
} from "Redux/Reducers/Project/EventsSlice";

/** Components */
import Details from "Components/Pages/Project/Events/Details";

import List from "Components/Pages/Project/Views/ActivitiesMediasList";
import Grid from "Components/Pages/Project/Views/ActivitiesGrid";
import Filters from "Components/Pages/Project/Views/ActivitiesFilters";
import AddEvent from "Components/Pages/Project/Events/AddEvent";

/** utils */
import { setItem } from "Utils/Storage";

/** Images */
import gridButton from "Assets/Images/clear/filters/grid.png";
import listButton from "Assets/Images/clear/filters/list.png";

/** Types */
import { EventType } from "Types/Event";

/** Api */

import { getEvents, getFilesByTypes } from "Api/Controllers/EventController";

function Medias() {
	//const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const project = useAppSelector(getProject);
	const filters = useAppSelector(getFilters);
	const amounts = useAppSelector(getAmounts);
	const modalAction = useAppSelector(getModalAction);
	const currentEvent = useAppSelector(getCurrentEvent);

	const defaultFilters: any = { ...filters };

	const [ActiveFilters, setActiveFilters] = useState(defaultFilters);
	const [mode, setMode] = useState("grid");

	const [events, setEvents] = useState([] as any);
	const [files, setFiles] = useState([] as any);

	/** FUNCTIONS */

	const openDetails = (event: EventType) => {
		dispatch(setCurrentEvent(event));
		dispatch(setIsOpen(true));
	};

	const switchMode = (modeEvent: string) => {
		setMode(modeEvent);
	};

	const handleScrollList = (e: any) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;
		const newFilters = structuredClone(filters);

		if (scrollTop <= 5) {
			newFilters.page = newFilters.page ? newFilters.page - 1 : 1;
			//dispatch(setFilters(newFilters));
			increaseEvents(false);
		}
		if (scrollTop + clientHeight >= scrollHeight - 10) {
			// refresh events
			newFilters.page = newFilters.page ? newFilters.page + 1 : 2;
			increaseEvents(true);
		}
	};

	/** reload Events */

	const reloadEvents = (currentFilters: any) => {
		const activeFilters = structuredClone(currentFilters);
		activeFilters.id_project = project.id;
		activeFilters.page = 1;
		activeFilters.limit = 20;
		delete activeFilters.type;
		delete activeFilters.fileType;

		//activeFilters.type = "picture";
		//dispatch(setFilters(activeFilters));

		if (project.id) {
			getFilesByTypes(
				project.id,
				["picture", "video", "sound"],
				activeFilters
			).then((returnDatas: any) => {
				setFiles(Object.values(returnDatas));
			});
		}

		/*
		getEvents(activeFilters).then((returnDatas: any) => {
			if (returnDatas?.status === "success") {
				setEvents(returnDatas?.data.events);
			}
		});
		*/
	};

	/** increase Events */

	const increaseEvents = (add: boolean) => {
		const activeFilters = structuredClone(filters);
		if (add) {
			activeFilters.page = activeFilters.page ? activeFilters.page + 1 : 2;
		} else {
			activeFilters.page = activeFilters.page ? activeFilters.page - 1 : 1;
		}
		activeFilters.id_project = project.id;
		activeFilters.limit = 20;
		if (
			activeFilters.page > 1 &&
			(activeFilters.page - 1) * 20 <= amounts.events
		) {
			//dispatch(setFilters(activeFilters));
			getEvents(activeFilters).then((returnDatas: any) => {
				if (returnDatas?.status === "success") {
					const newEvents = [...events, ...returnDatas?.data.events];
					setEvents(newEvents);
				}
			});
		}
	};
	/**  */

	/** HOOKS */

	useEffect(() => {
		filters.search &&
			setActiveFilters({ ...ActiveFilters, search: filters.search });
	}, [filters.search]);

	useEffect(() => {
		reloadEvents(defaultFilters);
	}, [project]);

	useEffect(() => {
		//dispatch(setFilters(ActiveFilters));
		reloadEvents(ActiveFilters);
	}, [dispatch, ActiveFilters]);

	useEffect(() => {
		const newMode: string = mode ? mode : "list";
		setMode(newMode);
		setItem("project_mode", newMode);
	}, [mode]);

	// listen to event modification
	useEffect(() => {
		if (modalAction && modalAction !== "waiting") {
			modalAction === "add" && reloadEvents(filters);

			const updatingEvents = { ...events };

			const index = Object.values(updatingEvents).findIndex(
				(event: any) => event.id === currentEvent.id
			);

			if (modalAction === "edit" && currentEvent.id) {
				updatingEvents[index] = currentEvent;
				const eventsList = Object.values(updatingEvents);
				setEvents(eventsList);
			}

			if (modalAction === "delete" && currentEvent.id) {
				delete updatingEvents[index];
				const eventsList = Object.values(updatingEvents);
				setEvents(eventsList);
				setTimeout(() => {
					dispatch(loadEvents({}));
				}, 300);
			}

			dispatch(setModalAction("waiting"));
		}
	}, [modalAction]);

	return (
		<div className="tabContent files">
			<div className="filters container">
				<Filters
					hideTypes
					filtersParams={ActiveFilters}
					onFilter={(filters: any) => {
						setActiveFilters(filters);
						//reloadEvents(filters);
						//setFromAnotherPage(false);
					}}
				/>
			</div>
			<div className="filters title_actions">
				<div className="switch">
					<button
						className={mode === "list" ? "switchBtn active" : "switchBtn"}
						onClick={() => {
							switchMode("list");
						}}
					>
						<img src={listButton} alt="List" />
					</button>
					<button
						className={mode === "grid" ? "switchBtn active" : "switchBtn"}
						onClick={() => {
							switchMode("grid");
						}}
					>
						<img src={gridButton} alt="Grid" />
					</button>
				</div>
				<h3 className="title ">Galerie des médias</h3>
				<div className="spacer"></div>
				<AddEvent selected={["picture", "sound", "video"]} />
			</div>
			<Details />

			<AnimatePresence>
				<div className="scrollhandler" onScroll={handleScrollList}>
					{mode === "list" ? (
						<List files={files} onOpen={openDetails} />
					) : (
						<Grid files={files} onOpen={openDetails} />
					)}
				</div>
			</AnimatePresence>
		</div>
	);
}

export default Medias;
