/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import TextField from "Components/Parts/TextField";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { setNotification } from "Redux/Reducers/App/AppSlice";

import {
	getUser,
	setUser,
	getSettings,
	disconnect,
} from "Redux/Reducers/User/UserSlice";

import { postPassword } from "Api/Controllers/UserController";

import { setItem, removeItem, getItem } from "Utils/Storage";

function Generals() {
	const { t } = useTranslation();
	const user = useAppSelector(getUser);
	const settings = useAppSelector(getSettings);
	const dispatch = useAppDispatch();

	const [currentUser, setCurrentUser] = useState(user);
	const [settingsLoading, setSettingsLoading] = useState(false);
	const [passwordLoading, setPasswordLoading] = useState(false);
	const [isCompany, setIsCompany] = useState(false);
	const [copied, setCopied] = useState(false);

	const [errors, setErrors] = useState({
		profile: false,
		password: false,
		address: false,
	});

	const [success, setSuccess] = useState({
		profile: false,
		password: false,
		address: false,
	});

	const [newPassword, setNewPassword] = useState("");

	useEffect(() => {
		setCurrentUser(user);
		if ( user.vat )
			setIsCompany(true)
	}, [user]);

	const saveInfos = () => {
		dispatch(setUser(currentUser));
		setSettingsLoading(true);

		setTimeout(() => {
			setSettingsLoading(false);
			dispatch(
				setNotification({
					message: "Vos informations ont été enregistrées.",
					type: "success",
				})
			);
		}, 1000);
	};

	const handleCheckIsCompany = () => {

		setIsCompany(!isCompany);
	};

	const clipboardCopyUrl = (url: string) => {
		setCopied(true);
		navigator.clipboard.writeText(url);
		setTimeout(() => {
			setCopied(false);
		}, 3000);
	};

	const savePassword = () => {
		setPasswordLoading(true);

		if (newPassword) {
			postPassword(newPassword).then(
				(result: any) => {
					if (result.status === "success") {
						const token = result.data.token;
						if (token) {
							setItem("token", token);
							setTimeout(() => {
								setNewPassword("");
								setPasswordLoading(false);
								dispatch(
									setNotification({
										message: "Votre mot de passe à été modifié.",
										type: "success",
									})
								);
								window.location.reload();
							}, 500);
						}
					}
				},
				(error) => {
					console.log(error);
				}
			);
		} else {
			setErrors({ ...errors, password: true });
			setPasswordLoading(false);
		}
	};

	const placeholders = {
		firstname: t("ACCOUNT.FORM.LABEL.firstname"),
		lastname: t("ACCOUNT.FORM.LABEL.lastname"),
		email: t("ACCOUNT.FORM.LABEL.e-mail"),
		password: t("ACCOUNT.FORM.LABEL.password"),
		address: t("ACCOUNT.FORM.LABEL.address"),
		city: t("ACCOUNT.FORM.LABEL.city"),
		zip: t("ACCOUNT.FORM.LABEL.zip"),
		company: t("ACCOUNT.FORM.LABEL.company"),
		vat: t("ACCOUNT.FORM.LABEL.vat"),
	};
	return (
		<div className="tabContent row">
			<div className="col">
				<Card className="settings_card">
					<CardHeader title={t("ACCOUNT.LABELS.my_informations")} />
					<CardContent>
						<div className="row">
							<div className="col">
								<TextField
									placeholder={placeholders.firstname}
									value={currentUser.firstname}
									onUpdate={(firstname: string) => {
										const newUser = { ...currentUser, firstname: firstname };
										setCurrentUser(newUser);
									}}
								/>
							</div>
							<div className="col">
								<TextField
									placeholder={placeholders.lastname}
									value={currentUser.name}
									onUpdate={(name: string) => {
										const newUser = { ...currentUser, name: name };
										setCurrentUser(newUser);
									}}
								/>
							</div>
						</div>

						<TextField
							disabled={true || settings?.provider ? true : false}
							placeholder={placeholders.email}
							value={currentUser.email}
							onUpdate={(email: string) => {
								const newUser = { ...currentUser, email: email };
								setCurrentUser(newUser);
							}}
						/>
						{settings?.provider ? (
							<span className="lock_provider">
								{t("ACCOUNT.LABELS.lock_provider")}
							</span>
						) : (
							<span className="lock_provider">
								{t("ACCOUNT.LABELS.lock_email")}
							</span>
						)}
						<br />
						<br />

						<TextField
							placeholder={placeholders.address}
							value={currentUser?.address ? currentUser.address : ""}
							onUpdate={(address: string) => {
								const newUser = { ...currentUser, address: address };
								setCurrentUser(newUser);
							}}
						/>
						<div className="row">
							<div className="col">
								<TextField
									placeholder={placeholders.zip}
									value={currentUser?.zip ? currentUser.zip : ""}
									onUpdate={(zip: string) => {
										const newUser = { ...currentUser, zip: zip };
										setCurrentUser(newUser);
									}}
								/>
							</div>
							<div className="col">
								<TextField
									placeholder={placeholders.city}
									value={currentUser?.city ? currentUser.city : ""}
									onUpdate={(city: string) => {
										const newUser = { ...currentUser, city: city };
										setCurrentUser(newUser);
									}}
								/>
							</div>
						</div>

						<FormControlLabel
							control={
								<Switch checked={isCompany } name="checked-1" 
									onChange={() => {
										handleCheckIsCompany();
									}}
								/>
							}
							label={t("ACCOUNT.FORM.LABEL.iam_a_professional")}
							/>

						{ isCompany ? (
							<div className="row">
								<div className="col">
								<TextField
									placeholder={placeholders.company}
									value={currentUser?.company ? currentUser.company : ""}
									onUpdate={(company: string) => {
										const newUser = { ...currentUser, company: company };
										setCurrentUser(newUser);
									}}
								/>
								</div>
								<div className="col">

								<TextField
									placeholder={placeholders.vat}
									value={currentUser?.vat ? currentUser.vat : ""}
									onUpdate={(vat: string) => {
										const newUser = { ...currentUser, vat: vat };
										setCurrentUser(newUser);
									}}
								/>
								</div>
							</div>
							
						) : (<></>)}
						
					</CardContent>
					<CardActions disableSpacing className="settings_actions">
						{success.profile && (
							<span className="success_message">
								{t("ACCOUNT.LABELS.success_profile")}
							</span>
						)}
						<LoadingButton
							loading={settingsLoading}
							onClick={saveInfos}
							loadingPosition="start"
							startIcon={<SaveIcon />}
							variant="contained"
						>
							{t("ACCOUNT.ACTIONS.save")}
						</LoadingButton>
					</CardActions>
				</Card>
			</div>
			<div className="col">
				<Card className="settings_card">
					<CardHeader title={t("ACCOUNT.LABELS.transfer_email")} />

					<CardContent>
						<p>
						{t("ACCOUNT.LABELS.transfer_email_description")} <a href="https://www.saytoapp.com/utilisez-votre-adresse-sayto-pour-transferer-rapidement-des-informations-vers-votre-compte/" target="_blank" className="delete_button">En savoir plus.</a>
						</p>
						
						{copied && (
							<p><strong>
							{t("PROJECT.LABELS.ics_copied")}
							</strong></p>
						)}
						
						
						<div className="transferEmail">


							<a href={`mailto:${user.saytomail}`}>{user.saytomail}</a>


							<Button
										color="secondary"
										onClick={() =>
											clipboardCopyUrl(
												user.saytomail
											)
										}
									>
										<ContentCopyIcon />
									</Button>
						</div>
					</CardContent>
				</Card>
				<Card className="settings_card">
					<CardHeader title={t("ACCOUNT.LABELS.change_password")} />

					<CardContent>
						<TextField
							disabled={settings?.provider ? true : false}
							placeholder={placeholders.password}
							value={newPassword}
							type="password"
							error={errors.password}
							showPasswordIcon={true}
							onUpdate={(newPassword: string) => {
								setNewPassword(newPassword);
							}}
						/>
						{settings?.provider && (
							<span className="lock_provider">
								{t("ACCOUNT.LABELS.lock_provider")}
							</span>
						)}
					</CardContent>

					<CardActions disableSpacing className="settings_actions">
						{errors.password && (
							<span className="error_message">
								{t("ACCOUNT.LABELS.error_password")}
							</span>
						)}

						{success.password && (
							<span className="success_message">
								{t("ACCOUNT.LABELS.success_password")}
							</span>
						)}
						{(!settings?.provider ||
							settings.provider === undefined ||
							settings.provider === "") && (
							<LoadingButton
								loading={passwordLoading}
								onClick={savePassword}
								loadingPosition="start"
								startIcon={<SaveIcon />}
								variant="contained"
							>
								{t("ACCOUNT.ACTIONS.create_new_password")}
							</LoadingButton>
						)}
					</CardActions>
				</Card>
			</div>
		</div>
	);
}

export default Generals;
