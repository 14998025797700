// https://github.com/guillermodlpa/google-autocomplete-api-react-demo/blob/main/src/App.tsx

import React, { useRef, useState } from "react";
import {
	getSessionToken,
	getPlaceDetails,
	getSuggestions,
} from "../../Utils/Geocoder";
import CircularProgress from "@mui/material/CircularProgress";
import { C } from "@fullcalendar/core/internal-common";

const GoogleAutoCompleteField = (props: {
	placeholder?: string;
	onSelect: Function;
	className?: string;
	value: string;
}) => {
	const { placeholder, onSelect, className, value } = props;
	const [searchedValue, setSearchedValue] = useState("");
	const [suggestions, setSuggestions] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);

	const timeoutRef = useRef<NodeJS.Timeout>();

	const [sessionToken, setSessionToken] = useState<any>(undefined);

	/** FUNCTIONS */
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		setSearchedValue(newValue);

		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		if (!newValue || newValue.trim().length <= 2) {
			setSuggestions([]);
		} else {
			setLoading(true);
			getSuggestions(newValue, sessionToken).then(
				(newSuggestions: any) => {
					setSuggestions(newSuggestions);
					setLoading(false);
				},
				(error) => {
					console.log(error);
					setSuggestions([]);
					setLoading(false);
				}
			);
		}
	};

	const handleSuggestionSelected = async (suggestion: any) => {
		setSearchedValue(suggestion.description);
		setSuggestions([]);

		const placeDetail: any = await getPlaceDetails(
			suggestion.place_id,
			sessionToken
		);

		const address_object = {
			formatted_address: placeDetail?.formatted_address || "",
			address:
				placeDetail?.address_components?.find(
					(component: any) => component.types[0] === "route"
				)?.long_name || "",
			id_country:
				placeDetail?.address_components?.find(
					(component: any) => component.types[0] === "country"
				)?.short_name || "",
			city:
				placeDetail?.address_components?.find(
					(component: any) => component.types[0] === "locality"
				)?.long_name || "",
			zip:
				placeDetail?.address_components?.find(
					(component: any) => component.types[0] === "postal_code"
				)?.long_name || "",
		};

		onSelect(address_object);
	};

	/** HOOKS */
	React.useEffect(() => {
		if (!sessionToken) {
			getSessionToken().then((_sessionToken) => {
				setSessionToken(_sessionToken);
			});
		}
	}, [sessionToken]);

	React.useEffect(() => {
		setSearchedValue(value);
	}, [value]);

	/** RENDER */
	return (
		<div className={className && className + " google_autocomplete"}>
			{loading && <CircularProgress size={20} className="loader" />}
			<input
				id="location-input"
				placeholder={placeholder || "Enter a location"}
				onChange={handleChange}
				value={searchedValue || ""}
			/>

			{Array.isArray(suggestions) && suggestions.length > 0 && (
				<div className="suggestion">
					<ul style={{ listStyleType: "none", padding: "0" }} role="listbox">
						{suggestions.map((suggestion) => (
							<li
								key={suggestion.place_id}
								tabIndex={-1}
								role="option"
								aria-selected="false"
								onClick={() => handleSuggestionSelected(suggestion)}
							>
								{suggestion.description}
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default GoogleAutoCompleteField;
