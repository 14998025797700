/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/fr";
import dayjs from "dayjs";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
/** Types */
import { EventType } from "../../../../../Types/Event";

import {
	formateDateForPicker,
	formateTimeForPicker,
} from "Utils/DateFormatter";

function AddDate(props: { event: EventType; onAdd: Function; params?: any }) {
	const { event, onAdd, params } = props;

	const { t } = useTranslation();

	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");

	useEffect(() => {
		setStartDate("");
		setEndDate("");
	}, [params.modalInit]);
	useEffect(() => {
		const newEvent = { ...event };
		newEvent.event_start = startDate;
		newEvent.event_end = endDate;

		if (params?.is_meeting === true) {
			newEvent.attr.is_meeting = true;
		}

		onAdd(newEvent);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate]);

	return (
		<div>
			<div className="row date">
				<div className="col">
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
						<label>{t("TASK.start_date")}</label>
						<DatePicker
							className="picker"
							disablePast
							//disableMaskedInput={true}
							maxDate={dayjs(formateDateForPicker(new Date(endDate)).sql)}
							value={dayjs(formateDateForPicker(new Date(startDate)).sql)}
							onChange={(date: any) => {
								const newDate = date.$d;
								const newDates = formateDateForPicker(newDate);
								setStartDate(newDates.sql);
							}}
						/>
					</LocalizationProvider>
				</div>
			</div>
			<div className="row date">
				{params?.is_meeting ? (
					<div className="col">
						<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
							{" "}
							<label>{t("TASK.meeting_hour")}</label>
							<TimePicker
								ampm={false}
								minutesStep={10}
								className="picker"
								//disableMaskedInput={true}
								value={dayjs(formateTimeForPicker(new Date(startDate)).sql)}
								onChange={(date: any) => {
									const newDate = date.$d;
									const newDates = formateTimeForPicker(newDate);
									setStartDate(newDates.sql);
								}}
							/>
						</LocalizationProvider>
					</div>
				) : (
					<div className="col">
						<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
							{" "}
							<label>{t("TASK.end_date")}</label>
							<DatePicker
								disablePast
								className="picker"
								//disableMaskedInput={true}
								minDate={dayjs(formateDateForPicker(new Date(startDate)).sql)}
								value={dayjs(formateDateForPicker(new Date(endDate)).sql)}
								onChange={(date: any) => {
									const newDate = date.$d;
									const newDates = formateDateForPicker(newDate);
									setEndDate(newDates.sql);
								}}
							/>
						</LocalizationProvider>
					</div>
				)}
			</div>
		</div>
	);
}

export default AddDate;
