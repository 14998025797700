
import { store } from 'Redux/store';
import { setTitleFlow } from 'Redux/Reducers/App/AppSlice';

export const handleScrollFlow = (e: any) => {
    const dispatch = store.dispatch;


    const filters = document.querySelector('.filters');

    const filtersPosition = filters?.getBoundingClientRect();

    const flowDate = document.querySelectorAll('.dayElement');

    let hasDate = false;
    flowDate.forEach((item: any) => {
        const itemPosition = item.getBoundingClientRect();
        const label = item.getAttribute('data-label');

        if (filtersPosition) {
            if (filtersPosition.top >= itemPosition.top-30  && filtersPosition.top + filtersPosition.height <= itemPosition.top + itemPosition.height ) {
                hasDate = true;
                dispatch(setTitleFlow(label));
            }
        }
    });

    if(!hasDate){
       // dispatch(setTitleFlow(''));
    }
};