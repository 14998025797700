/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Confirm from "Components/Parts/Confirm";

import Details from "../Events/Details";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

import EventLine from "Components/Pages/Project/Events/Item";

import Filters from "Components/Pages/Project/Views/ActivitiesFilters";
import AddEvent from "Components/Pages/Project/Events/AddEvent";

import Empty from "Components/Parts/Empty";
import {
	convertStringToDate,
	convertStringToDatetime,
} from "Utils/DateFormatter";
import { formatFileSize } from "Utils/Locale";

/** API */
import {
	getEvents,
	deleteFileByName,
	getFilesByTypes,
} from "Api/Controllers/EventController";
/** Types */
import { EventType } from "Types/Event";

/** Redux */
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { getProject } from "Redux/Reducers/Project/ProjectSlice";
import {
	getFilters,
	getAmounts,
	setCurrentEvent,
	setIsOpen,
	clearEvents,
	getModalAction,
	getCurrentEvent,
	setModalAction,
	loadEvents,
} from "Redux/Reducers/Project/EventsSlice";

function Mails() {
	const { t } = useTranslation();
	const project = useAppSelector(getProject);
	const amounts = useAppSelector(getAmounts);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(true);
	const filters = useAppSelector(getFilters);
	const modalAction = useAppSelector(getModalAction);
	const currentEvent = useAppSelector(getCurrentEvent);

	const defaultFilters: any = { ...filters };

	const [filtersLoading, setFiltersLoading] = useState(true);
	const [ActiveFilters, setActiveFilters] = useState(defaultFilters);

	const [mails, setMails] = useState([] as any);

	/** Functions */

	const onOpen = (event: EventType) => {};

	const handleScrollList = (e: any) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;
		const newFilters = structuredClone(filters);

		if (scrollTop <= 5) {
			newFilters.page = newFilters.page ? newFilters.page - 1 : 1;
			//dispatch(setFilters(newFilters));
			increaseEvents(false);
		}
		if (scrollTop + clientHeight >= scrollHeight - 10) {
			// refresh events
			newFilters.page = newFilters.page ? newFilters.page + 1 : 2;
			setActiveFilters(newFilters);
			increaseEvents(true);
		}
	};

	const reloadEvents = (currentFilters: any) => {
		setLoading(true);
		const activeFilters = structuredClone(currentFilters);
		activeFilters.id_project = project.id;
		activeFilters.page = 1;
		activeFilters.limit = 20;
		activeFilters.type = "mail";

		if (project.id) {
			console.log("project id : ", project.id);
			getEvents(activeFilters).then((result: any) => {
				const newEvents = result.data.events;
				setMails(newEvents);

				setLoading(false);
			});
		} else {
			console.log("no proejct id");
		}
	};

	const increaseEvents = (add: boolean) => {
		const activeFilters = structuredClone(filters);
		if (add) {
			activeFilters.page = activeFilters.page ? activeFilters.page + 1 : 2;
		} else {
			activeFilters.page = activeFilters.page ? activeFilters.page - 1 : 1;
		}
		activeFilters.id_project = project.id;
		activeFilters.limit = 20;
		if (
			activeFilters.page > 1 &&
			(activeFilters.page - 1) * 20 <= amounts.events
		) {
			setLoading(true);
			getEvents(activeFilters).then(
				(returnDatas: any) => {
					if (returnDatas?.status === "success") {
						const newEvents = [...mails, ...returnDatas?.data.events];
						setMails(newEvents);
						setLoading(false);
					}
				},
				(error: any) => {
					console.log("error", error);
				}
			);
		}
	};

	/** Hooks */
	useEffect(() => {
		dispatch(clearEvents({}));
		setLoading(true);
	}, [dispatch, project.id]);

	useEffect(() => {
		setLoading(false);
	}, [mails]);

	useEffect(() => {
		reloadEvents(ActiveFilters);
	}, [project.id, ActiveFilters]);

	// listen to event modification
	useEffect(() => {
		if (modalAction && modalAction !== "waiting") {
			modalAction === "add" && reloadEvents(filters);

			const updatingEvents = { ...mails };

			const index = Object.values(updatingEvents).findIndex(
				(event: any) => event.id === currentEvent.id
			);

			if (modalAction === "edit" && currentEvent.id) {
				updatingEvents[index] = currentEvent;
				const eventsList = Object.values(updatingEvents);
				setMails(eventsList);
			}

			if (modalAction === "delete" && currentEvent.id) {
				delete updatingEvents[index];
				const eventsList = Object.values(updatingEvents);
				setMails(eventsList);
				setTimeout(() => {
					dispatch(loadEvents({}));
				}, 300);
			}

			dispatch(setModalAction("waiting"));
		}
	}, [modalAction]);

	return (
		<div className="tabContent mail">
			<Details />

			<div className="filters container">
				<Filters
					hideTypes
					filtersParams={{}}
					onFilter={(filters: any) => {
						console.log("filters from attr", filters);
						setActiveFilters(filters);
						//setFromAnotherPage(false);
					}}
				/>
			</div>
			<div className="filters title_actions">
				<h3 className="title">{t("EVENT.LABELS.list_mails")}</h3>
			</div>

			{loading ? (
				<Empty empty_label={t("EVENT.LABELS.loading_mails")} />
			) : (
				<>
					{mails.length === 0 ? (
						<span className="emptyProject">Aucun e-mail pour l'instant</span>
					) : (
						<div className={"event_flux"}>
							<div className="dayElement" onScroll={handleScrollList}>
								{loading && (
									<div className="loading">
										<CircularProgress />
									</div>
								)}

								{mails.map((event: any, index: number) => {
									return (
										<div
											key="index"
											className="event_flux_item"
											onClick={() => {
												dispatch(setCurrentEvent(event));
												dispatch(setIsOpen(true));
											}}
										>
											<div className="event_header">
												<div className={"type " + event.type}>
													<Tooltip title={t("TYPE." + event.type)}>
														<div>
															<ForwardToInboxIcon />
														</div>
													</Tooltip>
												</div>
												<div className="event_action">
													<strong>Vous</strong>
													<span> avez</span>
													&nbsp;
													<span>{t("TYPE.ACTIONS.add_" + event.type)}</span>
													<div className="event_created mail">
														<span>le </span>
														<span className="creation_day">
															{convertStringToDate(event.creation_date, false)}
														</span>
														<span> {t("GLOBAL.LABELS.at")} </span>
														{convertStringToDate(event.creation_date, true)}
													</div>
												</div>
											</div>
											<div className="event_content">
												<div className="main_content">
													<strong>Objet : </strong>
													<span> {event.title}</span>
													<div className="files">
														{event.attr?.files?.length > 0 ? (
															<>
																{event.attr?.files?.length > 1 ? (
																	<span>
																		{event.attr?.files?.length} Pièces jointes
																	</span>
																) : (
																	<span>1 Pièce jointe</span>
																)}
															</>
														) : (
															<span className="empty">Aucune pièce jointe</span>
														)}
													</div>
												</div>
											</div>
											<div className="event_footer"></div>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default Mails;
