import abstractApiObject from "./abstractApiObject";

class paymentsApiObject extends abstractApiObject {
	constructor() {
		super();
		this.controller = "payment";
	}

	postPaymentIntent(datas: { id_plan: number; mode: string }) {
		const params = {
			url: this.controller + "/intent",
			method: "POST",
			body: datas,
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}
}

export default paymentsApiObject;
