import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import ListItemText from "@mui/material/ListItemText";


import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { number_format } from "Utils/Locale";

import { useAppSelector } from "Redux/hooks";
import { getUser } from "Redux/Reducers/User/UserSlice";

import { removeItem } from "Utils/Storage";

import COLORS from "Utils/colors";

const Account = () => {
	const { t } = useTranslation();
	const user = useAppSelector(getUser);
	const navigate = useNavigate();

	const [showNav, setShowNav] = React.useState(false);

	const [userName, setUsername] = React.useState(
		user.firstname + " " + user.name
	);
	const [userEmail, setUserEmail] = React.useState(user.email);
	const [avatar, setAvatar] = React.useState(user.picture);

	const [usedSpace, setUsedSpace] = React.useState(0);
	const [totalSpace, setTotalSpace] = React.useState(0);
	const [usedSpacePercent, setUsedSpacePercent] = React.useState(0);

	const logout = () => {
		removeItem("token");
		removeItem("current_project");
		window.location.href = "/login";
	};

	React.useEffect(() => {
		const length = 20;
		let userName = user.firstname + " " + user.name;

		userName =
			userName.length > length
				? userName.substring(0, length) + "..."
				: userName;

		setUsername(userName);

		let userEmail = user.email;
		userEmail.length > userName.length
			? setUserEmail(userEmail.substring(0, userName.length) + "...")
			: setUserEmail(userEmail);

		user.picture === ""
			? setAvatar("https://gravatar.com/avatar?d=mp")
			: setAvatar(user.picture);

		const usedInMb = Math.round((user.used_space / 1024 / 1000) * 100) / 100;

		setUsedSpace(usedInMb);
		setTotalSpace(user.storagelimit);

		const percent = (usedInMb * 100) / user.storagelimit;
		setUsedSpacePercent(percent);
	}, [user]);

	return (
		<div className="right_user_actions">

			<Link
				to="/settings"
				className="settings_link"
			>
				<SettingsOutlinedIcon />
			</Link>

			<div
				onClick={() => setShowNav(!showNav)}
				className={showNav ? "userConnected open" : "userConnected"}
			>

				<img src={avatar} alt="user thumbnail" />

				<div className="infos">
					<strong>{userName}</strong>
					<span>{userEmail}</span>
				</div>
				<ExpandMoreIcon className={"moreIcon"} />
			</div>

			{showNav && (
				<Paper
					className="account_actions_menu"
					sx={{ width: 250, maxWidth: "100%" }}
				>
					<MenuList>
						<div className="userInfos">
							<img src={avatar} alt="user thumbnail" />
							<div className="infos">
								<strong>{user.firstname + " " + user.name}</strong>
								<span>{user.email}</span>
							</div>
						</div>
						<div className="used_space">
							<div className="space">
								<div className="progress">
									<div
										className="progress_bar"
										style={
											usedSpacePercent < 50
												? {
													backgroundColor: COLORS[7],
													width: usedSpacePercent + "%",
												}
												: usedSpacePercent < 80
													? {
														backgroundColor: COLORS[3],
														width: usedSpacePercent + "%",
													}
													: {
														backgroundColor: COLORS[0],
														width: usedSpacePercent + "%",
													}
										}
									></div>
								</div>
							</div>
							<span>
								{totalSpace >= 1000 ? (
									<>
										{number_format(usedSpace / 1000, 2, ',', '.')}
										{" / "}
										{totalSpace / 1000}
										{" Gb"}
									</>
								) : (
									<>
										{number_format(usedSpace, 2, ',', '.')}
										{" / "}
										{totalSpace}
										{" Mb"}
									</>
								)}
							</span>
						</div>
						<MenuItem
							onClick={() => {
								navigate("/account");
								setShowNav(false);
							}}
						>
							<ListItemText>{t("ACCOUNT.ACTIONS.myprofile")}</ListItemText>
						</MenuItem>

						<MenuItem
							onClick={() => {
								navigate("/account/subscription");
								setShowNav(false);
							}}
						>
							<ListItemText>{t("ACCOUNT.ACTIONS.mysubscription")}</ListItemText>
						</MenuItem>
						<MenuItem
							onClick={() => {
								navigate("/account/stats");
								setShowNav(false);
							}}
						>
							<span>{t("GLOBAL.NAVIGATION.stats")}</span>
						</MenuItem>

						<Divider />
						<MenuItem
							onClick={() => {
								navigate("/settings");
								setShowNav(false);
							}}
						>
							<span>{t("GLOBAL.NAVIGATION.params")}</span>
						</MenuItem>

						<Divider />
						<MenuItem className="logout" onClick={logout}>
							<ListItemText className="logout" color="red">
								{t("ACCOUNT.ACTIONS.logout")}
							</ListItemText>
						</MenuItem>
					</MenuList>
				</Paper>
			)}
		</div>
	);
};

export default Account;
