/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import { Helmet } from "react-helmet";

export const GooglePixel = () => {
	return (
		<Helmet>
			<script>
				{`<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-7KW355CDHT"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-7KW355CDHT');
</script>`}
			</script>
		</Helmet>
	);
};
