/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import DropUploader from "Components/Parts/DropUploader";

function AddFile(props: {
	type: string;
	onChange: Function;
	canClearFile: boolean;
}) {
	const { type, onChange } = props;

	return (
		<>
			<DropUploader
				type={type}
				callback={(files: any) => {
					onChange(files);
				}}
				canClearFile={props.canClearFile}
			/>
		</>
	);
}

export default AddFile;
