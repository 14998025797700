import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";

import DropUploader from "Components/Parts/DropUploader";

/** Icons */
import AddIcon from "@mui/icons-material/Add";

/** APi */

import { postFilesForEvent } from "Api/Controllers/EventController";
/** Redux */

import { useAppSelector, useAppDispatch } from "Redux/hooks";
import {
	getCurrentEvent,
	refreshEvents,
	loadEvents,
} from "Redux/Reducers/Project/EventsSlice";

const AddFile = (props: {
	isOpen: boolean;
	type: string;
	onClose: Function;
	
}) => {
	const { isOpen, onClose, type } = props;

	const event = useAppSelector(getCurrentEvent);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [open, setOpen] = useState(isOpen);

	const [selectedContributor, setSelectedContributor] = useState<any>(null);
	const [canClearFile, setCanClearFile] = useState(false);
	const [message, setMessage] = useState("");
	const [files, setFiles] = useState<any[]>([]);

	/** Functions */

	const handleClose = () => {
		setSuccess(false);
		setMessage("");
		setSelectedContributor(null);
		onClose();
		setLoading(false);
	};

	const saveFiles = () => {
		setLoading(true);
		const savingEvent = structuredClone(event);

		savingEvent.attr.files = files;

		postFilesForEvent(savingEvent.id, type, files).then((result: any) => {
			const newMedias = result?.attr?.files ? result.attr.files : [];
			setLoading(false);
			setFiles([]);
			dispatch(refreshEvents({}));
			dispatch(loadEvents({}));

			onClose(newMedias);
		});
	};
	/** Hooks */

	useEffect(() => {
		setCanClearFile( !isOpen);
		setOpen(isOpen);
	}, [isOpen]);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{t("EVENT.ACTIONS.ADDFILE." + type)}
			</DialogTitle>
			<DialogContent className="share_content">
				<DropUploader
					type={type}
					callback={(files: any) => {
						setFiles(files);
					}}
					canClearFile={canClearFile}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setOpen(false);
						onClose();
					}}
				>
					{t("GLOBAL.ACTIONS.cancel")}
				</Button>

				<LoadingButton
					size="small"
					onClick={() => {
						saveFiles();
					}}
					endIcon={<AddIcon />}
					loading={loading}
					loadingPosition="end"
					variant="contained"
					disabled={files.length === 0}
				>
					<span>Ajouter</span>
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default AddFile;
