import React, { useState, useEffect } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";
import EuroIcon from "@mui/icons-material/Euro";

function TextField(props: {
	className?: string;
	ref?: any;
	value?: string | number;
	placeholder?: string;
	onUpdate: Function;
	type?: string;
	showPasswordIcon?: boolean;
	icon?: any;
	onKeyPress?: Function;
	disabled?: boolean;
	error?: boolean;
	onBlur?: Function;
	onClick?: Function;
}) {
	const {
		value,
		placeholder,
		type,
		onUpdate,
		showPasswordIcon,
		icon,
		onKeyPress,
		disabled,
		error,
		onClick,
		onBlur,
		className,
	} = props;

	const [showPassword, setShowPassword] = useState(false);
	const [isComplete, setIsComplete] = useState(value === "" ? false : true);
	const [classNameContainer, setClassName] = useState([
		"textField",
		className ? className : "",
	]);

	const fieldRef = React.createRef<HTMLInputElement>();

	const handleFieldCompletion = (element: any) => {
		if (element.target.value.trim() === "" && type !== "numeric") {
			setIsComplete(false);
		} else {
			setIsComplete(true);
		}
	};

	useEffect(() => {
		let newClassName = [...classNameContainer];
		if (error) {
			!newClassName.includes("error") && newClassName.push("error");
		} else {
			newClassName = newClassName.filter((item: string) => item !== "error");
		}

		error && fieldRef.current?.focus();
		setClassName(newClassName);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);

	useEffect(() => {
		setIsComplete(value === "" ? false : true);

		let newClassName = [...classNameContainer];

		if (value !== "" && value !== undefined) {
			!newClassName.includes("complete") && newClassName.push("complete");
		} else {
			newClassName = newClassName.filter((item: string) => item !== "complete");
		}

		if (disabled) {
			!newClassName.includes("disabled") && newClassName.push("disabled");
		} else {
			newClassName = newClassName.filter((item: string) => item !== "disabled");
		}

		if (error) {
			!newClassName.includes("error") && newClassName.push("error");
		} else {
			newClassName = newClassName.filter((item: string) => item !== "error");
		}

		setClassName(newClassName);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<div className={classNameContainer.join(" ")}>
			<input
				ref={fieldRef}
				disabled={disabled}
				type={showPassword ? "text" : type}
				value={value}
				onChange={(element: any) => {
					handleFieldCompletion(element);
					onUpdate(element.target.value);
				}}
				onKeyUp={(e) => {
					onKeyPress && onKeyPress(e);
				}}
				onBlur={() => onBlur && onBlur()}
				onClick={() => onClick && onClick()}
			/>
			{showPasswordIcon && !disabled && (
				<button onClick={() => setShowPassword(!showPassword)}>
					{showPassword ? <VisibilityOff /> : <Visibility />}
				</button>
			)}
			{icon === "euro" && !disabled && (
				<div className="icon">
					<EuroIcon />
				</div>
			)}

			{disabled && (
				<div className="icon">
					<LockIcon />
				</div>
			)}

			{placeholder && (
				<>
					<label
						className={value === "" || value === undefined ? "full" : "top"}
					>
						{placeholder}
					</label>
				</>
			)}
		</div>
	);
}

export default TextField;
