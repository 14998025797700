/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

/** Components */
import { EventPropsType } from "Types/Event";
import EventLine from "Components/Pages/Project/Events/Item";

import { checkIfDateIsToday } from "Utils/DateFormatter";
/** Redux */

function Flux(props: EventPropsType) {
	const { onOpen, events } = props;

	const [formattedEvents, setFormattedEvents] = useState<any>({});

	useEffect(() => {
		const formatted: any = [];

		for (const event of events) {
			let dayStamp = Date.parse(event?.event_start?.split(" ")[0]);

			if (event?.creation_date) {
				dayStamp = Date.parse(event?.creation_date?.split(" ")[0]);
			}
			if (formatted[dayStamp]) {
				formatted[dayStamp][event.id] = event;
			} else {
				formatted[dayStamp] = {
					[event.id]: event,
				};
			}
		}
		setFormattedEvents(formatted);
	}, [events]);

	return (
		<>
			<div className="event_flux">
				{formattedEvents && Object.keys(formattedEvents).length > 0 ? (
					<>
						{Object.keys(formattedEvents).map(
							(dayStamp: any, index: number) => {
								let hide = false;

								const dayLabel = checkIfDateIsToday(dayStamp);

								if (index === 0) {
									hide = true;
								}

								return (
									<div
										key={index}
										className={"dayElement"}
										data-label={dayLabel}
									>
										<strong className={hide ? "day_line hide" : "day_line"}>
											{dayLabel}
										</strong>
										{Object.values(formattedEvents[dayStamp])
											.reverse()
											.map((event: any, subIndex: number) => {
												return (
													<EventLine
														key={subIndex}
														event={event}
														onOpen={onOpen}
													/>
												);
											})}
									</div>
								);
							}
						)}
					</>
				) : (
					""
				)}
			</div>
		</>
	);
}

export default Flux;
