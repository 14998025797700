import React from "react";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import FormControl from "@mui/material/FormControl";

import {useAppDispatch} from "Redux/hooks";
import {setCurrentEvent} from "Redux/Reducers/Project/EventsSlice";

import COLORS from "Utils/colors";

import TextField from "Components/Parts/TextField";

const fileTypes = [
	{value: "estimate", label: "Estimation"},
	{value: "invoice", label: "Dépense"},
	{value: "other", label: "Autre"},
];

const HeaderTypeSelector = (props: {event: any; onChange: Function}) => {
	const {event, onChange} = props;

	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	const [amount, setAmount] = React.useState(0);

	const [selectEventFileType, setSelectEventFleType] = React.useState({
		value: "-1",
		label: "",
	});

	const handleChangeFileType = (type: any) => {
		const newEvent = structuredClone(event);
		newEvent.attr.fileType = type.value;

		if (newEvent.attr.fileType === "other") {
			newEvent.attr.amount = 0;
			setAmount(0);
		}

		const selectedType = fileTypes.filter((element: any) => {
			return element.value === type.value ? element : false;
		});
		setSelectEventFleType(selectedType[0]);
		dispatch(setCurrentEvent(newEvent));
		onChange(true);
	};

	// Prepare events
	React.useEffect(() => {
		if (event.attr) {
			const attributes =
				typeof event.attr === "string" ? JSON.parse(event.attr) : event.attr;

			if (attributes) {
				if (attributes.amount) {
					const amount =
						typeof attributes.amount === "string"
							? parseFloat(attributes.amount.replaceAll(",", "."))
							: attributes.amount;

					setAmount(amount);
				}

				if (attributes.fileType) {
					let fileType =
						typeof attributes.fileType === "string"
							? attributes.fileType.replaceAll('"', "")
							: attributes.fileType;

					const selectedType = fileTypes.filter((element: any) => {
						return element.value === fileType ? element : false;
					});
					setSelectEventFleType(selectedType[0]);
				} else {
					setSelectEventFleType({
						value: "",
						label: "",
					});
				}
			}
		}
	}, [event]);

	return (
		<div className="file_actions">
			<div className="row">
				<div className="col">
					<FormControl
						className="select_field"
						variant="standard"
						sx={{m: 1, minWidth: 120}}
					>
						<Select
							classNamePrefix="react-select"
							aria-label="Nature de l'information"
							placeholder="Nature de l'information"
							options={fileTypes}
							value={
								selectEventFileType?.value !== "" ? selectEventFileType : null
							}
							name="fileTypes"
							onChange={(element: any) => {
								handleChangeFileType(element);
							}}
							theme={(theme) => ({
								...theme,
								borderRadius: 0,
								colors: {
									...theme.colors,
									primary25: "#e238324f",
									primary50: "#e2383287",
									primary: COLORS[0],
								},
							})}
						/>
					</FormControl>
				</div>
				<div className="col">
					{selectEventFileType?.value &&
						selectEventFileType.value !== "other" && (
							<FormControl
								className="select_field"
								variant="standard"
								sx={{m: 1, minWidth: 120}}
							>
								<TextField
									type="number"
									icon="euro"
									value={amount >= 0 ? amount : 0}
									onUpdate={(element: string) => {
										const newEvent = {...event};
										const newAttributes = {
											...newEvent.attr,
											amount: parseFloat(element),
										};
										newEvent.attr = newAttributes;
										onChange(true);
										dispatch(setCurrentEvent(newEvent));
									}}
								/>
							</FormControl>
						)}
				</div>
			</div>
		</div>
	);
};

export default HeaderTypeSelector;
