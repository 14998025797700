import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import GoogleAutoCompleteField from "Components/Parts/GoogleAutoCompleteField";

/** Form components */
import TextField from "Components/Parts/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextFieldMui from "@mui/material/TextField";

/** Icons */
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

/** Redux */

import { useAppSelector, useAppDispatch } from "Redux/hooks";
import {
	getProject,
	setCoordinates,
} from "Redux/Reducers/Project/ProjectSlice";

/** Editor */
import Editor from "Components/Parts/Editor";

/** Position */
import { getPosition } from "Utils/Geocoder";

function EditProject(props: {
	project: any;
	open: boolean;
	onClose: Function;
	onSave: Function;
}) {
	const { open, onClose, onSave, project } = props;

	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const [projectDatas, setProjectDatas] = useState(project);
	const [loading, setLoading] = useState(false);

	const [projectCountry, setProjectCountry] = useState<any>({
		code: "BE",
		label: "Belgique",
		suggested: true,
	});

	const handleEdit = () => {
		setLoading(true);
		onSave(projectDatas);
		handleEditAddress();
		onClose();
		setTimeout(() => {
			setLoading(false);
		}, 300);
	};

	const handleEditAddress = () => {
		const address =
			projectDatas.datas.address +
			" " +
			projectDatas.datas.zip +
			" " +
			projectDatas.datas.city;

		getPosition(address).then(
			(result) => {
				if (result) {
					dispatch(setCoordinates(result));
				}
			},
			(error) => {
				console.log(error);
			}
		);
	};

	const handleClose = () => {
		onClose();
	};

	useEffect(() => {
		setProjectDatas(project);
	}, [project]);

	const countries: readonly CountryType[] = [
		{ code: "AL", label: "Albanie", suggested: false },
		{ code: "DE", label: "Allemagne", suggested: true },
		{ code: "AD", label: "Andore", suggested: false },
		{ code: "AM", label: "Arménie", suggested: false },
		{ code: "AT", label: "Autriche", suggested: false },
		{ code: "BE", label: "Belgique", suggested: true },
		{ code: "BG", label: "Bulgarie", suggested: false },
		{ code: "HR", label: "Croatie", suggested: false },
		{ code: "CY", label: "Chypre", suggested: false },
		{ code: "CZ", label: "République Tchèque", suggested: false },
		{ code: "DK", label: "Danemark", suggested: false },
		{ code: "ES", label: "Espagne", suggested: false },
		{ code: "EE", label: "Estonie", suggested: false },
		{ code: "FI", label: "Finlande", suggested: false },
		{ code: "FR", label: "France", suggested: true },
		{ code: "GE", label: "Georgie", suggested: false },
		{ code: "GR", label: "Grêce", suggested: false },
		{ code: "HU", label: "Hongrie", suggested: false },
		{ code: "IS", label: "Icelande", suggested: false },
		{ code: "IE", label: "Irelande", suggested: false },
		{ code: "IT", label: "Italie", suggested: false },
		{ code: "XK", label: "Kosovo", suggested: false },
		{ code: "LV", label: "Latvia", suggested: false },
		{ code: "LI", label: "Liechtenstein", suggested: false },
		{ code: "LT", label: "Lituanie", suggested: false },
		{ code: "LU", label: "Luxembourg", suggested: true },
		{ code: "MT", label: "Malte", suggested: false },
		{ code: "MD", label: "Moldavie", suggested: false },
		{ code: "MC", label: "Monaco", suggested: false },
		{ code: "ME", label: "Montenegro", suggested: false },
		{ code: "MK", label: "Macédoine du Nord", suggested: false },
		{ code: "NO", label: "Norvège", suggested: false },
		{ code: "NL", label: "Pays-Bas", suggested: true },
		{ code: "PL", label: "Pologne", suggested: false },
		{ code: "PT", label: "Portugal", suggested: false },
		{ code: "RO", label: "Roumanie", suggested: false },
		{ code: "RS", label: "Serbie", suggested: false },
		{ code: "SK", label: "Slovaquie", suggested: false },
		{ code: "SI", label: "Slovénie", suggested: false },
		{ code: "SE", label: "Suède", suggested: false },
		{ code: "CH", label: "Suisse", suggested: false },
		{ code: "TR", label: "Turquie", suggested: false },
		{ code: "UA", label: "Ukraine", suggested: false },
		{ code: "GB", label: "Royaume Uni", suggested: false },
		{ code: "US", label: "Etas-Unis", suggested: false },
		{ code: "CA", label: "Canada", suggested: false },
	];

	return (
		<div className={open ? "modal add_project open" : "modal add_project"}>
			<div className="overlay" onClick={handleClose}></div>
			<div className="content">
				<div className="title">
					<button className="closeModal" onClick={handleClose}>
						<CloseIcon />
					</button>
					<span>Modifier mon </span>
					<strong>Projet</strong>
				</div>
				<div className="separator"></div>
				<div className="row">
					<div className="col md8">
						<TextField
							placeholder={"Nom de votre projet"}
							value={projectDatas?.datas?.name}
							onUpdate={(element: string) => {
								const newProject = structuredClone(projectDatas);
								newProject.datas.name = element;
								setProjectDatas(newProject);
							}}
						/>
					</div>
					<div className="col md4">
						<TextField
							placeholder={"Budget"}
							type="numeric"
							icon="euro"
							value={projectDatas?.datas?.budget}
							onUpdate={(element: string) => {
								const newProject = structuredClone(projectDatas);
								newProject.datas.budget = parseFloat(element)
									? parseFloat(element)
									: 0;
								setProjectDatas(newProject);
							}}
						/>
					</div>
				</div>

				<div className="sep row">
					<div className="col">
						<div className="row">
							<div className="col">
								<GoogleAutoCompleteField
									value={projectDatas?.datas?.address}
									className="address_field"
									placeholder="Adresse complète"
									onSelect={(address: any) => {
										const newProject = structuredClone(projectDatas);
										newProject.datas.address = address.address;
										newProject.datas.zip = address.zip;
										newProject.datas.city = address.city;
										newProject.datas.id_country = address.id_country;
										setProjectDatas(newProject);

									
										const selectedCountry = countries.find(
											(country) => country.code === address.id_country
										);
										
										setProjectCountry(selectedCountry);
									}}
								/>
							</div>
							<div
								className="col"
								style={{
									alignItems: "center",
									padding: 0,
									fontSize: 12,
									marginTop: -11,
								}}
							>
								<Autocomplete
									id="country-select"
									sx={{ width: 200 }}
									options={countries}
									onChange={(event, countries) => {
										const newProject = structuredClone(projectDatas);
										newProject.datas.id_country = countries?.code;
										setProjectDatas(newProject);
									}}
									autoHighlight
									value={projectCountry}
									style={{ padding: 0, fontSize: 12, margin: "1em 0" }}
									renderOption={(props, option) => (
										<Box
											component="li"
											sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
											{...props}
										>
											<img
												loading="lazy"
												width="20"
												srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
												src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
												alt=""
											/>
											{option.label}
										</Box>
									)}
									renderInput={(params) => (
										<TextFieldMui
											{...params}
											placeholder="Pays"
											className=""
											variant="outlined"
											size="small"
											style={{ padding: 0, fontSize: 11 }}
										/>
									)}
								/>
							</div>
						</div>
					</div>
				</div>

				<Button
					className="action_button"
					variant="contained"
					onClick={handleEdit}
				>
					{loading ? <CircularProgress /> : <>{t("GLOBAL.ACTIONS.save")}</>}
				</Button>
			</div>
		</div>
	);
}

interface CountryType {
	code: string;
	label: string;
	suggested?: boolean;
}

export default EditProject;
