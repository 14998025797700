import React from "react";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

import {useAppDispatch} from "Redux/hooks";
import {setCurrentEvent} from "Redux/Reducers/Project/EventsSlice";

const HeaderTask = (props: {event: any; onChange: Function}) => {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();

	const {event, onChange} = props;

	return (
		<Button
			onClick={() => {
				const newEvent = structuredClone(event);
				newEvent.attr.status = "done";
				onChange(true);
				dispatch(setCurrentEvent(newEvent));
			}}
			variant="outlined"
			className={
				event?.attr?.status === "done" ? "done task_actions" : "task_actions"
			}
		>
			<CheckCircleOutlinedIcon />
			<span>
				{event?.attr?.status === "done"
					? t("TASK.STATUS.done")
					: t("TASK.ACTIONS.check_as_done")}
			</span>
		</Button>
	);
};

export default HeaderTask;
