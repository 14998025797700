import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";

import COLORS from "Utils/colors";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import StarIcon from "@mui/icons-material/Star";
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";

import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { setError } from "Redux/Reducers/App/AppSlice";
import { getPlan, checkToken } from "Redux/Reducers/User/UserSlice";

import config from "Config";
import {
	getProject,
	setProject,
	getCoordinates,
	deleteProject,
} from "Redux/Reducers/Project/ProjectSlice";

const ShareAgendaDialog = (props: {
  open: boolean;
  onClose: Function;
}) => {
  const { open, onClose } = props;

  const navigate = useNavigate();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [copied, setCopied] = useState(false);
  const dispatch = useAppDispatch();
  const currentPlan = useAppSelector(getPlan);
  const project = useAppSelector(getProject);
	const { datas } = project;
  const [tmpProject, setTmpProject] = useState(project);

  /** Functions */
  const handleClose = () => {
    onClose();
    setLoading(false);
  };

  const controlStateIcs = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newProject = structuredClone(project);
		newProject.datas.sharing_ics = event.target.checked;
		dispatch(setProject(newProject));
	};

  const clipboardCopyUrl = (url: string) => {
		setCopied(true);
		navigator.clipboard.writeText(url);
		setTimeout(() => {
			setCopied(false);
		}, 3000);
	};


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
      <ShareIcon /> {t("PROJECT.LABELS.share_agenda")}
      </DialogTitle>
      <DialogContent className="share_content">
        <DialogContentText id="alert-dialog-description">
            <p>{t("PROJECT.LABELS.share_agenda_desc")}</p>


			{parseInt(currentPlan?.email) === 0 ? (

				<>
				<strong>
					<p>{t("GLOBAL.MARKETING.upgrade_dialog_desc")}</p>
					<p>{t("GLOBAL.MARKETING.upgrade_dialog_desc2")}</p>
				</strong>
				<div
				className="need_upgrade"
				onClick={() => {
					navigate("/account/subscription");
				}}
				>
					<StarIcon />
					<span>Upgrade</span>
				</div>
				</>
			) : (
				<>
				<FormControlLabel
				control={
					<Switch
						checked={datas.sharing_ics}
						onChange={controlStateIcs}
					/>
				}
				label={t("PROJECT.LABELS.active_ics_sharing")}
				/>
				{ datas.sharing_ics ? (
					<div className="sharing_ics">
						<span>{t("PROJECT.LABELS.ics_url")}: </span>
						<a
							target="_blank"
							href={config.apiUrl + "calendar/ics/" + datas.uuid}
							rel="noreferrer"
						>
							{config.apiUrl}calendar/ics/{datas.uuid}
						</a>

						{copied && (
							<span className="ics_copied">
								{t("PROJECT.LABELS.ics_copied")}
							</span>
						)}
						<Button
							color="secondary"
							onClick={() =>
								clipboardCopyUrl(
									config.apiUrl+"calendar/ics/" + datas.uuid
								)
							}
						>
							<ContentCopyIcon />
						</Button>
					</div>
				) : 
				("")}
				</>

			)}

        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("GLOBAL.ACTIONS.close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareAgendaDialog;
