import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Tooltip from "@mui/material/Tooltip";

import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import Logo from "Assets/Images/logo/logo.png";


function Navigation(props: { current: string | undefined }) {

	const { t } = useTranslation();

	return (
		<div className={"navigation"}>
			<img className="app_icon" src={Logo} alt="Sayto" />

			<div className={"menu"}>
				<Link
					to={props.current === "project" ? "" : "/project"}
					className={props.current === "project" ? "active" : ""}
				>
					<CottageOutlinedIcon />
					<span>{t("GLOBAL.NAVIGATION.home")}</span>
				</Link>

				{/*
        <Link
          to={"/project/"+currentProjectId}
          className={props.current === "project" ? "active" : ""}
        >
          <img src={projectIcon} alt="User" />
        </Link>
  */}
				<Link
					to="/reports"
					className={props.current === "reports" ? "active" : ""}
				>
					<LeaderboardOutlinedIcon />
					<span>{t("GLOBAL.NAVIGATION.stats")}</span>
				</Link>
				<Link
					to="/settings"
					className={props.current === "settings" ? "active" : ""}
				>
					<SettingsOutlinedIcon />
					<span>{t("GLOBAL.NAVIGATION.params")}</span>
				</Link>

				<Link
					to="/account"
					className={props.current === "account" ? "active" : ""}
				>
					<Tooltip title={t("GLOBAL.NAVIGATION.profile_tooltip")}>
						<AccountCircleOutlinedIcon />
					</Tooltip>
					<span>{t("GLOBAL.NAVIGATION.profile")}</span>
				</Link>
			</div>
		</div>
	);
}

export default Navigation;
