/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import emptyProject from "Assets/Images/clear/default/empty_project.png";

/** Modules  */
import Notifications from "Components/Parts/Notifications";
import AccountIcon from "Components/Parts/Account";
import Logout from "Components/Parts/Logout";
import AddProject from "Components/Pages/Project/AddProject";

/**  API */
import ProjectApi from "Api/Models/Project";
import { ProjectState } from "Types/Project";

/** Redux */
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { getUserId, getUser } from "Redux/Reducers/User/UserSlice";
import { setError } from "Redux/Reducers/App/AppSlice";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

/** Images */
const logo = require("Assets/Images/logo/logo.png");

const projectApi = new ProjectApi();

const defaultProjectsList = [] as ProjectState[];

function Dashboard() {
  const { t } = useTranslation();
  const currentUser: any = useAppSelector(getUser);
  const currentUserId = useAppSelector(getUserId);
  const [openModalProject, setOpenModalProject] = useState(false);
  const [projects, setProjects] = useState(defaultProjectsList);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (currentUserId) {
      projectApi.getAll({ id_user: currentUserId }).then(
        (result: any) => {
          setProjects(result);
          setLoading(false);
        },
        (error) => {
          dispatch(setError(error));
          setLoading(false);
        }
      );
    }
  }, [currentUserId, dispatch]);

  
  return (
    <div className="Page dashboard">
      <header>
        <AddProject
          open={openModalProject}
          onClose={() => {
            setOpenModalProject(false);
          }}
        />
        <div className="pageName">
          <img className="logo" src={logo} alt="Sayto" />
        </div>
        <AccountIcon />
      </header>

      <div className="content">
        <div className="row">
          {loading ? (
            <div className="col greeting">
              <div className="row">
                <Skeleton
                  className=""
                  variant="circular"
                  width={60}
                  height={60}
                  style={{ marginRight: 25 }}
                />
                <Skeleton variant="rectangular" width={250} height={25} />
              </div>
            </div>
          ) : (
            <div className="col greeting">
              <div className="row">
                {currentUser.picture && (
                  <img src={currentUser.picture} alt="User avatar" />
                )}

                <div className="col">
                  <strong>{t("DASHBOARD.hello")}</strong>
                  <div className="row name">
                    {currentUser.firstname ? (
                      <span>
                        {currentUser.firstname} {currentUser.name}
                      </span>
                    ) : (
                      <span>{currentUser.email}</span>
                    )}
                    <Link to="/account">
                      <SettingsOutlinedIcon />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {loading ? (
          <div className="row">
            <div className="module col md6">
              <Skeleton variant="rectangular" width={250} height={25} />
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="module col md6">
              {projects && projects.length ? (
                <>
                  <div className="module_header">
                    <strong>{t("PROJECT.listing_title")}</strong>
                    <Button
                      className="add_project_button"
                      variant="contained"
                      onClick={() => {
                        setOpenModalProject(true);
                      }}
                    >
                      <AddRoundedIcon />
                      {t("PROJECT.ACTIONS.create")}
                    </Button>
                  </div>
                  <div className="module_content list">
                    {projects &&
                      projects.map((project: any, index: number) => {
                        return (
                          <Link
                            className="item"
                            key={index}
                            to={"/project/" + project.uuid}
                          >
                            <div className="thumb">
                              {project?.name?.substring(0, 1)}
                            </div>
                            <div className="name">{project.name}</div>
                            <div className="actions">
                              <button>
                                {t("PROJECT.ACTIONS.see_project")}
                              </button>
                            </div>
                          </Link>
                        );
                      })}
                  </div>
                </>
              ) : (
                <div className="start_project">
                  <img src={emptyProject} alt="Empty project" />
                  <strong>{t("DASHBOARD.start_new_project")}</strong>

                  <Button
                    className="add_project_button"
                    variant="contained"
                    onClick={() => {
                      setOpenModalProject(true);
                    }}
                  >
                    <AddRoundedIcon />
                    {t("PROJECT.ACTIONS.create")}
                  </Button>
                </div>
              )}
            </div>
            <div className="module col md6">
              <Card sx={{ margin: 2 }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {t("DASHBOARD.NEXT_RDV.title")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t("DASHBOARD.NEXT_RDV.empty_message")}
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{ margin: 2 }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {t("DASHBOARD.NEXT_TASK.title")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t("DASHBOARD.NEXT_TASK.empty_message")}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
