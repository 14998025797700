import {ApiType, ApiHeaderType} from "Types/Api";
import {getItem} from "Utils/Storage";

import {store} from "Redux/store";

import {setError} from "Redux/Reducers/App/AppSlice";

import Config from "Config";

const token = getItem("token");

class abstractApiObject {
	controller!: string;
	apiUrl: string;

	constructor() {
		this.apiUrl = Config.apiUrl;
	}

	call(params: ApiType) {
		return new Promise((resolve, reject) => {
			const init: ApiHeaderType = {
				method: params.method,
				headers: {},
			};

			if (params.method === "POST") {
				init.headers = {
					Accept: "application/json",
					"Content-Type": "application/json",
				};
				init.body = JSON.stringify(params.body);
			}

			if (params.secured) {
				if (params.forcedToken && params.forcedToken !== "") {
					init.headers.Authorization = "Bearer " + params.forcedToken;
				} else {
					init.headers.Authorization = "Bearer " + token;
				}
			}

			const url = this.apiUrl + params.url;

			try {
				fetch(url, init)
					.then(
						(response) => {
							try {
								response.json().then(
									(datas) => {
										if (response.status !== 200) {
											store.dispatch(
												setError({
													type: "api_error",
													message: datas?.message
														? datas.message
														: "API.ERRORS.fetch_error",
												})
											);

											reject(datas);
										} else if (datas?.status === "error") {
											reject(datas);
										} else if (!datas) {
											reject(datas);
										} else {
											resolve(datas);
										}
									},
									() => {
										reject({
											type: "response_datas",
											message: "API.ERRORS.JSON_error_datas",
										});
									}
								);
							} catch (e) {
								reject({
									type: "response_parsing",
									message: "API.ERRORS.JSON_fail_parsing",
								});
							}
						},
						() => {
							reject({
								type: "fetch_error",
								message: "API.ERRORS.fetch_error",
							});
						}
					)
					.catch(() => {
						reject({
							type: "fetch_error",
							message: "API.ERRORS.fetch_error",
						});
					});
			} catch (e) {
				reject({
					type: "response_error",
					message: "API.ERRORS.catch_fetch_error",
				});
			}
		});
	}

	get() {
		const params = {
			url: this.controller,
			method: "GET",
			body: {},
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	getOne(id: number | string | undefined) {
		const params = {
			url: this.controller + "/" + id,
			method: "GET",
			body: {},
			secured: true,
		};
		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	post(datas: any) {
		return new Promise((resolve, reject) => {
			const params = {
				url: this.controller,
				method: "POST",
				body: datas,
				secured: true,
			};

			this.call(params).then(
				(result) => {
					console.log("RESOLVE FROM POST API", result);
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	put(datas: any) {
		const params = {
			url: this.controller + "/" + datas.id,
			method: "POST",
			body: datas,
			secured: true,
		};

		return new Promise((resolve, reject) => {
			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	delete(id: number) {
		return new Promise((resolve, reject) => {
			const params = {
				url: this.controller + "/" + id,
				method: "delete",
				body: {},
				secured: true,
			};

			this.call(params).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}
}
export default abstractApiObject;
