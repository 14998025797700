/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "Redux/hooks";
import { getUser, getPlan } from "Redux/Reducers/User/UserSlice";


/** Modules  */
import AccountIcon from "Components/Parts/Account";

import Tabs from "./Tabs";

/** Views */

import Generals from "./Views/Generals";
import Subscription from "./Views/Subscription";
import Transactions from "./Views/Transactions";
import Security from "./Views/SecurityAndPrivacy";
import Stats from "./Views/Stats";

/** API  */

/** Images */
import Logo from "Assets/Images/logo/logo.png";

function Account() {
	const currentPlan = useAppSelector(getPlan);
	const { t } = useTranslation();
	const { id } = useParams();
	const current = id ? id : "settings";

	const user = useAppSelector(getUser);

	return (
		<div className="Page account">
			<header>
				<Link to="/project">
					<img className="app_icon" src={Logo} alt="Sayto" />
				</Link>
				<div className="pageName">
					<div className="avatar">
						{user.picture && <img src={user.picture} alt="User avatar" />}
					</div>
					<div className="infos">
						<strong>
							{user.firstname} {user.name}
						</strong>
						<span>{user.email}</span>
						<span className="plan">{currentPlan.name}</span>
					</div>
				</div>
				<AccountIcon />
			</header>

			<div className="infos">
				<Tabs />
			</div>

			<div className="content">
				{current === "settings" && <Generals />}
				{current === "subscription" && <Subscription />}
				{current === "transactions" && <Transactions />}
				{current === "security_and_privacy" && <Security />}
				{current === "stats" && <Stats />}
			</div>
		</div>
	);
}

export default Account;
