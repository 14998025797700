/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import GoogleAutoCompleteField from "Components/Parts/GoogleAutoCompleteField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/fr";
import dayjs from "dayjs";

import Alert from "@mui/material/Alert";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getUser, setUser, checkToken } from "Redux/Reducers/User/UserSlice";

import Editor from "Components/Parts/Editor";
import { useAppDispatch, useAppSelector } from "Redux/hooks";

import ProjectApi from "Api/Models/Project";

import { defaultProject } from "Types/Project";

import logo from "Assets/Images/logo/logo.png";

import { postEvent } from "Api/Controllers/EventController";

import { formateDateForPicker } from "Utils/DateFormatter";

const projectApi = new ProjectApi();

const today = new Date();

function Wizard() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const user = useAppSelector(getUser);
	const [step, setStep] = React.useState(1);
	const [currentUser, setCurrentUser] = React.useState(user);
	const [currentProject, setCurrentProject] = React.useState(defaultProject);
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [animated, setAnimated] = React.useState(false);
	const [postLoading, setPostLoading] = React.useState(false);

	const [startDate, setStartDate] = React.useState(today.toISOString());
	const [endDate, setEndDate] = React.useState(today.toISOString());

	const finishWizard = () => {
		setPostLoading(true);

		const currentProjectToSave = { ...currentProject };
		if (currentProjectToSave?.name) {
			const now = new Date();
			now.setHours(now.getHours() + 1);

			currentProjectToSave.start_date = now
				.toISOString()
				.slice(0, 19)
				.replace("T", " ");
			const userToSave = structuredClone(currentUser);

			if (userToSave.id) {
				currentProjectToSave.id_user =
					typeof userToSave.id === "string"
						? parseInt(userToSave.id)
						: userToSave.id;
			}

			currentProjectToSave.attr = { is_new: true };

			//dispatch(setProject({ datas: currentProject }));

			if (currentProjectToSave.id_user) {
				projectApi.post(currentProjectToSave).then(
					(result: any) => {
						if (result.id) {
							checkPostEvent(result);
						}
					},
					(error) => {
						console.log(error);
					}
				);
			} else {
				setPostLoading(false);
			}
		}
	};

	const nextStep = () => {
		switch (step) {
			case 1:
				//setAnimated(false);
				setStep(2);
				setTimeout(() => {
					const input_to_focus = document.getElementById("project_name");
					input_to_focus?.focus();
				}, 300);

				break;
			case 2:
				const input_to_focus = document.getElementById("project_name");

				if (currentProject.name) {
					input_to_focus?.classList.remove("error");

					//setAnimated(false);
					setTimeout(() => {
						const input_to_focus: any = document.querySelector(
							"#google_autocomplete input"
						);
						input_to_focus?.focus();
					}, 300);
					setStep(3);
				} else {
					setError(true);
					input_to_focus?.classList.add("error");
					input_to_focus?.focus();
				}
				break;
			case 4:
				//setAnimated(false);
				setTimeout(() => {
					const input_to_focus = document.getElementById("input_amount");
					input_to_focus?.focus();
				}, 300);
				setStep(5);

				break;

			default:
				//setAnimated(false);
				setStep(step + 1);
				break;
		}
		setTimeout(() => setError(false), 3000);
	};

	const checkPostEvent = (project: any) => {
		setPostLoading(false);

		if (project.uuid && project.uuid !== "") {
			const userToSave = structuredClone(currentUser);

			userToSave.wizard_done = true;
			dispatch(setUser(userToSave));

			// Create Event for start and end project */
			if (startDate && new Date(startDate) !== new Date()) {
				const formattedStartDate = formateDateForPicker(
					new Date(startDate)
				).sql;

				const newEvent = {
					id_project: project.id,
					id_user: userToSave.id,
					event_start: formattedStartDate,
					event_end: formattedStartDate,
					type: "date",
					title: "Début du chantier",
				};

				postEvent(newEvent);
			}
			if (endDate && new Date(endDate) !== new Date()) {
				const formattedEndDate = formateDateForPicker(new Date(endDate)).sql;

				const newEvent = {
					id_project: project.id,
					id_user: userToSave.id,
					start_date: formattedEndDate,
					event_start: formattedEndDate,
					event_end: formattedEndDate,
					creation_date: new Date(),
					type: "date",
					title: "🍾 " + t("Fin du chantier"),
				};

				postEvent(newEvent);
			}

			setTimeout(() => {
				// project created, update User and create events if startDate and endDate are sets
				window.location.href = "/project/" + project.uuid;
			}, 500);
		}
	};

	const userCanCreateProject = (user: any) => {
		if (
			user.plan &&
			user.plan.projectlimit &&
			parseInt(user.plan.projectlimit) === 1
		) {
			projectApi.getAll({ id_user: user.id }).then((result: any) => {
				if (result.length > 0) {
					window.location.href = "/project/" + result[0].uuid;
				}
			});
			return false;
		} else {
			return true;
		}
	};

	React.useEffect(() => {
		if (user.id) {
			if (userCanCreateProject(user)) {
				setLoading(false);
			}
		} else {
			setLoading(true);
		}
	}, [user.id]);
	React.useEffect(() => {
		dispatch(checkToken({}));
		setLoading(false);
	}, [dispatch]);

	React.useEffect(() => {
		if (!loading) {
			if (!user.logged) {
				navigate("/login");
			} else {
				setCurrentUser(user);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	React.useEffect(() => {
		setTimeout(() => {
			setAnimated(true);
		}, 1000);
	}, [step]);

	return (
		<div className="wizard">
			<div className="content">
				<div className="sayto">
					<img
						src={logo}
						alt="Sayto"
						className={animated ? "animate logo" : "logo"}
					/>
				</div>
				<div className="steps">
					<div className={step === 1 ? "step step1 active" : "step step1"}>
						<div
							className={
								animated ? "animate welcome_message" : "welcome_message"
							}
						>
							{user.firstname && (
								<span>
									{t("WIZARD.LABELS.hello")} {user.firstname}
								</span>
							)}
							<span>{t("WIZARD.LABELS.welcome")}</span>
						</div>

						{!loading && (
							<div
								className={
									animated ? "animate animation_element" : "animation_element"
								}
							>
								{user.firstname && (
									<div className="form">
										<span>{t("WIZARD.LABELS.create_project_title")}</span>
										<Button variant="contained" onClick={nextStep}>
											{t("WIZARD.LABELS.create_project")}
										</Button>
									</div>
								)}
								{!user.firstname && (
									<div className="form">
										<span>{t("WIZARD.LABELS.question_name")}</span>
										<div className="row">
											<div className="col">
												<input
													placeholder="Votre prénom"
													onChange={(element) => {
														setCurrentUser({
															...currentUser,
															firstname: element.target.value,
														});
													}}
												/>
											</div>
											<div className="col">
												<input
													placeholder="Votre nom"
													onChange={(element) => {
														setCurrentUser({
															...currentUser,
															name: element.target.value,
														});
													}}
												/>
											</div>
										</div>
										<Button variant="contained" onClick={nextStep}>
											{t("WIZARD.LABELS.create_project")}
										</Button>
									</div>
								)}
							</div>
						)}
					</div>
					<div className={step === 2 ? "step step2 active" : "step step2"}>
						<div
							className={
								animated ? "animate animation_element" : "animation_element"
							}
						>
							<strong className="question">
								{t("WIZARD.LABELS.question_project")}
							</strong>

							<input
								className="project_name"
								id="project_name"
								value={currentProject.name}
								placeholder="Ex: construction d'une annexe au premier étage"
								onChange={(element: any) => {
									setCurrentProject({
										...currentProject,
										name: element.target.value,
									});
								}}
							/>

							<div className="description">
								<span>{t("WIZARD.LABELS.add_description")}</span>
								<br />
								<span>{t("WIZARD.LABELS.example_description")}</span>
							</div>

							<Editor
								placeholder="Décrivez de votre projet"
								content={currentProject.description}
								onEdit={(value: string) =>
									setCurrentProject({ ...currentProject, description: value })
								}
							/>
						</div>
					</div>
					<div className={step === 3 ? "step step3 active" : "step step2"}>
						<strong className="question">
							{t("WIZARD.LABELS.question_place")}
						</strong>

						<GoogleAutoCompleteField
							value=""
							className="wizard_autocomplete"
							placeholder="Ex: avenue des Champs Elysée 4, Paris"
							onSelect={(datas: any) => {
								const projectWithAdress = { ...currentProject };
								projectWithAdress.address = datas.address;
								projectWithAdress.city = datas.city;
								projectWithAdress.zip = datas.zip;
								projectWithAdress.id_country = datas.id_country;
								setCurrentProject(projectWithAdress);
							}}
						/>

						<p>
							L'adresse n'est pas obligatoire mais si vous la remplissez, elle
							sera transmise à vos intervenants lorsque vous leur transmettrez
							des informations.
						</p>
					</div>
					<div className={step === 4 ? "step step4 active" : "step step2"}>
						<strong className="question">
							{t("WIZARD.LABELS.question_date")}
						</strong>
						<div className="date_project">
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale="fr"
							>
								<div className="picker">
									<span className="question">Date de début</span>
									<DateCalendar
										//maxDate={dayjs(formateDateForPicker(new Date(endDate)).sql)}
										value={dayjs(formateDateForPicker(new Date(startDate)).sql)}
										onChange={(newValue: any) => {
											const newDate = newValue.$d;
											const newDates = formateDateForPicker(newDate);
											setStartDate(newDates.sql);
										}}
									/>
								</div>
							</LocalizationProvider>
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								adapterLocale="fr"
							>
								<div className="picker">
									<span className="question">Date de fin</span>
									<DateCalendar
										/*
										minDate={dayjs(
											formateDateForPicker(new Date(startDate)).sql
										)}
										*/
										value={dayjs(formateDateForPicker(new Date(endDate)).sql)}
										onChange={(newValue: any) => {
											const newDate = newValue.$d;
											const newDates = formateDateForPicker(newDate);
											setEndDate(newDates.sql);
										}}
									/>
								</div>
							</LocalizationProvider>
						</div>
						<p>
							Ces dates ne sont pas obligatoires mais si vous les renseigner,
							deux évènements seront déjà créés dans votre agenda.
						</p>
					</div>
					<div className={step === 5 ? "step step5 active" : "step step2"}>
						<strong className="question">
							{t("WIZARD.LABELS.question_budget")}
						</strong>

						<div className="budget">
							<input
								id="input_amount"
								type="number"
								value={currentProject.budget ? currentProject.budget : ""}
								placeholder="0"
								onChange={(element: any) => {
									setCurrentProject({
										...currentProject,
										budget: element.target.value,
									});
								}}
							/>
							<span>€</span>
						</div>
						<p>
							Le budget n'est pas obligatoire. Il est utile si vous souhaitez
							suivre vos dépenses et comparer le montant total avec votre budget
							initial.
						</p>
					</div>
				</div>

				<div className={step > 1 ? "actions row active" : "actions row"}>
					<div className="col col3">
						<Button
							disabled={step < 2}
							variant="outlined"
							onClick={() => setStep(step - 1)}
						>
							{t("GLOBAL.ACTIONS.back")}
						</Button>
					</div>
					<div className="col col6">
						{error && (
							<Alert severity="error">{t("ERRORS.missing_fields")}</Alert>
						)}
					</div>
					<div className="col col3">
						{step < 5 ? (
							<Button variant="contained" onClick={nextStep}>
								{t("GLOBAL.ACTIONS.continue")}
							</Button>
						) : (
							<LoadingButton
								loading={postLoading}
								onClick={finishWizard}
								variant="contained"
							>
								{t("GLOBAL.ACTIONS.done")}
							</LoadingButton>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Wizard;
