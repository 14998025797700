import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";

import Config from "Config";

import { useAppSelector } from "Redux/hooks";
import { getUser, getPlan } from "Redux/Reducers/User/UserSlice";

import { Grid, Paper, Button, Divider } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { postPaymentIntent } from "Api/Controllers/PaymentsController";

import { number_format } from "Utils/Locale";

const PlanItem = (props: any) => {
	const { t } = useTranslation();

	const { plan, monthlyView, reduction, requestCancel } = props;
	const currentUser = useAppSelector(getUser);
	const currentPlan = useAppSelector(getPlan);

	const [loading, setLoading] = React.useState(false);

	const requestPayment = () => {
		setLoading(true);
		if (parseInt(plan.id) && parseInt(plan.id) !== 1) {
			postPaymentIntent({
				id_plan: plan.id,
				mode: monthlyView ? "monthly" : "yearly",
			}).then(
				(results: any) => {
					setLoading(false);
					window.location.href = results;
				},
				(error) => {
					console.log(error);
				}
			);
		} else {
			requestCancel();
			setLoading(false);
		}
	};
	return (
		<Grid item xs={4}>
			<Paper
				className="plan"
				elevation={currentUser.id_plan === plan.id ? 24 : 3}
			>
				{currentUser.id_plan === plan.id ? (
					<span className="actual_selected">
						{t("PLAN.LABELS.selected_plan")}
					</span>
				) : (
					<span className="plan_not_selected"></span>
				)}
				<div className="content">
					<strong>{plan.name}</strong>

					<span className="price">
						{plan.price > 0 ? (
							<>
								{monthlyView ? (
									<>
										{number_format((parseFloat(plan.price)+parseFloat(plan.vat)),2)} € / {t("PLAN.PRICE.monthly")}
									</>
								) : (
									<>
										{reduction ? (
											<>
												{parseInt( ""+(parseFloat(plan.price)+parseFloat(plan.vat)) * 10)}€ /{" "}
												{t("PLAN.PRICE.yearly")}
											</>
										) : (
											<>
												{parseInt( ""+(parseFloat(plan.price)+parseFloat(plan.vat))* 12)} € /{" "}
												{t("PLAN.PRICE.yearly")}
											</>
										)}
									</>
								)}
							</>
						) : (
							<>{t("PLAN.PRICE.free")}</>
						)}
					</span>

					<ul className="advantages">
						<li>
							{plan.projectlimit === "-1" ? (
								<>{t("PLAN.ADVANTAGES.unlimited_projects")}</>
							) : (
								<>
									{plan.projectlimit === "1" ? (
										<>
											{plan.projectlimit} {t("PROJECT.singular")}
										</>
									) : (
										<>
											{plan.projectlimit} {t("PROJECT.plurial")}
										</>
									)}
								</>
							)}
						</li>
						<li>
							{plan.storagelimit === "-1" ? (
								<>{t("PLAN.ADVANTAGES.unlimited_storage")}</>
							) : (
								<>
									{parseInt(plan.storagelimit) >= 1000 ? (
										<>
											<span className="storage">
												{parseInt(plan.storagelimit) / 1000}
												{" Gb"}
											</span>
											{t("PLAN.ADVANTAGES.storage_space")}
										</>
									) : (
										<>
											<span className="storage">
												{plan.storagelimit}
												{" Mb"}
											</span>
											{t("PLAN.ADVANTAGES.storage_space")}
										</>
									)}
								</>
							)}
						</li>

						<li>
							{plan.userlimit === "-1" ? (
								<>{t("PLAN.ADVANTAGES.unlimited_users")}</>
							) : (
								<>
									{parseInt(plan.userlimit) > 0 ? (
										<>
											{plan.userlimit === "1" ? (
												<>
													{plan.userlimit} {t("PLAN.ADVANTAGES.user")}
												</>
											) : (
												<>
													{plan.userlimit} {t("PLAN.ADVANTAGES.users")}
												</>
											)}
										</>
									) : (
										<>
											<span className="disabled">
												{t("PLAN.ADVANTAGES.no_user")}
											</span>
										</>
									)}
								</>
							)}
						</li>

						<li>
							{parseInt(plan.email) === 0 ? (
								<span className="disabled">
									<>{t("PLAN.ADVANTAGES.no_email")}</>
								</span>
							) : (
								<>{t("PLAN.ADVANTAGES.email")}</>
							)}
						</li>
						<li>
							{parseInt(plan.sms) === 0 ? (
								<span className="disabled">
									<>{t("PLAN.ADVANTAGES.no_sms")}</>
								</span>
							) : (
								<> {t("PLAN.ADVANTAGES.sms")}</>
							)}
						</li>

						{!monthlyView && plan.price > 0 && reduction ? (
							<li className="reduction">{t("PLAN.ADVANTAGES.reduction")}</li>
						) : (
							<li>&nbsp;</li>
						)}
					</ul>
					<LoadingButton
						loading={loading}
						className="select_button"
						disabled={
							parseInt(plan.id) === parseInt(currentPlan.id) ? true : false
						}
						onClick={requestPayment}
					>
						{t("PLAN.ACTIONS.select_plan")}
					</LoadingButton>
				</div>
			</Paper>
		</Grid>
	);
};

export default PlanItem;
