import { defaultReturnObject, ApiReturnDatas } from "Types/Api";

/** Models */
import projectApiObject from "Api/Models/Project";
const projectApi = new projectApiObject();

/** Actions */
export const getProject = (id_project: number) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;
	return new Promise((resolve, reject) => {
		projectApi.getOne(id_project).then(
			(projectFromApi: any) => {
				if (projectFromApi) {
					if (projectFromApi.id) {
						const attributes =
							typeof projectFromApi.attr === "string"
								? JSON.parse(projectFromApi.attr)
								: projectFromApi.attr;

						const projectDatas = { ...projectFromApi };
						delete projectDatas.id;
						delete projectDatas.attr;

						returnDatas.status = "success";
						returnDatas.message = "";
						returnDatas.data = {
							contractors: attributes?.contractors,
							project: {
								attributes: attributes,
								id: projectFromApi.id,
								datas: projectDatas,
								progress: 50,
							},
						};
						resolve(returnDatas);
					} else {
						reject(returnDatas);
					}
				} else {
					reject(returnDatas);
				}
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const deleteProject = (id_project: number) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		projectApi.delete(id_project).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				resolve(returnDatas);
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const postProject = (projectToSave: any) => {
	return new Promise((resolve, reject) => {
		if (projectToSave.id) {
			projectApi.put(projectToSave).then(
				(result: any) => {
					resolve(result);
				},
				(error: any) => {
					reject(error);
				}
			);
		} else {
			projectApi.post(projectToSave).then(
				(result: any) => {
					resolve(result);
				},
				(error: any) => {
					reject(error);
				}
			);
		}
	});
};
