/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Confirm from "Components/Parts/Confirm";
import { removeItem, getItem } from "Utils/Storage";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { getUser, setUser, disconnect , setSettings, getSettings } from "Redux/Reducers/User/UserSlice";
import { deleteUser } from "Api/Controllers/UserController";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { setNotification } from "Redux/Reducers/App/AppSlice";

import Grid from '@mui/material/Grid';

function SecurityAndPrivacy() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const user = useAppSelector(getUser);

	const [currentUser, setCurrentUser] = useState(user);
	const settings = useAppSelector(getSettings);
	const [currentDevices, setCurrentDevices] = useState(settings.devices);

	const [showConfirmDelete, setShowConfirmDelete] = useState(false);

	const requestDelete = () => {
		deleteUser(user.id).then(
			(result: any) => {
				if (result.status === "success") {
					dispatch(disconnect({}));
					removeItem("token");
					window.location.href = "/login";
				}
			},
			(error) => {
				console.log(error);
			}
		);
	};

	useEffect(() => {
		setCurrentUser(user);
	}, [user]);


	const saveInfos = () => {
		dispatch(setUser(currentUser));

		setTimeout(() => {
			dispatch(
				setNotification({
					message: "Vos informations ont été enregistrées.",
					type: "success",
				})
			);
		}, 1000);
	};

	const handleChange = ( deviceId : any, checked : any) => {

		dispatch( () => {
				setSettings({
					devices: currentDevices,
				});
				setTimeout(() => {
					dispatch(
						setNotification({
							message: "Vos informations ont été enregistrées.",
							type: "success",
						})
					);
				}, 1000);
			}
		);
	};

	const placeholders = {
		firstname: t("ACCOUNT.FORM.LABEL.firstname"),
		lastname: t("ACCOUNT.FORM.LABEL.lastname"),
		email: t("ACCOUNT.FORM.LABEL.e-mail"),
		password: t("ACCOUNT.FORM.LABEL.password"),
		address: t("ACCOUNT.FORM.LABEL.address"),
		city: t("ACCOUNT.FORM.LABEL.city"),
		zip: t("ACCOUNT.FORM.LABEL.zip"),
		delete_confirm: t("ACCOUNT.LABELS.confirm_delete"),
		delete_content: t("ACCOUNT.LABELS.delete_content"),
		delete_account: t("ACCOUNT.ACTIONS.delete_request"),
	};
	return (
		<>
			<Confirm
				status={showConfirmDelete}
				title={placeholders.delete_confirm}
				content={placeholders.delete_content}
				onConfirm={() => {
					requestDelete();
					setShowConfirmDelete(false);
				}}
				onClose={() => setShowConfirmDelete(false)}
				confirmButton={placeholders.delete_account}
			/>

			{ false && (
				<Card className="settings_card">
				<CardHeader title={t("ACCOUNT.LABELS.mobiledevices")} />
				<CardContent className="delete_content">


						{ settings.devices ? (
							<Grid container>
        					<Grid item md={8}>
							<p>{t("ACCOUNT.LABELS.mobiledevices_description")}</p>
							 <List sx={{  bgcolor: 'background.paper' }} >
								{ settings.devices.map((device: any, index: number) => {
									return (
				
										<ListItem
											key={index}
											secondaryAction={
												<FormControlLabel
													control={
														<Switch checked={device?.active} name="checked-{index}" 
															onChange={(element) => {
																//handleChange(device.token, element.target.checked);
															}}
														/>
													}
													label={t("ACCOUNT.LABELS.mobiledevices_allow_notification")}
													/>
											}
											>
											<ListItemAvatar>
												<Avatar>
													{ device?.channel === "APNS" ? <AppleIcon /> : <AndroidIcon />}
												</Avatar>
											</ListItemAvatar>
											<ListItemText
												primary={device?.deviceName}
												//secondary={device?.token}
											/>
											</ListItem>
									
									)
								}) }
								</List>
								</Grid>
								</Grid>
							
						) : ( 
							<span>{t("ACCOUNT.LABELS.mobiledevices_no")}</span>
						) }
				</CardContent>
			</Card>
			)}

			<Card className="settings_card">
				<CardHeader title={t("ACCOUNT.LABELS.export_request_title")} />
				<CardContent className="delete_content">
					<span>{t("ACCOUNT.LABELS.export_request_content")}</span>
					<a href="https://www.saytoapp.com/contact" target="_blank" className="delete_button">{t("BUTTONS.contact")}</a>
				</CardContent>
			</Card>

			<Card className="settings_card">
				<CardHeader title={t("ACCOUNT.LABELS.delete_request_title")} />
				<CardContent className="delete_content">
					<span>{t("ACCOUNT.LABELS.delete_request_content")}</span>
					<button
						className="delete_button"
						onClick={() => setShowConfirmDelete(true)}
					>
						{t("ACCOUNT.ACTIONS.delete_request")}
					</button>
				</CardContent>
			</Card>
		</>
	);
}

export default SecurityAndPrivacy;
