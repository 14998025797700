/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

/** API */
import UserApi from "Api/Models/User";
import Config from "Config";

import Empty from "Components/Parts/Empty";
import { amount_format } from "Utils/Locale";

function Transactions() {
	const { t } = useTranslation();
	const [payments, setPayments] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const userApi = new UserApi();

		if (!loading) return;

		userApi.getPayments().then(
			(result: any) => {
				if (result) {
					setPayments(result);
					setLoading(false);
				}
			},
			(error: string) => {
				console.log(error);
			}
		);
	}, []);

	return (
		<div className="tabContent payments">
			{loading ? (
				<Empty empty_label={t("ACCOUNT.transactions")} />
			) : (
				<>
					{payments.length > 0 ? (
						<>
							<h3 className="title">{t("ACCOUNT.transactions")}</h3>

							<div className={"listContainer"}>
								<div className="columns">
									<div className="header">
										<div className="date">
											<span>{t("LIST.date")}</span>
										</div>
										<div className="description">
											<span>{t("LIST.title")}</span>
										</div>

										<div className="amount">
											<span>{t("LIST.amount")}</span>
										</div>
										<div className="actions">
											<span>{t("LIST.actions")}</span>
										</div>
									</div>
								</div>
								<div className="list">
									{loading && (
										<div className="loading">
											<CircularProgress />
										</div>
									)}

									{payments.map((payment: any, index: any) => {
										return (
											<FileLine payment={payment} key={index} index={index} />
										);
									})}
								</div>
							</div>
						</>
					) : (
						<>
							<div className="">
								<span className="emptyMessage">Aucune transaction</span>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
}

function FileLine(props: { payment: any; index: any }) {
	const { t } = useTranslation();
	const { payment, index } = props;

	const handleDownloadInvoice = (id: any) => {
		const userApi = new UserApi();
		window.open(Config.apiUrl + "payment/pdf?id=" + id, '_blank');
	};

	return (
		<div className="item payment" key={index}>
			<div className="date">
				<span>{payment.creation_date}</span>
			</div>
			<div className="task">{payment.label}</div>

			<div className="amount">
				<span className="filesize">
					<strong>{amount_format(payment.amount)}</strong>
				</span>
			</div>
			<div className="actions">
				<a
					target="_blank"
					rel="noreferrer"
					onClick={() => handleDownloadInvoice(payment.id_billder)}
				>
					<PictureAsPdfIcon />

					{t("ACCOUNT.LABELS.invoice")}
				</a>
			</div>
		</div>
	);
}

export default Transactions;
