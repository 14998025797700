/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import config from "Config";

import {
	LoginSocialGoogle,
	LoginSocialFacebook,
	LoginSocialApple,
	IResolveParams,
} from "reactjs-social-login";

import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";

/** Backgrounds */

import bg1 from "Assets/Images/backgrounds/1.jpg";

import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

/** analytics */
import { GooglePixel } from "Utils/Scripts/Google";

/** Storage */
import { setItem } from "Utils/Storage";

/** Form components */
import TextField from "Components/Parts/TextField";

/** API */
import userApiObject from "Api/Models/User";
const userApi = new userApiObject();

function Register() {
	const { id } = useParams();
	const { t } = useTranslation();
	const [login, setLogin] = useState("");

	const [password, setPassword] = useState("");
	const [passwordStrength, setPasswordStrength] = useState("empty");
	const [passwordStrengthLabel, setPasswordStrengthLabel] = useState(
		t("ACCOUNT.FORM.ERRORS.password_strength_empty")
	);

	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	const [registerIsDone, setRegisterIsDone] = useState(false);

	const [acceptCGU, setAcceptCGU] = useState(false);
	const [backgroundImage, setBackgroundImage] = useState(bg1);
	const [errorMessage, setErrorMessage] = useState(
		t("ACCOUNT.FORM.ERRORS.field_check")
	);

	const [errorLogin, setErrorLogin] = useState(false);
	const [errorPassword, setErrorPassword] = useState(false);

	const password_strength_label = {
		empty: t("ACCOUNT.FORM.ERRORS.password_strength_empty"),
		weak: t("ACCOUNT.FORM.ERRORS.password_strength_weak"),
		medium: t("ACCOUNT.FORM.ERRORS.password_strength_medium"),
		strong: t("ACCOUNT.FORM.ERRORS.password_strength_strong"),
	};

	useEffect(() => {
		let backgroundImage = bg1;

		setBackgroundImage(backgroundImage);
	}, [id]);

	useEffect(() => {
		userApi.checkToken().then(
			(result: any) => {
				if (result && result.status === "ok") {
					window.location.href = "/project";
				} else {
					setPageLoading(false);
				}
			},
			(error) => {
				setPageLoading(false);
			}
		);
	}, []);

	const checkField = () => {
		setErrorPassword(false);
		setErrorLogin(false);
		setError(false);

		if (login === "") {
			setErrorMessage(t("ACCOUNT.FORM.ERRORS.field_check_email"));
			setErrorLogin(true);
			setError(true);

			return false;
		}

		if (!isEmailValid(login)) {
			setErrorMessage(t("ACCOUNT.FORM.ERRORS.email_seems_wrong"));
			setErrorLogin(true);
			setError(true);

			return false;
		}

		if (password === "") {
			setErrorMessage(t("ACCOUNT.FORM.ERRORS.field_check_password"));
			setErrorPassword(true);
			setError(true);
			return false;
		}
		if (getPasswordStrength(password) === "weak") {
			setErrorMessage(t("ACCOUNT.FORM.ERRORS.password_too_weak"));
			setErrorPassword(true);
			setError(true);
			return false;
		}

		if (!acceptCGU) {
			setErrorMessage(t("ACCOUNT.FORM.ERRORS.accept_cgu"));
			setError(true);
			return false;
		}
		return true;
	};

	const attemptRegister = () => {
		setLoading(true);

		if (!checkField()) {
			setLoading(false);
			return;
		}

		userApi.postRegister(login, password, "BE").then(
			(_result: any) => {
				// send to pinterest pixel
				if (window.pintrk) {
					window.pintrk("track", "signup", {
						event_id: "eventId0001",
					});
				} else {
					console.log("pinterest not found");
				}
				// continue proceeding the registration
				setRegisterIsDone(true);
				setError(false);
				setLoading(false);
			},
			(error) => {
				console.log(error);
				setError(true);
				setErrorMessage(t(error.message));
				setLoading(false);
			}
		);
	};
	const handleOauthLogin = (provider: string, token: any) => {
		setLoading(true);
		userApi.postCredentials(provider, token).then(
			(result: any) => {
				if (result.status === "success" && result.data !== null) {
					setItem("token", result.data);
					window.location.href = "/project";
					setError(false);
					setLoading(false);
				} else {
					setError(true);
					setErrorMessage(t(result.message));
					setLoading(false);
				}
			},
			(error) => {
				setError(true);
				setLoading(false);
			}
		);
	};

	const handlePassworChanged = (password: string) => {
		setErrorPassword(password === "" ? true : false);
		setPassword(password);
		const strength = getPasswordStrength(password);
		setPasswordStrength(strength);
		switch (strength) {
			case "empty":
				setPasswordStrengthLabel(password_strength_label.empty);
				break;
			case "weak":
				setPasswordStrengthLabel(password_strength_label.weak);
				break;
			case "medium":
				setPasswordStrengthLabel(password_strength_label.medium);
				break;
			case "strong":
				setPasswordStrengthLabel(password_strength_label.strong);
				break;
			default:
				setPasswordStrengthLabel(password_strength_label.empty);
				break;
		}
	};

	const handleLoginChanged = (login: string) => {
		setLogin(login);
		setErrorLogin(login === "" ? true : false);
	};

	/** Placeholders */

	const placeholder = {
		login: t("ACCOUNT.FORM.LABEL.login"),
		password: t("ACCOUNT.FORM.LABEL.choosepassword"),
	};

	const isEmailValid = (email: string) => {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	};

	const getPasswordStrength = (password: string) => {
		if (password.length === 0) {
			return "empty";
		}

		let strength = 0;

		// Critères pour évaluer la force du mot de passe
		const criteria = [
			/[A-Z]/, // majuscules
			/[a-z]/, // minuscules
			/[0-9]/, // chiffres
			/[^A-Za-z0-9]/, // caractères spéciaux
		];

		// Vérifie chaque critère
		criteria.forEach((pattern) => {
			if (pattern.test(password)) {
				strength++;
			}
		});

		// Longueur du mot de passe
		if (password.length >= 8) {
			strength++;
		}

		// Évaluation de la force
		switch (strength) {
			case 0:
			case 1:
			case 2:
				return "weak";
			case 3:
				return "medium";
			case 4:
			case 5:
				return "strong";
			default:
				return "weak";
		}
	};

	return (
		<>
			<GooglePixel />
			<div className="login_container">
				{pageLoading && (
					<div className="loader">
						<CircularProgress />
					</div>
				)}

				<div className="form">
					<div className="content">
						{registerIsDone ? (
							<>
								<div className="switch_account_action success">
									<CheckCircleOutlineIcon />
									<strong>{t("ACCOUNT.FORM.SUCCESS.account_created")}</strong>
									<span>
										{t("ACCOUNT.FORM.SUCCESS.email_sent")} <i>{login}</i>
									</span>
								</div>
							</>
						) : (
							<>
								<div className="switch_account_action">
									<strong>{t("ACCOUNT.LABELS.welcome")}</strong>
								</div>
								<div className="oauth_connect">
									<span> {t("ACCOUNT.LABELS.register_with")}</span>
									<LoginSocialFacebook
										className="login_button facebook"
										appId={config.facebookAppId || ""}
										fieldsProfile={"id,first_name,last_name,picture,,email"}
										redirect_uri={"https://my.saytoapp.com/"}
										onResolve={({ provider, data }: IResolveParams) => {
											handleOauthLogin("facebook", data?.accessToken);
										}}
										auth_type="rerequest"
										onReject={(err) => {
											console.log(err);
										}}
									>
										<FacebookRoundedIcon />
									</LoginSocialFacebook>
									<LoginSocialGoogle
										isOnlyGetToken={true}
										className="login_button google"
										client_id={config.googleClientId || ""}
										redirect_uri={""}
										scope="openid profile email"
										discoveryDocs="claims_supported"
										access_type="offline"
										onResolve={({ provider, data }: IResolveParams) => {
											handleOauthLogin(provider, data?.access_token);
										}}
										onReject={(err) => {
											console.log(err);
										}}
									>
										<GoogleIcon />
									</LoginSocialGoogle>
									<LoginSocialApple
										className="login_button apple"
										client_id={config.appleClientId || ""}
										scope={"name email"}
										redirect_uri={config.appleRedirectUri || ""}
										onResolve={({ provider, data }: IResolveParams) => {
											//console.log(data);
											handleOauthLogin(provider, data?.authorization?.id_token);
										}}
										onReject={(err) => {
											console.log(err);
										}}
									>
										<AppleIcon />
									</LoginSocialApple>
								</div>

								<span className="connect_with">{t("GLOBAL.LABELS.or")}</span>

								{error && (
									<Alert severity="error" className="error">
										{errorMessage}
									</Alert>
								)}
								<div className="fields">
									<TextField
										error={errorLogin}
										value={login}
										placeholder={placeholder.login}
										onUpdate={(newLogin: string) =>
											handleLoginChanged(newLogin)
										}
									/>
								</div>
								<div className="fields">
									<TextField
										error={errorPassword}
										value={password}
										placeholder={placeholder.password}
										onUpdate={(newPassword: string) =>
											handlePassworChanged(newPassword)
										}
										showPasswordIcon={true}
										type="password"
										onKeyPress={(e: any) => {
											if (e.keyCode === 13) {
												attemptRegister();
											}
										}}
									/>
									<div className="password_strength">
										<div className="bar">
											<div className={`strength ${passwordStrength}`}></div>
										</div>
										<div className={`label ${passwordStrength}`}>
											<span>{passwordStrengthLabel}</span>
										</div>
									</div>

									<FormControl component="fieldset" className="cgu_accept">
										<FormControlLabel
											value="cgu_accept"
											control={<Checkbox />}
											label=""
											labelPlacement="end"
											onChange={(e: any) => {
												setAcceptCGU(!acceptCGU);
											}}
										/>
										<a
											href="https://www.saytoapp.com/conditions-generales-dutilisation/"
											target="_blank"
										>
											{t("ACCOUNT.FORM.LABEL.accept_cgu")}{" "}
											{t("GLOBAL.LABELS.cgu")}
										</a>
									</FormControl>
								</div>
								<div className="actions">
									<FormControl className="fields" sx={{ width: "80%" }}>
										<Button variant="contained" onClick={attemptRegister}>
											{loading ? (
												<CircularProgress />
											) : (
												<>{t("ACCOUNT.ACTIONS.register")}</>
											)}
										</Button>
									</FormControl>
								</div>

								<div className="fields center">
									<span>{t("ACCOUNT.LABELS.registered_yet")} </span>
									<Link to={"/login/" + (id !== undefined ? id : "")}>
										{t("ACCOUNT.ACTIONS.dologin")}
									</Link>
								</div>
							</>
						)}
					</div>
				</div>
				<div
					className="presentation"
					style={{
						backgroundImage: `url(${backgroundImage})`,
					}}
				></div>
			</div>
		</>
	);
}

export default Register;
