/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import SearchIcon from "@mui/icons-material/Search";
import filterButton from "Assets/Images/clear/filters/filter.png";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

import {
	TextField,
	MenuItem,
	InputLabel,
	Button,
	Select,
	Autocomplete,
} from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/fr";
import dayjs from "dayjs";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { getContractors, getTags } from "Redux/Reducers/Project/ProjectSlice";
import { useAppSelector } from "Redux/hooks";

import { formateDateForPicker } from "Utils/DateFormatter";
import Stack from "@mui/material/Stack";

const today = formateDateForPicker(new Date());
const contributorIdsArray = [] as any[];

const defaultFilters: any = {
	search: "",
	start_date: new Date(),
	from: new Date(),
	to: new Date(),
	end_date: new Date(),
};
function Filters(props: {
	filtersParams: any;
	onFilter: Function;
	hideTypes?: boolean;
}) {
	const { onFilter, filtersParams, hideTypes } = props;
	const { t } = useTranslation();
	const contributorsList = useAppSelector(getContractors);
	const tags = useAppSelector(getTags);

	const [filters, setFilters] = useState(defaultFilters);
	const [amountActiveFilters, setAmountActiveFilters] = useState(0);

	const [contributorsOptions, setContributorsOptions] =
		useState(contributorIdsArray);

	const [open, setOpen] = useState(false);

	const clearFilters = () => {
		const clearFilters = defaultFilters;
		delete clearFilters.from;
		delete clearFilters.to;
		delete clearFilters.end_date;
		delete clearFilters.start_date;
		setFilters(clearFilters);
		onFilter(clearFilters);
		setOpen(false);
		setTimeout(() => {
			setAmountActiveFilters(0);
		}, 10);
	};

	useEffect(() => {});
	useEffect(() => {
		const formattedFilters = { ...filtersParams };
		setFilters(formattedFilters);
	}, [filtersParams]);

	useEffect(() => {
		let amount = 0;

		if (filters.from) {
			amount++;
		}

		if (filters.to) {
			amount++;
		}

		if (!hideTypes && filters.type && filters.type !== "*") {
			amount++;
		}

		if (filters.filetype) {
			amount++;
		}

		setAmountActiveFilters(amount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	// prepare contractors
	useEffect(() => {
		const contributorsListArray =
			typeof contributorsList === "string"
				? JSON.parse(contributorsList)
				: contributorsList;
		if (contributorsListArray && Array.isArray(contributorsListArray)) {
			const contributorsArray: any = [];
			contributorsListArray.map((contributor: any, index: number) => {
				if (contributor?.email) {
					const label =
						contributor.company !== ""
							? contributor.company
							: contributor.firstname + " " + contributor.lastname;
					contributorsArray.push({
						value: contributor.id,
						label: label,
					});
				}

				return contributor;
			});
			setContributorsOptions(contributorsArray);
		}
	}, [contributorsList]);

	useEffect(() => {
		if (filters.contractors) {
			const selectedContributorsArray: any = [];
			filters.contractors.map((contributorId: any, index: number) => {
				const contributor = contributorsOptions.filter((contributor: any) => {
					return contributor.value === contributorId;
				});
				if (contributor.length > 0) {
					selectedContributorsArray.push(contributor[0]);
				}
				return contributorId;
			});
			//setSelectedContributors(selectedContributorsArray);
		}
	}, [filters.contractors, contributorsOptions]);

	const prepareSaveFilters = (filters: any) => {
		delete filters.start_date;
		delete filters.end_date;
		onFilter(filters);
	};

	const searchPlaceholder = t("EVENT.FILTERS.placeholder");
	return (
		<div className="filtersActions">
			<div className="search_input">
				<SearchIcon />
				<div className="field">
					<label>{searchPlaceholder}</label>
					<input
						id="search_event"
						className="search_event"
						placeholder={"Commencer votre recherche"}
						value={filters.search}
						onKeyDown={(e) => {
							e.key === "Enter" && prepareSaveFilters(filters);
						}}
						onChange={(element) => {
							const newFilters = { ...filters };
							newFilters.search = element.target.value;
							setFilters(newFilters);

							if (newFilters.search === "") {
								prepareSaveFilters(newFilters);
							}
						}}
					/>
				</div>

				<span
					className={
						filters.search && filters.search !== ""
							? "enter_message"
							: "enter_message empty"
					}
				>
					<KeyboardReturnIcon /> OU
					<button
						onClick={() => {
							prepareSaveFilters(filters);
						}}
					>
						GO
					</button>
				</span>
			</div>
			<div className="search_contractors">
				<div className="field">
					<label>{"Filtrer par intervenant"}</label>
					<Autocomplete
						multiple
						options={contributorsOptions}
						onChange={(event, contractors) => {
							const newFilters = { ...filters };

							const contractorsIds = contractors.map(
								(contractor: any) => contractor.value
							);
							newFilters.contractors = contractorsIds;
							setFilters(newFilters);
							prepareSaveFilters(newFilters);
						}}
						renderOption={(props, option, { selected }) => {
							const contractorId = option.value;

							return (
								<li {...props}>
									<span>{option.label}</span>
								</li>
							);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								id="contractor_event"
								className=""
								variant="outlined"
								value={filters.search}
							/>
						)}
					/>
				</div>
			</div>

			<div className="search_tags">
				<div className="field">
					<label>{"Filtrer par tag"}</label>
					<Autocomplete
						multiple
						options={tags}
						onChange={(event, tags) => {
							const newFilters = { ...filters };
							newFilters.tags = tags;
							setFilters(newFilters);
							prepareSaveFilters(newFilters);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								id="tags_event"
								className=""
								variant="outlined"
								value={filters.search}
							/>
						)}
					/>
				</div>
			</div>

			<button className="search_filters" onClick={() => setOpen(!open)}>
				{amountActiveFilters > 0 && (
					<span className="amount">{amountActiveFilters}</span>
				)}
				{open ? (
					<>
						<span className="label">Masquer les filtres</span>
						<img src={filterButton} alt="filter" />
					</>
				) : (
					<>
						<span className="label">Afficher plus de filtres</span>
						<img src={filterButton} alt="filter" />
					</>
				)}
			</button>

			<div className={open ? "filtersBox open" : "filtersBox"}>
				<div className="header">
					<h3>{t("EVENT.FILTERS.title")}</h3>
					<button className="clear" onClick={clearFilters}>
						{t("EVENT.FILTERS.clear_filters")}
					</button>
				</div>
				<div className="separator"></div>

				<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
					<span className="filterLabel">
						{t("EVENT.FILTERS.by_publication_date")}
					</span>

					<Stack className="dateFilter" spacing={3}>
						<DatePicker
							className="picker"
							label="Du"
							value={dayjs(today.sql)}
							onChange={(date: any) => {
								const newDate = date.$d;

								const newDates = formateDateForPicker(newDate);
								const newFilters = { ...filters };

								newFilters.start_date = newDates.formatted;
								newFilters.from = newDates.sql;
								setFilters(newFilters);
							}}
						/>
						<DatePicker
							label="Au"
							className="picker right"
							value={dayjs(today.sql)}
							onChange={(date: any) => {
								const newDate = date.$d;

								const newDates = formateDateForPicker(newDate);
								const newFilters = { ...filters };

								newFilters.end_date = newDates.formatted;
								newFilters.to = newDates.sql;
								setFilters(newFilters);
							}}
						/>
					</Stack>
				</LocalizationProvider>
				<div className="separator"></div>

				{!hideTypes &&
					(!filters.hide ||
						!filters?.hide?.includes("type") ||
						!filters?.hide?.includes("fileType")) && (
						<>
							<span className="filterLabel">Recherche par type</span>
						</>
					)}

				<div className="row type_filter">
					{!hideTypes &&
						(!filters.hide || !filters?.hide?.includes("type")) && (
							<div className="col">
								<InputLabel className="input_select" id="type_select">
									Type d'information
								</InputLabel>

								<Select
									labelId="type_select"
									id="select_type"
									value={filters.type ? filters.type : "*"}
									label="Fonction"
									onChange={(element) => {
										const newFilters = { ...filters };
										if (element.target.value === "*") {
											delete newFilters.type;
										} else {
											newFilters.type = element.target.value;
										}
										setFilters(newFilters);
									}}
								>
									<MenuItem value={"*"}>{t("EVENT.FILTERS.all")}</MenuItem>
									<MenuItem value={"picture"}>
										{t("EVENT.FILTERS.picture")}
									</MenuItem>
									<MenuItem value={"sound"}>
										{t("EVENT.FILTERS.sound")}
									</MenuItem>
									<MenuItem value={"file"}>{t("EVENT.FILTERS.file")}</MenuItem>
									<MenuItem value={"video"}>
										{t("EVENT.FILTERS.video")}
									</MenuItem>
									<MenuItem value={"text"}>{t("EVENT.FILTERS.text")}</MenuItem>
									<MenuItem value={"task"}>{t("EVENT.FILTERS.task")}</MenuItem>
									<MenuItem value={"date"}>{t("EVENT.FILTERS.date")}</MenuItem>
									<MenuItem value={"mail"}>{t("EVENT.FILTERS.mail")}</MenuItem>
								</Select>
							</div>
						)}

					{!hideTypes &&
						(!filters.hide || !filters?.hide?.includes("fileType")) && (
							<div className="col">
								<InputLabel className="input_select" id="type_file">
									Nature de l'information
								</InputLabel>

								<Select
									labelId="type_file"
									id="seleft_filetype"
									value={filters.filetype ? filters.filetype : "*"}
									label="Fonction"
									onChange={(element) => {
										const newFilters = { ...filters };
										if (element.target.value === "*") {
											delete newFilters.filetype;
										} else {
											newFilters.filetype = element.target.value;
										}
										setFilters(newFilters);
									}}
								>
									<MenuItem value={"*"}>{t("EVENT.FILTERS.all")}</MenuItem>
									<MenuItem value={"estimate"}>
										{t("EVENT.FILTERS.estimate")}
									</MenuItem>
									<MenuItem value={"invoice"}>
										{t("EVENT.FILTERS.invoice")}
									</MenuItem>
								</Select>
							</div>
						)}
				</div>

				{!hideTypes &&
					(!filters.hide ||
						!filters?.hide?.includes("type") ||
						!filters?.hide?.includes("fileType")) && (
						<div className="separator"></div>
					)}

				<Button
					className="filterButton"
					variant="contained"
					onClick={() => {
						prepareSaveFilters(filters);
						setOpen(false);
					}}
				>
					{t("EVENT.ACTIONS.filter")}
				</Button>
			</div>
		</div>
	);
}

export default Filters;
