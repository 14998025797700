/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

import AddIcon from "@mui/icons-material/Add";

import EventModal from "./Modals/EventModal";

import { hasFreeSpace } from "Utils/Locale";

/* Images */
import FileIcon from "Assets/Images/clear/events/Files.png";
import ImageIcon from "Assets/Images/clear/events/Picture.png";
import SoundIcon from "Assets/Images/clear/events/Sound.png";
import VideoIcon from "Assets/Images/clear/events/Camera.png";
import CommentIcon from "Assets/Images/clear/events/Text.png";
import TaskIcon from "Assets/Images/clear/events/Task.png";
import DateIcon from "Assets/Images/clear/events/Date.png";
import MeetingIcon from "Assets/Images/clear/events/Date.png";

import CloseIcon from "@mui/icons-material/Close";

/** Redux */
import { useAppSelector } from "Redux/hooks";
import { getUser } from "Redux/Reducers/User/UserSlice";

function AddEvent(props: { selected?: string[]; distinct?: boolean }) {
	const user = useAppSelector(getUser);
	const [type, setType] = useState("");
	const [open, setOpen] = useState(false);
	const [params, setParams] = useState({} as any);
	const [showNav, setShowNav] = useState(false);
	const { t } = useTranslation();

	const [freeSpace, setFreeSpace] = useState(hasFreeSpace());

	const [selected, setSelected] = useState(props.selected);
	const directOpen =
		selected && selected.length === 1 ? selected[0] : undefined;
	const openModal = (type: string) => {
		setType(type);
		setOpen(true);
	};

	React.useEffect(() => {
		if (showNav && directOpen) {
			openModal(directOpen);
			setSelected(undefined);
		}
	}, [showNav, directOpen]);

	React.useEffect(() => {
		setFreeSpace(hasFreeSpace());
	}, [user.used_space]);

	const parentClassName = directOpen
		? "only_add menu_modal"
		: props?.distinct
		? "multi_add"
		: "";

	return (
		<div className={parentClassName}>
			<Tooltip title={
						!freeSpace ?
							t("EVENT.ACTIONS.event_add_button_tooltip_disabled")
							: "Ajouter un élément à votre projet"
					} placement="right"><span>
				{props?.distinct && selected?.length ? (
					<div className="multi_add_button">
						{selected.map((item, index) => (
							<Button
								key={index}
								disabled={!freeSpace}
								className={item + " add_to_project"}
								variant="contained"
								size="small"
								color={index === 0 ? "secondary" : "primary"}
								disableElevation
								startIcon={<AddIcon />}
								onClick={() => {
									if (item === "meeting") {
										openModal("date");
										setParams({ is_meeting: true });
									} else {
										openModal(item);
										setParams({ is_meeting: false });
									}
								}}
							>
								{t("TYPE.BUTTONS." + item)}
							</Button>
						))}
					</div>
				) : (
						<Button
							disabled={!freeSpace}
							className="add_to_project"
							variant="contained"
							size="small"
							color="secondary" 
							disableElevation
							startIcon={<AddIcon />}
							onClick={() => {
								if (directOpen) {
									openModal(directOpen);
								} else {
									setShowNav(!showNav);
								}
							}}
						>
							Ajouter au projet
						</Button>
				)}
				</span>
			</Tooltip>

			<div className={showNav ? "open addEvent" : "addEvent"}>
				<div className="overlay" onClick={() => setShowNav(!showNav)}></div>

				<div className="menu">
					<div className="title">
						<button onClick={() => setShowNav(!showNav)}>
							<CloseIcon />
						</button>
						<span>Que souhaitez-vous ajouter ?</span>
					</div>
					<div className="content">
						<div className="group">
							{(selected === undefined || selected?.includes("file")) && (
								<>
									<button
										className="action"
										onClick={() => {
											openModal("file");
										}}
									>
										<img src={FileIcon} alt="file" />
										<span>{t("TYPE.file")}</span>
									</button>
								</>
							)}
							{(selected === undefined || selected?.includes("picture")) && (
								<button
									className="action"
									onClick={() => {
										openModal("picture");
									}}
								>
									<img src={ImageIcon} alt="file" />
									<span>{t("TYPE.picture")}</span>
								</button>
							)}
							{(selected === undefined || selected?.includes("sound")) && (
								<button
									className="action"
									onClick={() => {
										openModal("sound");
									}}
								>
									<img src={SoundIcon} alt="file" />
									<span>{t("TYPE.sound")}</span>
								</button>
							)}
							{(selected === undefined || selected?.includes("video")) && (
								<button
									className="action"
									onClick={() => {
										openModal("video");
									}}
								>
									<img src={VideoIcon} alt="file" />
									<span>{t("TYPE.video")}</span>
								</button>
							)}
						</div>
						<div className="group">
							{(selected === undefined || selected?.includes("text")) && (
								<button
									className="action"
									onClick={() => {
										openModal("text");
									}}
								>
									<img src={CommentIcon} alt="file" />
									<span>{t("TYPE.text")}</span>
								</button>
							)}
							{(selected === undefined || selected?.includes("task")) && (
								<button
									className="action"
									onClick={() => {
										openModal("task");
									}}
								>
									<img src={TaskIcon} alt="file" />
									<span>{t("TYPE.task")}</span>
								</button>
							)}
							{(selected === undefined || selected?.includes("date")) && (
								<button
									className="action"
									onClick={() => {
										openModal("date");
										setParams({ is_meeting: false, modalInit: Math.random() });
									}}
								>
									<img src={DateIcon} alt="file" />
									<span>{t("TYPE.date")}</span>
								</button>
							)}

							{(selected === undefined || selected?.includes("date")) && (
								<button
									className="action"
									onClick={() => {
										openModal("date");
										setParams({ is_meeting: true, modalInit: Math.random() });
									}}
								>
									<img src={MeetingIcon} alt="file" />
									<span>{t("TYPE.meeting")}</span>
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
			<EventModal
				type={type}
				status={open}
				params={params}
				onEnd={() => {
					setOpen(false);
					setShowNav(false);
				}}
				onClose={() => {
					setOpen(false);
				}}
			/>
		</div>
	);
}

export default AddEvent;
