/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

/** Form components */
import TextField from "Components/Parts/TextField";
/** Storage */

import { setItem, removeItem } from "Utils/Storage";

/** Images */
import backgroundImage from "Assets/Images/backgrounds/1.jpg";
import apple from "Assets/Images/stores/apple.png";
import google from "Assets/Images/stores/google.png";

/** API */
import userApiObject from "Api/Models/User";

const userApi = new userApiObject();

function Validate() {
	const { t } = useTranslation();
	const { hash } = useParams();

	const [pageLoading, setPageLoading] = useState(true);
	const [accountValidated, setAccountValidated] = useState(false);
	const [hashIsUsed, sethashIsUsed] = useState(false);

	const [error] = useState(false);
	const [password, setPassword] = useState("");
	const [errorLogin, setErrorLogin] = useState(false);

	const [errorMessage, setErrorMessage] = useState(
		t("ACCOUNT.FORM.ERRORS.field_check")
	);

	const [currentUser, setCurrentUser] = useState<string>("");

	const getMobileOperatingSystem = () => {
		const userAgent: string = navigator.userAgent || navigator.vendor;

		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			return "Windows Phone";
		}

		if (/android/i.test(userAgent)) {
			return "Android";
		}

		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
			return "iOS";
		}
	};

	const openApp = () => {
		const deviceOs = getMobileOperatingSystem();

		if (deviceOs === "iOS") {
			try {
				window.location.replace(new URL("sayto://"));
			} catch (error) {}
		} else if (deviceOs === "Android") {
			try {
				window.location.replace(new URL("sayto://"));
			} catch (error) {}
		} else {
			window.location.href = "/project";
		}
	};

	useEffect(() => {
		if (hash && !hashIsUsed) {
			userApi.postValidate(hash).then(
				(result: any) => {
					sethashIsUsed(true);
					setAccountValidated(true);
					setPageLoading(false);
					if (result?.token) {
						//setItem("token", result.token);
						userApi.verifyOneTimeToken(result.token).then((result: any) => {
							result.email && setCurrentUser(result.email);
						});
					}
				},
				(error) => {
					console.log(error);
					setPageLoading(false);
				}
			);
		} else {
			setPageLoading(false);
		}
	}, [hash, hashIsUsed]);

	/** Placeholders */

	const placeholder = {
		login: t("ACCOUNT.FORM.LABEL.login"),
		password: t("ACCOUNT.FORM.LABEL.password"),
	};

	const validatePwd = () => {
		userApi.postLogin(currentUser, password).then(
			(result: any) => {
				if (result.status === "ok") {
					setItem("token", result.token);
					removeItem("current_project");
					//window.location.href = "/project";
					openApp();
				} else {
					setErrorMessage(t(result.message));
					setErrorLogin(true);
				}
			},
			(error: any) => {
				console.log("error", error);
				setErrorMessage(t(error.message));
				setErrorLogin(true);
			}
		);
	};

	return (
		<div className="login_container">
			{pageLoading && (
				<div className="loader">
					<CircularProgress />
				</div>
			)}
			<div className="form">
				<div className="content">
					{accountValidated ? (
						<div className="switch_account_action success">
							<strong>{t("ACCOUNT.FORM.SUCCESS.account_validated")}</strong>
							<span>{t("GLOBAL.LABELS.perfect")}!</span>
							<br />
							{false &&
							getMobileOperatingSystem() !== "iOS" &&
							getMobileOperatingSystem() !== "Android" ? (
								<>
									<span>
										{t("ACCOUNT.FORM.SUCCESS.account_validated_with_login")}
									</span>
									<div className="actions actions_validate_form">
										<TextField
											error={error}
											value={password}
											placeholder={placeholder.password}
											onUpdate={(newLogin: string) => setPassword(newLogin)}
											showPasswordIcon={true}
											type="password"
											onKeyPress={(e: any) => {
												setPassword(e.target.value);
												if (e.keyCode === 13) {
													validatePwd();
												}
											}}
										/>
										<Button
											className="validate_btn"
											variant="contained"
											onClick={validatePwd}
										>
											{t("ACCOUNT.ACTIONS.start")}
										</Button>
									</div>
									{errorLogin && (
										<span style={{ color: "red", fontSize: 14 }}>
											{errorMessage}
										</span>
									)}
								</>
							) : (
								<>
									<span>Votre compte a bien été validé.</span>
									<br />
									<span>
										Vous pouvez vous connecter depuis l'application ou continuer
										depuis un navigateur de bureau.
									</span>
									<Button
										onClick={openApp}
										variant="contained"
										color="primary"
										style={{ marginTop: 20, marginBottom: 20 }}
									>
										Connectez-vous
									</Button>
									<br />
									<br />
									<strong>Pas encore l'application ? </strong>
									<br />
									<span>Téléchargez-la dès maintenant : </span>
									<div className="validate_store">
										{getMobileOperatingSystem() !== "Android" && (
											<a href="https://apps.apple.com/be/app/sayto/id6471659290">
												<img
													className="app_image"
													src={apple}
													alt="App Store"
												/>
											</a>
										)}
										{getMobileOperatingSystem() !== "iOS" && (
											<a href="https://play.google.com/store/apps/details?id=com.sayto.app&hl=fr&gl=be">
												<img
													className="app_image"
													src={google}
													alt="playstore"
												/>
											</a>
										)}
									</div>
								</>
							)}
						</div>
					) : (
						<div className="switch_account_action error">
							<strong>{t("ACCOUNT.FORM.ERRORS.account_not_validated")}</strong>
							<span>
								{t(
									"ACCOUNT.FORM.ERRORS.account_already_validate_or_missing_hash"
								)}
							</span>
							<span>{"ACCOUNT.FORM.ERRORS.still_error"}</span>
							<Link to="/support">{t("GLOBAL.ACTIONS.contact_support")}</Link>
						</div>
					)}
				</div>
			</div>
			<div
				className="presentation"
				style={{
					backgroundImage: `url(${backgroundImage})`,
				}}
			></div>
		</div>
	);
}

export default Validate;
