/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

/** Form components */
import TextField from "Components/Parts/TextField";
import FormControl from "@mui/material/FormControl";

/** Images */
import backgroundImage from "Assets/Images/backgrounds/1.jpg";

/** API */
import userApiObject from "Api/Models/User";
const userApi = new userApiObject();

function Recover() {
	const { t } = useTranslation();

	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [pageLoading, setPageLoading] = useState(true);
	const [requestSent, setRequestSent] = useState(false);

	const attemptRecover = () => {
		setLoading(true);

		if (email !== "") {
			userApi.postRecover(email).then(
				(result: any) => {
					setLoading(false);
					setRequestSent(true);
					setError(false);
				},
				(error) => {
					setLoading(false);
					setError(true);
					setErrorMessage("reset_user_not_found");
				}
			);
		}
	};
	useEffect(() => {
		setPageLoading(false);
	}, []);

	/** Placeholders */

	const placeholder = {
		email: t("ACCOUNT.FORM.LABEL.e-mail"),
		password: t("ACCOUNT.FORM.LABEL.password"),
	};

	return (
		<div className="login_container">
			{pageLoading && (
				<div className="loader">
					<CircularProgress />
				</div>
			)}
			<div className="form">
				{requestSent ? (
					<div className="content">
						<div className="switch_account_action success">
							<CheckCircleOutlineIcon />
							<strong>{t("ACCOUNT.FORM.SUCCESS.request_sent")}</strong>
							<span>
								{t("ACCOUNT.FORM.SUCCESS.email_sent")} {email}
							</span>
						</div>
					</div>
				) : (
					<div className="content">
						<div className="switch_account_action">
							<strong>{t("ACCOUNT.LABELS.request_password")}</strong>
							<span>{t("ACCOUNT.ACTIONS.enter_email")}</span>
						</div>
						<div className="fields">
							<TextField
								error={error}
								value={email}
								placeholder={placeholder.email}
								onUpdate={(newEmail: string) => setEmail(newEmail)}
							/>
						</div>

						<div className="actions">
							<FormControl className="fields" sx={{ width: "80%" }}>
								<Link to="/login">{t("ACCOUNT.ACTIONS.back_to_login")}</Link>
								<Button variant="contained" onClick={attemptRecover}>
									{loading ? (
										<CircularProgress />
									) : (
										<>{t("ACCOUNT.ACTIONS.request_new_password")}</>
									)}
								</Button>
							</FormControl>
							{error && (
								<Alert severity="error" className="error">
									{t("ACCOUNT.FORM.ERRORS." + errorMessage)}
								</Alert>
							)}
						</div>
					</div>
				)}
			</div>
			<div
				className="presentation"
				style={{
					backgroundImage: `url(${backgroundImage})`,
				}}
			></div>
		</div>
	);
}

export default Recover;
