import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import CancelIcon from '@mui/icons-material/Cancel';
import FancyBox from "Components/Parts/Fancybox";

function Gallery(props: any) {
	const { pictures, onDelete } = props;

	return (
		<div className={"gallery"}>
			<FancyBox>
				{pictures &&
					Array.isArray(pictures) &&
					pictures.map(
						(picture: { url: string; name: string }, index: number) => {
							return (
								<div className="gallery_item" key={index}>
									<button
										onClick={() => {
											onDelete(picture);
										}}
									>
										<CancelIcon />
									</button>
									<a
										href={picture.url}
										data-fancybox="gallery"
										key={index}
									>
										<img src={picture.url} alt={picture.name} />
									</a>
								</div>
							);
						}
					)}
			</FancyBox>
		</div>
	);
}

export default Gallery;
