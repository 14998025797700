/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

function SoundPlayer(props: any) {
	const { sounds, onDelete, hideTitle, hideDelete } = props;
	const [records, setRecords] = useState<any[]>([]);

	useEffect(() => {
		const formattedRecords: any = [];
		for (const recordIndex in sounds) {
			const record = sounds[recordIndex];

			fetch(record.url).then(
				(sound) => {
					record.loaded = true;
					record.error = false;
					formattedRecords.push(record);
					setRecords(formattedRecords);
				},
				(error) => {
					// record.loaded = true;
					//record.error = true;
					formattedRecords.push(record);
					setRecords(formattedRecords);
				}
			);
		}
	}, [sounds]);

	return (
		<div className={"soundPlayer"}>
			{records.map((sound: any, index: number) => {
				return (
					<div className={"player"} key={index}>
						{!hideTitle && <span>{sound.name}</span>}
						{!sound.error ? (
							<audio
								id={"record_" + index}
								controls={true}
								src={sound.url}
							></audio>
						) : (
							<span>Audio</span>
						)}
						{!hideDelete && (
							<button
								onClick={() => {
									onDelete(sound);
								}}
							>
								<HighlightOffOutlinedIcon />
							</button>
						)}
					</div>
				);
			})}
		</div>
	);
}

export default SoundPlayer;
