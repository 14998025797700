/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { Grid, Box } from "@mui/material";

/** Modules  */

import { StatsType } from "Types/User";
import UserApi from "Api/Models/User";

function Stats() {
	const { t } = useTranslation();

	const statType: StatsType = {
		nb_project: 0,
		nb_event: 0,
		event_stats: [],
		used_space: 0,
		total_space: 0,
		used_space_percent: 0,
	};
	const [stats, setStats] = useState(statType);

	useEffect(() => {
		const userApi = new UserApi();

		userApi.getStats().then(
			(result: any) => setStats(result)
			,
			(error: string) => {
				console.log(error);
			}
		);
	}, []);

	return (
		<div className="Page reports">

			{stats.nb_event && stats.nb_event > 0 ? (
				<div className="content">
					<Grid container spacing={2}>
						<Grid item md={6}>
							<Card variant="outlined">
								<CardContent>
									<Typography gutterBottom variant="h5" component="div">
										{t("REPORTS.space_used")}
									</Typography>
									<Typography variant="h3" color="text.secondary">
										{stats.used_space} MB / {stats.total_space} MB
									</Typography>

									<Box sx={{ display: "flex", alignItems: "center" }}>
										<Box sx={{ width: "100%", mr: 1 }}>
											<LinearProgress
												value={stats.used_space_percent}
												variant="determinate"
												color="primary"
											/>
										</Box>
										<Box sx={{ minWidth: 35 }}>
											<Typography variant="body2" color="text.secondary">
												{stats.used_space_percent}%
											</Typography>
										</Box>
									</Box>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
					<Grid item md={6}>
							<Card variant="outlined">
								<CardContent>
									<div className="table_stats">
										<div className="head">
											<strong>Type</strong>
											<strong className="right">Nombre</strong>
											<strong className="right">Taille</strong>
										</div>
										<div className="content">
											{
												stats.event_stats.map((item: any) => {

													return (
														<div className="row">
															<span>{t('TYPE.' + item.name)}</span>
															<span className="right">{item.value_number}</span>
															<span className="right">{item.value_size} Mb</span>
														</div>
													)
												})
											}
										</div>
									</div>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</div>
			) : (
				<div className="content">
					<span className="emptyMessage">
						Commencez à remplir votre projet afin de voir vos statistiques.
					</span>
				</div>
			)}
		</div>
	);
}

export default Stats;
