/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Skeleton from "@mui/material/Skeleton";

import { Link, useParams } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { getAmounts, setAmounts } from "Redux/Reducers/Project/EventsSlice";
import {
  getProject,
  getProjectLoading,
} from "Redux/Reducers/Project/ProjectSlice";

function Tabs() {
  const { t } = useTranslation();
  const amounts = useAppSelector(getAmounts);
  const project = useAppSelector(getProject);
  const dispatch = useAppDispatch();

  const { id, tab } = useParams();
  const current = tab ? tab : "activities";

  useEffect(() => {
    dispatch(setAmounts({ event: 1000 }));
  }, [dispatch]);

  return (
    <ul className="tabs">
      <li>
        {project.loading ? (
          <Skeleton variant="rectangular" width={60} height={15} />
        ) : (
          <Link
            to={"/project/" + id + "/activities"}
            className={
              current === "activities" || current === "project" ? "active" : ""
            }
          >
            {t("TABS.activities")}{" "}
            <span>
              {amounts?.events && amounts.events > 0 ? amounts.events : 0}
            </span>
          </Link>
        )}
      </li>
      <li>
        {project.loading ? (
          <Skeleton variant="rectangular" width={80} height={15} />
        ) : (
          <Link
            to={"/project/" + id + "/calendar"}
            className={current === "calendar" ? "active" : ""}
          >
            {t("TABS.calendar")}
          </Link>
        )}
      </li>
      <li>
        {project.loading ? (
          <Skeleton variant="rectangular" width={80} height={15} />
        ) : (
          <Link
            to={"/project/" + id + "/medias"}
            className={current === "medias" ? "active" : ""}
          >
            {t("TABS.medias")}{" "}
            <span>
              {amounts?.medias && amounts.medias > 0 ? amounts.medias : 0}
            </span>
          </Link>
        )}
      </li>
      <li>
        {project.loading ? (
          <Skeleton variant="rectangular" width={80} height={15} />
        ) : (
          <Link
            to={"/project/" + id + "/files"}
            className={current === "files" ? "active" : ""}
          >
            {t("TABS.files")}{" "}
            <span>
              {amounts?.files && amounts.files > 0 ? amounts.files : 0}
            </span>
          </Link>
        )}
      </li>
      <li>
        {project.loading ? (
          <Skeleton variant="rectangular" width={80} height={15} />
        ) : (
          <Link
            to={"/project/" + id + "/mail"}
            className={current === "mail" ? "active" : ""}
          >
            {t("TABS.mails")}{" "}
            <span>
              {amounts?.mail && amounts.mail > 0 ? amounts.mail : 0}
            </span>
          </Link>
        )}
      </li>
      <li>
        {project.loading ? (
          <Skeleton variant="rectangular" width={70} height={15} />
        ) : (
          <Link
            to={"/project/" + id + "/tasks"}
            className={current === "tasks" ? "active" : ""}
          >
            {t("TABS.tasks")}{" "}
            <span>
              {amounts?.tasks && amounts.tasks > 0 ? amounts.tasks : 0}
            </span>
          </Link>
        )}
      </li>
      <li>
        {project.loading ? (
          <Skeleton variant="rectangular" width={50} height={15} />
        ) : (
          <Link
            to={"/project/" + id + "/budget"}
            className={current === "budget" ? "active" : ""}
          >
            {t("TABS.budget")}
          </Link>
        )}
      </li>
      <li>
        {project.loading ? (
          <Skeleton variant="rectangular" width={60} height={15} />
        ) : (
          <Link
            to={"/project/" + id + "/contractors"}
            className={current === "contractors" ? "contractors active" : "contractors"}
          >
            {t("TABS.collaborators")}
          </Link>
        )}
      </li>
      <li>
        {project.loading ? (
          <Skeleton variant="rectangular" width={50} height={15} />
        ) : (
          <Link
            to={"/project/" + id + "/description"}
            className={current === "description" ? "active" : ""}
          >
            {t("TABS.description")}
          </Link>
        )}
      </li>
    </ul>
  );
}

export default Tabs;
