/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";

import ProjectList from "Components/Parts/ProjectList";
import { useAppSelector } from "Redux/hooks";
import {
	getProject,
	getProjectLoading,
} from "Redux/Reducers/Project/ProjectSlice";

import ProjectLoader from "Utils/Loaders/ProjectLoader";
/** Components */

import Tabs from "./Tabs";

import Activities from "./Views/Activities";
import Calendar from "./Views/Calendar";
import Description from "./Views/Description";
import Contributors from "./Views/Contributors";
import Files from "./Views/Files";
import Mails from "./Views/Mails";
import Tasks from "./Views/Tasks";
import Budget from "./Views/Budget";
import Medias from "./Views/Medias";

import Logo from "Assets/Images/logo/logo.png";


/** Modules  */
import AccountIcon from "Components/Parts/Account";

/** utils */
import { getItem } from "Utils/Storage";

/** Skeletons */
import SkeletonList from "./Skeletons/List";
import SkeletonHeader from "./Skeletons/Header";

function Project() {
	const { t } = useTranslation();
	const { tab, id } = useParams();
	const navigate = useNavigate();

	const project = useAppSelector(getProject);
	const loading = useAppSelector(getProjectLoading);

	const [error, setError] = useState(false);
	const [openList, setOpenList] = useState(false);

	if (!id) {
		const currentId = getItem("current_project");
		if (currentId) {
			navigate("/project/" + currentId);
		}
	}

	return (
		<>
			<ProjectLoader
				onLoad={(success: boolean) => !success && setError(true)}
			/>

			{error ? (
				<div className="Project">
					<header></header>
					<div className="content">
						<span className="emptyMessage">
							{t("PROJECT.project_not_found")}
						</span>
					</div>
				</div>
			) : (
				<div className="Project">
					<header>
						{loading ? (
							<SkeletonHeader />
						) : (
							<>
								{project.id > 0 ? (
									<div className="project_header">
										<img className="app_icon" src={Logo} alt="Sayto" />
										<div className="projectName">
											<div className="thumb" onClick={() => setOpenList(true)}>
												{project.datas.name.substr(0, 1)}
											</div>

											<strong onClick={() => setOpenList(true)}>
												{project.datas.name.length > 50
													? project.datas.name.substr(0, 50) + "..."
													: project.datas.name}
											</strong>

											<ProjectList
												openList={openList}
												onClose={() => setOpenList(false)}
											/>
										</div>
									</div>
								) : (
									<div className="projectName"></div>
								)}
							</>
						)}

						{!loading && <AccountIcon />}
					</header>

					<div className="infos">
						<Tabs />
					</div>

					<div className="content">
						{(tab === "" ||
							tab === undefined ||
							tab === "project" ||
							tab === "activities") && (
								<>{loading ? <SkeletonList /> : <Activities />}</>
							)}
						{tab === "calendar" && (
							<>{loading ? <SkeletonList /> : <Calendar />}</>
						)}
						{tab === "description" && <Description />}
						{tab === "contractors" && <Contributors />}
						{tab === "tasks" && <Tasks />}
						{tab === "medias" && <Medias />}
						{tab === "files" && <Files />}
						{tab === "mail" && <Mails />}
						{tab === "budget" && <Budget />}
					</div>
				</div>
			)}
		</>
	);
}

export default Project;
