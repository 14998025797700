/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import Details from "../Events/Details";
import Tags from "Components/Parts/Tags";
import Avatars from "Components/Parts/Avatars";

import Filters from "Components/Pages/Project/Views/ActivitiesFilters";
import {hasSomeParentTheClass} from "Utils/Globals";
import Tooltip from "@mui/material/Tooltip";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
/** Redux */

import {useAppSelector, useAppDispatch} from "Redux/hooks";
import {getProject, getContractors} from "Redux/Reducers/Project/ProjectSlice";
import {
	setFilters,
	clearEvents,
	getFilters,
	getAmounts,
	setCurrentEvent,
	setIsOpen,
	getModalAction,
	getCurrentEvent,
	setModalAction,
	loadEvents,
} from "Redux/Reducers/Project/EventsSlice";

import {setNotification} from "Redux/Reducers/App/AppSlice";
import AddEvent from "Components/Pages/Project/Events/AddEvent";

/** Utils */
import {convertStringToDate, checkIfDateIsExpired} from "Utils/DateFormatter";

/** Api  */
import {getEvents, putEvent} from "Api/Controllers/EventController";

function Tasks() {
	const {t} = useTranslation();
	const dispatch = useAppDispatch();
	const project = useAppSelector(getProject);
	const amounts = useAppSelector(getAmounts);
	const filters = useAppSelector(getFilters);
	const modalAction = useAppSelector(getModalAction);
	const currentEvent = useAppSelector(getCurrentEvent);
	const contributorsList = useAppSelector(getContractors);

	const defaultFilters: any = {...filters};
	const [ActiveFilters, setActiveFilters] = useState(defaultFilters);
	const [loading, setLoading] = useState(true);
	const [events, setEvents] = useState([] as any);

	/** Functions */

	const handleScrollList = (e: any) => {
		const {scrollTop, scrollHeight, clientHeight} = e.target;
		const newFilters = structuredClone(filters);

		if (scrollTop <= 5) {
			newFilters.page = newFilters.page ? newFilters.page - 1 : 1;
			//dispatch(setFilters(newFilters));
			increaseEvents(false);
		}
		if (scrollTop + clientHeight >= scrollHeight - 10) {
			// refresh events
			newFilters.page = newFilters.page ? newFilters.page + 1 : 2;
			const currentPage = filters.page ? filters.page : 1;
			increaseEvents(true);
		}
	};
	const reloadEvents = (currentFilters: any) => {
		const activeFilters = structuredClone(currentFilters);
		activeFilters.id_project = project.id;
		activeFilters.page = 1;
		activeFilters.limit = 20;
		activeFilters.type = "task";
		//dispatch(setFilters(activeFilters));
		getEvents(activeFilters).then(
			(returnDatas: any) => {
				if (returnDatas?.status === "success") {
					setEvents(returnDatas?.data.events);
				}
			},
			(error) => {
				console.log("error", error);
			}
		);
	};

	const increaseEvents = (add: boolean) => {
		const activeFilters = structuredClone(filters);
		if (add) {
			activeFilters.page = activeFilters.page ? activeFilters.page + 1 : 2;
		} else {
			activeFilters.page = activeFilters.page ? activeFilters.page - 1 : 1;
		}
		activeFilters.id_project = project.id;
		activeFilters.limit = 20;
		if (
			activeFilters.page > 1 &&
			(activeFilters.page - 1) * 20 <= amounts.events
		) {
			//dispatch(setFilters(activeFilters));
			setLoading(true);
			getEvents(activeFilters).then(
				(returnDatas: any) => {
					if (returnDatas?.status === "success") {
						const newEvents = [...events, ...returnDatas?.data.events];
						setEvents(newEvents);
						setLoading(false);
					}
				},
				(error) => {
					console.log("error", error);
				}
			);
		}
	};

	const updateTask = (event: any) => {
		const eventsList = [...events];
		const index = eventsList.findIndex((item: any) => item.id === event.id);
		eventsList[index] = event;
		setEvents(eventsList);
		putEvent(event).then((result: any) => {
			if (result.status === "success") {
				dispatch(
					setNotification({type: "success", message: "Tâche mise à jour"})
				);
			}
		});
	};

	const changeStatus = (status: string, index: number) => {
		const newEvent = structuredClone(events[index]);
		newEvent.attr.status = status;
		updateTask(newEvent);
	};

	/** Hooks */
	useEffect(() => {
		dispatch(clearEvents({}));
		setLoading(true);

		const filters = {
			id_project: project.id,
			type: "task",
			page: 1,
			limit: 15,
		};
		//dispatch(setFilters(filters));
	}, [dispatch, project.id]);

	useEffect(() => {
		setLoading(false);
	}, [events]);

	useEffect(() => {
		setLoading(true);
		//dispatch(setFilters(ActiveFilters));
		reloadEvents(ActiveFilters);
	}, [project.id, ActiveFilters]);

	// listen to event modification
	useEffect(() => {
		if (modalAction && modalAction !== "waiting") {
			modalAction === "add" && reloadEvents(filters);

			const updatingEvents = {...events};

			const index = Object.values(updatingEvents).findIndex(
				(event: any) => event.id === currentEvent.id
			);

			if (modalAction === "edit" && currentEvent.id) {
				updatingEvents[index] = currentEvent;
				const eventsList = Object.values(updatingEvents);
				setEvents(eventsList);
			}

			if (modalAction === "delete" && currentEvent.id) {
				delete updatingEvents[index];
				const eventsList = Object.values(updatingEvents);
				setEvents(eventsList);
				setTimeout(() => {
					dispatch(loadEvents({}));
				}, 300);
			}

			dispatch(setModalAction("waiting"));
		}
	}, [modalAction]);

	return (
		<div className="tabContent tasks">
			<Details />

			<div className="filters container">
				<Filters
					hideTypes
					filtersParams={{}}
					onFilter={(filters: any) => {
						setActiveFilters(filters);
						//setFromAnotherPage(false);
					}}
				/>
			</div>
			<div className="filters title_actions">
				<h3 className="title with_spacer">{t("EVENT.LABELS.list_tasks")}</h3>
				<div className="spacer"></div>
				<AddEvent selected={["task"]} />
			</div>

			{events && events.length > 0 ? (
				<div className={"listContainer"}>
					<div className="columns">
						<div className="item">
							<div className="iconpriority"></div>
							<div className="checkaction"></div>
							<div className="task">
								<span>{t("LIST.task")}</span>
							</div>
							<div className="due_date">
								<span>{t("LIST.due_date")}</span>
							</div>
							<div className="status">
								<span>{t("LIST.status")}</span>
							</div>
							<div className="priority">
								<span>{t("LIST.priority")}</span>
							</div>{" "}
							<div className="priority">
								<span>{t("LIST.receiver")}</span>
							</div>
							<div className="tags">
								<span>{t("LIST.tags")}</span>
							</div>
						</div>
					</div>
					<div className="list" onScroll={handleScrollList}>
						{loading && (
							<div className="loading">
								<CircularProgress />
							</div>
						)}

						{events.map((event: any, index: number) => {
							let avatarsContractors = [];
							const attributes =
								typeof event?.attr === "string"
									? JSON.parse(event?.attr)
									: event?.attr;

							let contributorsAttr =
								typeof attributes?.contractors === "string"
									? JSON.parse(attributes?.contractors)
									: attributes?.contractors;

							if (contributorsList && contributorsAttr) {
								let AvatarContractors: any = contributorsList.filter(
									(item: any) => {
										return contributorsAttr.includes(item.id);
									}
								);
								AvatarContractors = AvatarContractors.map((item: any) => {
									return {
										value: item.id,
										label: item.company,
										color: item.color,
									};
								});
								avatarsContractors = AvatarContractors;
							}

							return (
								<div
									onClick={(element) => {
										if (
											!hasSomeParentTheClass(element.target, ["checkButton"])
										) {
											dispatch(setCurrentEvent(event));
											dispatch(setIsOpen(true));
										}
									}}
									className="item"
									key={index}
								>
									<div className="iconpriority">
										{event?.attr?.priority && (
											<>
												{event?.attr?.priority === "low" && (
													<Tooltip title="Priorité faible">
														<LowPriorityIcon className="low" />
													</Tooltip>
												)}
												{event?.attr?.priority === "high" && (
													<Tooltip title="Priorité élevée">
														<PriorityHighIcon className="high" />
													</Tooltip>
												)}
											</>
										)}
									</div>
									<div className="checkaction">
										{event?.attr?.status && event?.attr?.status === "done" ? (
											<CheckCircleIcon
												onClick={() => {
													changeStatus("started", index);
												}}
												className="checkButton done"
											/>
										) : (
											<RadioButtonUncheckedIcon
												className="checkButton"
												onClick={() => {
													changeStatus("done", index);
												}}
											/>
										)}
									</div>
									<div className="task">
										<span
											className={
												event?.attr?.status && event?.attr?.status === "done"
													? "title done " + event?.attr?.priority
													: "title " + event?.attr?.priority
											}
										>
											{event.title !== "" ? (
												<>
													{event.title.substr(0, 70) === event.title
														? event.title
														: event.title.substr(0, 70) + "..."}
												</>
											) : (
												<>Nouvelle tâche</>
											)}
										</span>
									</div>
									<div className="due_date">
										<span
											className={
												checkIfDateIsExpired(event.event_end)
													? "past"
													: "normal"
											}
										>
											{event.event_end
												? convertStringToDate(event.event_end)
												: "pas d'échéance"}
										</span>
									</div>
									<div className="status">
										{event?.attr?.status && (
											<span className={"tag " + event?.attr?.status}>
												{t("TASK.STATUS." + event.attr.status)}
											</span>
										)}
									</div>
									<div className="priority">
										{event?.attr?.priority && (
											<span className={"tag " + event?.attr?.priority}>
												{t("TASK.PRIORITY." + event.attr.priority)}
											</span>
										)}
									</div>
									<div className="contributors">
										<Avatars items={avatarsContractors} />
									</div>
									<div className="tags">
										{event.attr.tags && event.attr.tags.length > 0 ? (
											<Tags
												order="left"
												tags={event.attr.tags}
												handleClick={(tag: string) => {
													const newFilters = {...filters, search: tag};
													//dispatch(setFilters(newFilters));
												}}
											/>
										) : (
											<span className="no_tag">Aucune</span>
										)}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			) : (
				<div className="">
					<span className="emptyMessage">Aucune tâche disponible</span>
				</div>
			)}
		</div>
	);
}

export default Tasks;
