import React, { useState, useEffect, createRef } from "react";
import ReactPlayer from "react-player";

import { useAppSelector, useAppDispatch } from "Redux/hooks";

import {
	close,
	getStatus,
	getPictures,
	setPictures,
	setVideo,
	getVideo,
	getCurrent,
} from "Redux/Reducers/Lightbox/LightboxSlice";

/** Images */

import closeButton from "Assets/Images/dark/navigation/close.png";
import nextButton from "Assets/Images/clear/navigation/next.png";
import prevButton from "Assets/Images/clear/navigation/previous.png";

function LightBox() {
	const pictures = useAppSelector(getPictures);
	const status = useAppSelector(getStatus);
	const currentIndex = useAppSelector(getCurrent);
	const video = useAppSelector(getVideo);
	const dispatch = useAppDispatch();

	const [isOpen, setIsOpen] = useState(false);
	const [isZoom, setIsZoom] = useState(false);
	const [current, setCurrent] = useState(pictures[0]);
	const [index, setIndex] = useState(0);

	const lightboxRef: any = createRef();

	useEffect(() => {
		if (status === "open") {
			setIsOpen(true);

			if (lightboxRef.current) {
				lightboxRef.current.focus();
			}
		}
	}, [lightboxRef, status]);

	useEffect(() => {
		dispatch(setVideo({} as any));
		if (pictures.length) {
			setCurrent(pictures[currentIndex]);
			setIndex(currentIndex);
		}
	}, [pictures, currentIndex]);

	useEffect(() => {
		if (video?.url) {
			dispatch(setPictures([] as any));
		}
	}, [video]);
	const prevPicture = () => {
		let prevIndex = index - 1;
		if (index === 0) {
			prevIndex = pictures.length - 1;
		}
		setIndex(prevIndex);
		setCurrent(pictures[prevIndex]);
	};
	const nextPicture = () => {
		let nextIndex = index + 1;
		if (index === pictures.length - 1) {
			nextIndex = 0;
		}
		setIndex(nextIndex);
		setCurrent(pictures[nextIndex]);
	};

	const closeLightbox = () => {
		dispatch(close());
		setIsOpen(false);
		dispatch(setPictures([] as any));
		dispatch(setVideo({} as any));
	};

	const handleKeyboardPress = (e: any) => {
		if (e.keyCode === 39) {
			nextPicture();
		}
		if (e.keyCode === 37) {
			prevPicture();
		}
	};

	return (
		<>
			<div
				ref={lightboxRef}
				className={"LightBox " + (isOpen ? "open" : "close")}
				tabIndex={0}
				onKeyDown={handleKeyboardPress}
			>
				<div className={"overlay"} onClick={closeLightbox}></div>
				<button
					aria-label={"close"}
					className={"close"}
					onClick={closeLightbox}
				>
					<img src={closeButton} alt="close" />
				</button>

				{!video?.url && pictures && (
					<>
						{pictures.length > 1 && (
							<>
								<button
									aria-label={"previous"}
									onClick={prevPicture}
									className={"nav_button previous"}
								>
									<img src={prevButton} alt="previous" />
								</button>
								<button
									aria-label={"next"}
									onClick={nextPicture}
									className={"nav_button next"}
								>
									<img src={nextButton} alt="next" />
								</button>
							</>
						)}

						<div
							className={isZoom ? "pictureContainer zoom" : "pictureContainer"}
						>
							{current?.url && (
								<>
									<img
										src={current.url}
										alt={current.name}
										onClick={() => {
											setIsZoom(!isZoom);
										}}
									/>
									<div className={"caption"}>{current.name}</div>
								</>
							)}
						</div>
					</>
				)}

				{pictures.length === 0 && video?.url && (
					<>
						<ReactPlayer
							url={video.url}
							controls={true}
							height={"100%"}
							style={{ backgroundColor: "#000"}}
						/>
					</>
				)}
			</div>
		</>
	);
}

export default LightBox;
