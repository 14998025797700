import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";

import Config from "./Config";

import App from "./App";

import "./Styles/App.css";
import "./Utils/i18n";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./Styles/Theme";
import { PinterestPixel } from "Utils/Scripts/Pinterest";

import logo from "Assets/Images/logo/logo.png";
import apple from "Assets/Images/stores/apple.png";
import google from "Assets/Images/stores/google.png";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const acceptResponsive = () => {
	const acceptedUrl = ["validate"];
	const urlParameters = window.location.pathname.split("/");
	let isAccepted = false;

	urlParameters.forEach((element) => {
		if (acceptedUrl.includes(element)) {
			isAccepted = true;
		}
	});

	return isAccepted;
};

root.render(
	<Provider store={store}>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Sayto</title>
			<meta name="robots" content="noindex,nofollow" />
			<meta name="viewport" content="width=device-width, initial-scale=1" />
			<meta http-equiv="cache-control" content="no-cache" />
			<meta http-equiv="expires" content="0" />
			<meta http-equiv="pragma" content="no-cache" />
		</Helmet>
		<PinterestPixel />

		<GoogleOAuthProvider clientId={Config.googleClientId}>
			<ThemeProvider theme={theme}>
				<div className={acceptResponsive() ? "deviceView hide" : "deviceView"}>
					<img className="logo" src={logo} alt="Sayto" />
					<span>Envie de découvrir Sayto ?</span>
					<strong>
						Téléchargez l'app mobile pour profiter d'une expérience optimale !
					</strong>

					<div className="stores">
						<a href="https://apps.apple.com/be/app/sayto/id6471659290">
							<img className="stores" src={apple} alt="App Store" />
						</a>
						<a href="https://play.google.com/store/apps/details?id=com.sayto.app&hl=fr&gl=be">
							<img className="stores" src={google} alt="Play Store" />
						</a>
					</div>
				</div>
				<div
					className={acceptResponsive() ? "desktopView show" : "desktopView"}
				>
					<App />
				</div>
			</ThemeProvider>
		</GoogleOAuthProvider>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

/*
function sendToAnalytics({ id, name, value }) {
  ga('send', 'event', {
    eventCategory: 'Web Vitals',
    eventAction: name,
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    eventLabel: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate
  });
}


reportWebVitals(sendToAnalytics);

*/
