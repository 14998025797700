import { defaultReturnObject, ApiReturnDatas } from "Types/Api";

import paymentsApiObject from "Api/Models/Payments";
const paymentsApi = new paymentsApiObject();

export const postPaymentIntent = (props: { id_plan: number; mode: string }) => {
	return new Promise((resolve, reject) => {
		paymentsApi.postPaymentIntent(props).then(
			(result: any) => {
				resolve(result);
			},
			(error: any) => {
				reject(error);
			}
		);
	});
};
