import abstractApiObject from "./abstractApiObject";

class planApiObject extends abstractApiObject {
  constructor() {
    super();
    this.controller = "plan";
  }

  getList() {
    const params = {
      url: this.controller + "/list",
      method: "GET",
      body: {},
      secured: false,
    };
    return new Promise((resolve, reject) => {
      this.call(params).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  postCancel() {
    const params = {
      url: this.controller + "/cancel",
      method: "POST",
      body: {},
      secured: true,
    };
    return new Promise((resolve, reject) => {
      this.call(params).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}

export default planApiObject;
