/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import { Helmet } from "react-helmet";

declare global {
	// Declare the new pinterest function
	interface Window {
		pintrk: Function | undefined;
	}
}

export const PinterestPixel = () => {
	return (
		<Helmet>
			<script>
				{`!(function (e) {
                    if (!window.pintrk) {
                        window.pintrk = function () {
                            window.pintrk.queue.push(Array.prototype.slice.call(arguments));
                        };
                        var n = window.pintrk;
                        (n.queue = []), (n.version = "3.0");
                        var t = document.createElement("script");
                        (t.async = !0), (t.src = e);
                        var r = document.getElementsByTagName("script")[0];
                        r.parentNode.insertBefore(t, r);
                    }
                })("https://s.pinimg.com/ct/core.js");
                pintrk("load", "2614358730356", { em: "<user_email_address>" });
                pintrk("page");`}
			</script>
			<noscript>
				{`
					<img
						height="1"
						width="1"
						style="display:none;"
						alt=""
						src="https://ct.pinterest.com/v3/?event=init&tid=2614358730356&pd[em]=<hashed_email_address>&noscript=1"
					/>
				`}
			</noscript>
		</Helmet>
	);
};
