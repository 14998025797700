import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import LoopIcon from "@mui/icons-material/Loop";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";

import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { getUserId } from "Redux/Reducers/User/UserSlice";

import AddProject from "Components/Pages/Project/AddProject";
import EditProject from "Components/Pages/Project/EditProject";

import ProjectApi from "Api/Models/Project";
import { ProjectState } from "Types/Project";
import { getPlan } from "Redux/Reducers/User/UserSlice";
import { setItem } from "Utils/Storage";
import { getProject, setProject } from "Redux/Reducers/Project/ProjectSlice";

const defaultProjectsList = [] as ProjectState[];
const projectApi = new ProjectApi();

const ProjectList = (props: {
	position?: { vertical: string; horizontal: string };
	openList: boolean;
	onClose: Function;
}) => {
	const currentPlan = useAppSelector(getPlan);
	const project = useAppSelector(getProject);

	const userId = useAppSelector(getUserId);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const [projects, setProjects] = useState(defaultProjectsList);
	const [openModalProject, setOpenModalProject] = useState(false);
	const [open, setOpen] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [canAdd, setCanAdd] = useState(false);

	useEffect(() => {
		projectApi.getAll({ id_user: userId }).then(
			(result: any) => {
				setProjects(result);
			},
			(error) => {
				console.log(error);
			}
		);
	}, []);

	useEffect(() => {
		if (currentPlan.projectlimit > 1 || currentPlan.projectlimit === "-1") {
			setCanAdd(true);
		} else {
			setCanAdd(false);
		}
	}, [currentPlan]);

	useEffect(() => {
		canAdd && props.openList && setOpen(true);
	}, [props.openList, canAdd]);

	useEffect(() => {
		!open && props.onClose();
	}, [open]);
	return (
		<>
			<AddProject
				open={openModalProject}
				onClose={() => {
					setOpenModalProject(false);
				}}
			/>
			<EditProject
				project={project}
				open={openEdit}
				onClose={() => {
					setOpenEdit(false);
				}}
				onSave={(projectEdit: any) => {
					dispatch(setProject(projectEdit));
				}}
			/>

			<Tooltip
				title={
					canAdd
						? "Changer de projet"
						: "Passer dans un plan supérieur pour changer de projet"
				}
			>
				<Button
					className={
						canAdd
							? "switch_project_button"
							: "switch_project_button need_upgrade"
					}
					variant="text"
					onClick={() => {
						if (canAdd) {
							setOpen(true);
						}
					}}
				>
					<KeyboardArrowDownIcon />
				</Button>
			</Tooltip>

			<div className="project_header_buttons">
				<Tooltip title="Modifer le projet">
					<Button
						onClick={() => setOpenEdit(true)}
						color="info"
						className="projectButtons"
					>
						<EditRoundedIcon />
					</Button>
				</Tooltip>

				<Tooltip
					title={
						parseInt(currentPlan.projectlimit) > 1 ||
						currentPlan.projectlimit === "-1"
							? "Créer un nouveau projet"
							: "Passer dans un plan supérieur pour ajouter d’autres projets"
					}
				>
					<Button
						className={
							parseInt(currentPlan.projectlimit) > 1 ||
							currentPlan.projectlimit === "-1"
								? "add_project_button"
								: "add_project_button need_upgrade"
						}
						variant="text"
						onClick={() => {
							if (
								parseInt(currentPlan.projectlimit) > 1 ||
								currentPlan.projectlimit === "-1"
							) {
								setOpenModalProject(true);
							}
						}}
					>
						<AddRoundedIcon />
					</Button>
				</Tooltip>

				{!canAdd && (
					<div
						className="need_upgrade"
						onClick={() => {
							navigate("/account/subscription");
						}}
					>
						<StarIcon />
						<span>Upgrade</span>
					</div>
				)}
			</div>

			<div className={open ? "modal add_project open" : "modal add_project"}>
				<div className="overlay" onClick={() => setOpen(false)}></div>
				<div className="content module">
					<div className="title">
						<button className="closeModal" onClick={() => setOpen(false)}>
							<CloseIcon />
						</button>
						<span>List de vos </span>
						<strong>Projets</strong>
					</div>

					<div className="module_content list">
						{projects &&
							projects.map((project: any, index: number) => {
								return (
									<div className="item" key={index}>
										<div className="thumb">
											{project?.name?.substring(0, 1)}
										</div>
										<div className="name">{project.name}</div>
										<div className="actions">
											<button
												onClick={() => {
													setItem("current_project", project.uuid);
													window.location.href = "/project/" + project.uuid;
													navigate("/project/" + project.uuid);
													setOpen(false);
												}}
											>
												{t("PROJECT.ACTIONS.see_project")}
											</button>
										</div>
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</>
	);
};

export default ProjectList;
