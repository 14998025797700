export const convertStringToDate = (date: string, hours?: boolean) => {


    if (typeof date === 'string') {
        const newDate = new Date(date);

        if (!hours) {

            return newDate.toLocaleString("fr-fr", {
                year: "numeric",
                month: "short",
                day: "numeric",
            });
        } else {
            return newDate.toLocaleString("fr-fr", {
                hour: "numeric",
                minute: "numeric",
            });
        }
    } else {
        return date;
    }

}

export const formatTime = (date: Date) => {

    /*
    console.log("------------------------");
    console.log(date);
    console.log(typeof date);
    console.log("------------------------");
    */
    if (date === null || date === undefined) return ('');

    if (typeof date === 'string') date = new Date(date);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
}


export const convertStringToDatetime = (date: string) => {

    if (typeof date === 'string') {
        const newDate = new Date(date);

        return newDate.toLocaleString("fr-fr", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        });
    } else {
        return date;
    }

}

export const checkIfDateIsExpired = (date: string) => {
    if (typeof date === "string") {
        const dateToCheck = new Date(date);
        const now = new Date();

        if (dateToCheck.setHours(0, 0, 0, 0) <= now.setHours(0, 0, 0, 0)) {
            return true;
        }
        return false;
    } else {
        return false;
    }
}

export const checkIfDateIsToday = (dayStamp: string) => {
    const dayData = new Date(parseInt(dayStamp));
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    let dayLabel = "le " + dayData.toLocaleDateString("fr-fr");

    if (
        dayData.toLocaleDateString("fr-fr") ===
        today.toLocaleDateString("fr-fr")
    ) {
        dayLabel = "Aujourd'hui";
    } else if (
        dayData.toLocaleDateString("fr-fr") ===
        yesterday.toLocaleDateString("fr-fr")
    ) {
        dayLabel = "Hier";
    }

    return dayLabel;
};


export const formateDateForPicker = (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return {
        formatted: `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`,
        sql: `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`,
        raw: date
    }
};

export const formateTimeForPicker = (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();


    return {
        formatted: `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`,
        sql: `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}  ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`,
        raw: date
    }
};