/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import ReactPlayer from "react-player";
import {useAppDispatch, useAppSelector} from "Redux/hooks";

import {
	open,
	setPictures,
	setVideo,
	getStatus,
} from "Redux/Reducers/Lightbox/LightboxSlice";

import videoPosterImage from "Assets/Images/clear/default/video_default_thumb.png";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

function VideoPlayer(props: any) {
	const {video, deletable, onDelete} = props;
	const status = useAppSelector(getStatus);
	const dispatch = useAppDispatch();

	const type = video.name.split(".").pop();

	useEffect(() => {
		dispatch(setVideo(video));
		dispatch(setPictures([] as any));
	}, [dispatch, video, status]);

	const playerStyle = {
		backgroundColor: "#EEE",
		cursor: "pointer",
		borderRadius: "5px",
	};
	return (
		<>
			<a
				data-fancybox="gallery"
				data-width="640"
				data-height="360"
				href={video.url}
			>
				<div
					className="video"
					style={{
						backgroundColor: "#000",
						width: 180,
						height: 100,
						borderRadius: 3,
					}}
				>
					<ReactPlayer url={video.url} width={180} height={100} />
					{deletable && (
						<button onClick={() => onDelete(video)}>
							<HighlightOffOutlinedIcon />
						</button>
					)}
				</div>
			</a>
		</>
	);
}

export default VideoPlayer;
