import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const md5 = require("md5");

export interface UserState {
	id: number;
	email: string;
	saytomail: string;
	id_plan: number;
	name: string;
	firstname: string;
	logged: boolean;
	wizard_done: boolean;
	picture: string;
	company: string;
	vat: string;
	address: string;
	zip: string;
	city: string;
	country: string;
	settings: any;
	plan: any;
	storagelimit: number;
	used_space: number;
}

const initialState: UserState = {
	id: 0,
	email: "",
	saytomail: "",
	storagelimit: 0,
	used_space: 0,
	id_plan: 1,
	name: "",
	firstname: "",
	logged: false,
	wizard_done: false,
	picture: "",
	company: "",
	vat: "",
	address: "",
	zip: "",
	city: "",
	country: "BE",
	settings: {},
	plan: {},
};

export const UserSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		checkToken: (state, action: PayloadAction<any>) => {
			state.logged = true;
		},
		setUser: (state, action: PayloadAction<any>) => {
			state.id = action.payload?.id;
			state.id_plan = action.payload?.id_plan;
			state.name = action.payload?.name ? action.payload?.name : "";
			state.firstname = action.payload?.firstname
				? action.payload?.firstname
				: "";
			state.email = action.payload?.email;
			state.saytomail = action.payload?.saytomail;
			state.storagelimit = action.payload?.storagelimit;
			state.used_space = action.payload?.used_space;
			state.company = action.payload?.company;
			state.email = action.payload?.email;
			state.picture = action.payload?.picture;
			state.vat = action.payload?.vat;
			state.address = action.payload?.address;
			state.zip = action.payload?.zip;
			state.city = action.payload?.city;
			state.country = action.payload?.country;
			state.wizard_done = parseInt(action.payload?.wizard_done) ? true : false;

			state.picture = action?.payload?.picture
				? action.payload.picture
				: "https://www.gravatar.com/avatar/" +
				  md5(action.payload?.email) +
				  "?d=mp";
		},
		disconnect: (state, action: PayloadAction<any>) => {
			state = initialState;
			window.location.href = "/login";
		},
		setSettings: (state, action: PayloadAction<any>) => {
			state.settings = { ...state.settings, ...action.payload };
		},
		setPlan: (state, action: PayloadAction<any>) => {
			state.plan = action.payload;
		},
	},
});

export const { setUser, checkToken, disconnect, setSettings } =
	UserSlice.actions;
export const getUser = (state: RootState) => state.user;
export const getUserId = (state: RootState) => state.user.id;

export const getSettings = (state: RootState) => state.user.settings;
export const getPlan = (state: RootState) => state.user.plan;

export default UserSlice.reducer;
