/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import FullCalendar from "@fullcalendar/react";
import multiMonthPlugin from "@fullcalendar/multimonth";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";
import CircularProgress from "@mui/material/CircularProgress";

import Button from "@mui/material/Button";

/** Components */
import Details from "Components/Pages/Project/Events/Details";
import AddEvent from "Components/Pages/Project/Events/AddEvent";

import ShareAgendaDialog from "Components/Parts/ShareAgendaDialog";
import ShareIcon from "@mui/icons-material/Share";
import StatusConfirmedIcon from "@mui/icons-material/CheckCircle";
import StatusPendingIcon from "@mui/icons-material/ContactSupport";
import StatusCancelledIcon from "@mui/icons-material/DoDisturbOn";

import {formatTime} from "Utils/DateFormatter";

/** Redux */
import {useAppDispatch, useAppSelector} from "Redux/hooks";
import {getProject, getContractors} from "Redux/Reducers/Project/ProjectSlice";
import {
	setCurrentEvent,
	setFilters,
	getFilters,
	setIsOpen,
	getModalAction,
	setModalAction,
	getCurrentEvent,
	loadEvents,
} from "Redux/Reducers/Project/EventsSlice";
import {getUser} from "Redux/Reducers/User/UserSlice";

import COLORS from "Utils/colors";
import {getEvents, postEvent} from "Api/Controllers/EventController";

/** Types */
import {EventType, defaultEvent} from "Types/Event";
import {hasFreeSpace} from "Utils/Locale";
import {el} from "@fullcalendar/core/internal-common";
import {act} from "react-dom/test-utils";

function Calendar() {
	const dispatch = useAppDispatch();
	const {t} = useTranslation();
	const modalAction = useAppSelector(getModalAction);
	const user = useAppSelector(getUser);

	const filters = useAppSelector(getFilters);
	const project = useAppSelector(getProject);

	const contractors = useAppSelector(getContractors);
	const currentEvent = useAppSelector(getCurrentEvent);

	const [events, setEvents] = useState([] as any);
	const [eventsForCalendar, setEventsForCalendar] = useState([]);
	const calendarRef = React.createRef<FullCalendar>();
	const [dateClick, setDateClick] = React.useState("");
	const [shareDialogOpen, setShareDialogOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [freeSpace, setFreeSpace] = useState(hasFreeSpace());

	const [ActiveFilters, setActiveFilters] = useState<any>({});

	useEffect(() => {
		const api = calendarRef?.current?.getApi();

		const activeStart: Date | undefined = api?.view.activeStart;
		const activeEnd: Date | undefined = api?.view.activeEnd;

		const activeFilters = {
			id_project: project.id,
			from: activeStart?.toISOString(),
			to: activeEnd?.toISOString(),
			limit: 30000,
			type: "date",
		};

		reloadEvents(activeFilters);
	}, [project.id, dispatch]);

	useEffect(() => {
		setFreeSpace(hasFreeSpace());
	}, [user.used_space]);

	useEffect(() => {
		const formattedEvents: any = [];
		// eslint-disable-next-line array-callback-return
		events.map((item: any, index: number) => {
			const attributes =
				typeof item.attr === "string" ? JSON.parse(item.attr) : item.attr;

			let isAllDay = item.attr?.is_meeting ? false : true;

			const firstContractorId =
				item?.attr?.contractors && item?.attr?.contractors.length > 0
					? item.attr.contractors[0]
					: null;

			const firstContractor =
				contractors && Array.isArray(contractors)
					? contractors.find((contractor: any) => {
							return contractor.id === firstContractorId;
					  })
					: null;

			let phaseColor =
				firstContractor && firstContractor?.color
					? firstContractor?.color
					: COLORS[2];

			let borderColor = phaseColor;

			let textColor =
				firstContractor?.color === "#fccd87" ||
				firstContractor?.color === "#a9ce7d"
					? "#000000"
					: "#ffffff";

			const isPending =
				!item?.attr?.date_status || item?.attr?.date_status !== "confirmed";
			// Full calendar is end date exclusive, need to add 1 day more
			const endDate = new Date(item.event_end);
			const startDate = new Date(item.event_start);
			if (endDate > startDate) {
				endDate.setDate(endDate.getDate() + 1);
			}

			const date_status = item?.attr?.date_status;

			const hour = item.attr?.is_meeting ? formatTime(startDate) : "";

			const eventDatas = {
				id: item.id,
				eventstatus: item?.attr?.date_status,
				borderColor: isPending ? borderColor : "transparent",
				backgroundColor: isPending ? phaseColor + "77" : phaseColor,
				textColor: textColor,
				title: item.title,
				timeText: hour,
				start: item.event_start,
				end: endDate,
				allDay: true,
				extendedProps: item,
				classNames: ["event_day", date_status],
			};

			if (item.type !== "date" && eventDatas.end) {
				let start = endDate;
				eventDatas.start = start.setDate(start.getDate());
			}

			formattedEvents.push(eventDatas);
		});

		setLoading(false);
		setEventsForCalendar(formattedEvents);
	}, [events]);

	const reloadFromCalendar = (view: string) => {
		const api = calendarRef?.current?.getApi();

		if (view === "next") {
			api?.next();
		} else if (view === "prev") {
			api?.prev();
		} else if (view !== "") {
			api?.changeView(view);
		} else {
			api?.changeView(api?.view.type);
		}

		const activeStart: Date | undefined = api?.view.activeStart;
		const activeEnd: Date | undefined = api?.view.activeEnd;

		const formattedFrom = activeStart?.toISOString()?.split("T")[0];
		const formattedTo = activeEnd?.toISOString()?.split("T")[0];

		const currentFilters = structuredClone(ActiveFilters);
		currentFilters.from = formattedFrom;
		currentFilters.to = formattedTo;

		setActiveFilters(currentFilters);

		reloadEvents(currentFilters);
	};

	const reloadEvents = (currentFilters: any) => {
		//setLoading(true);
		const activeFilters = structuredClone(currentFilters);
		activeFilters.id_project = project.id;

		getEvents(activeFilters).then((returnDatas: any) => {
			if (returnDatas?.status === "success") {
				setEvents(returnDatas?.data.events);
			}
		});
	};

	const placeholders = {
		year: t("GLOBAL.TIME.year"),
		months_3: t("GLOBAL.TIME.months_3"),
		month: t("GLOBAL.TIME.month"),
		week: t("GLOBAL.TIME.week"),
	};

	// listen to event modification
	useEffect(() => {
		if (modalAction && modalAction !== "waiting") {
			const updatingEvents = {...events};

			const index = Object.values(updatingEvents).findIndex(
				(event: any) => event.id === currentEvent.id
			);

			if (modalAction === "edit" && currentEvent.id) {
				updatingEvents[index] = currentEvent;
				const eventsList = Object.values(updatingEvents);
				reloadEvents(ActiveFilters);
			} else if (modalAction === "delete" && currentEvent.id) {
				delete updatingEvents[index];
				const eventsList = Object.values(updatingEvents);
				setEvents(eventsList);
				setTimeout(() => {
					dispatch(loadEvents({}));
				}, 300);
			} else {
				const api = calendarRef?.current?.getApi();

				const activeStart: Date | undefined = api?.view.activeStart;
				const activeEnd: Date | undefined = api?.view.activeEnd;

				const activeFilters = {
					id_project: project.id,
					from: activeStart?.toISOString(),
					to: activeEnd?.toISOString(),
					limit: 30000,
				};
				reloadEvents(activeFilters);
			}
			dispatch(setCurrentEvent(defaultEvent));
			dispatch(setModalAction("waiting"));
		}
	}, [modalAction]);

	useEffect(() => {}, [eventsForCalendar]);

	const createDirectDate = (date: string) => {
		const currentHour = new Date().getHours();
		const currentMinutes = new Date().getMinutes();
		const meetingDay = new Date(date);
		meetingDay.setHours(currentHour + 2);
		meetingDay.setMinutes(currentMinutes);

		const newDate = {
			id_project: project.id,
			id_user: user.id,
			title: "Nouveau rendez-vous",
			content: "",
			type: "date",
			event_start: meetingDay.toISOString(),
			event_end: meetingDay.toISOString(),
			attr: {
				is_meeting: true,
			},
		};
		postDate(newDate);
	};

	const createDirectPhase = (from: string, to: string) => {
		const newDate = {
			id_project: project.id,
			id_user: user.id,
			title: "Nouvelle intervention",
			content: "",
			type: "date",
			event_start: from,
			event_end: to,
			attr: {
				is_meeting: false,
			},
		};
		postDate(newDate);
	};

	const postDate = (newDate: any) => {
		postEvent(newDate).then((newEventCreated: any) => {
			const api = calendarRef?.current?.getApi();

			const activeStart: Date | undefined = api?.view.activeStart;
			const activeEnd: Date | undefined = api?.view.activeEnd;

			const activeFilters = {
				id_project: project.id,
				from: activeStart?.toISOString(),
				to: activeEnd?.toISOString(),
				limit: 30000,
				type: "date",
			};
			reloadEvents(activeFilters);

			dispatch(setCurrentEvent(newEventCreated));
			dispatch(setIsOpen(true));
		});
	};

	const renderEventContent = (eventInfo: any) => {
		return (
			<div
				style={{
					display: "inline-flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{(eventInfo.event.extendedProps.eventstatus === "pending" ||
					!eventInfo.event.extendedProps.eventstatus) && (
					<StatusPendingIcon style={{marginRight: "5px"}} />
				)}
				{eventInfo.event.extendedProps.eventstatus === "confirmed" && (
					<StatusConfirmedIcon
						style={{marginRight: "5px"}}
						sx={{color: "#a9ce7d"}}
					/>
				)}
				{eventInfo.event.extendedProps.eventstatus === "cancelled" && (
					<StatusCancelledIcon
						style={{marginRight: "5px"}}
						sx={{color: "#99201c"}}
					/>
				)}
				<strong style={{marginRight: "5px"}}>
					{eventInfo.event.extendedProps.timeText}{" "}
				</strong>
				<p>{eventInfo.event.title}</p>
			</div>
		);
	};

	return (
		<div className="calendar_view ">
			<Details />
			<ShareAgendaDialog
				open={shareDialogOpen}
				onClose={() => {
					setShareDialogOpen(false);
				}}
			/>
			<>
				{/*}
				<div className="filters container">
					<Filters
						filtersParams={{}}
						onFilter={(filters: any) => {
							setActiveFilters(filters);
							//setFromAnotherPage(false);
						}}
					/>
				</div>
					*/}
				<div className="filters title_actions">
					<h3 className="title with_spacer compact">Agenda</h3>
					<a className="shareButton" onClick={() => setShareDialogOpen(true)}>
						<ShareIcon /> {t("GLOBAL.ACTIONS.export_calendar")}...
					</a>

					<div className="spacer"></div>
					<AddEvent selected={["meeting", "date"]} distinct />
				</div>
			</>

			<div className="calendar">
				{loading && (
					<div className="loading">
						<CircularProgress />
					</div>
				)}
				<FullCalendar
					ref={calendarRef}
					locale={frLocale}
					plugins={[dayGridPlugin, multiMonthPlugin, interactionPlugin]}
					initialView="multiMonthYear"
					views={{
						multiMonthCustom: {
							type: "multiMonth",
							duration: {months: 3},
						},
						multiYearCustom: {
							type: "multiMonth",
							duration: {months: 12},
						},
					}}
					events={eventsForCalendar}
					eventContent={renderEventContent}
					headerToolbar={{
						left: "prevCustom,nextCustom today",
						center: "title",
						right: "multiYearCustom,multiMonthCustom,monthView,weekView",
					}}
					customButtons={{
						nextCustom: {
							icon: "chevron-right",
							click: () => {
								reloadFromCalendar("next");
							},
						},
						prevCustom: {
							icon: "chevron-left",
							click: () => {
								reloadFromCalendar("prev");
							},
						},
						multiYearCustom: {
							text: placeholders.year,
							click: () => {
								reloadFromCalendar("multiMonthYear");
							},
						},
						multiMonthCustom: {
							text: placeholders.months_3,
							click: () => {
								reloadFromCalendar("multiMonthCustom");
							},
						},
						monthView: {
							text: placeholders.month,
							click: () => {
								reloadFromCalendar("dayGridMonth");
							},
						},
						weekView: {
							text: placeholders.week,
							click: () => {
								reloadFromCalendar("dayGridWeek");
							},
						},
					}}
					selectable
					dayMaxEvents={3}
					eventClick={(element) => {
						dispatch(setCurrentEvent(element.event.extendedProps));
						dispatch(setIsOpen(true));
					}}
					select={(element: any) => {
						if (!freeSpace) {
							return;
						}

						const startDateStr = new Date(element.startStr).toISOString();
						const endDate = new Date(element.endStr);
						endDate.setDate(endDate.getDate() - 1);
						const endDateStr = endDate.toISOString();
						if (startDateStr === endDateStr) {
							createDirectDate(startDateStr);
						} else {
							createDirectPhase(startDateStr, endDateStr);
						}
					}}
				/>
			</div>
		</div>
	);
}

export default Calendar;
