import React, { useState, useEffect, createRef } from "react";
import ReactPlayer from "react-player";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

/** Images */

import closeButton from "Assets/Images/dark/navigation/close.png";
import nextButton from "Assets/Images/clear/navigation/next.png";
import prevButton from "Assets/Images/clear/navigation/previous.png";

function Fancybox(props: any) {
	const containerRef = React.useRef(null);

	useEffect(() => {
		const container = containerRef.current;

		const delegate = props.delegate || "[data-fancybox]";
		const options = props.options || {};

		NativeFancybox.bind(container, delegate, options);

		return () => {
			NativeFancybox.unbind(container);
			NativeFancybox.close();
		};
	});

	return <div ref={containerRef}>{props.children}</div>;
}

export default Fancybox;
