/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import { hasSomeParentTheClass } from "Utils/Globals";

/* icons */
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import VideocamIcon from "@mui/icons-material/Videocam";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

import ReactPlayer from "react-player";
import FancyBox from "Components/Parts/Fancybox";

/** Utils */
import { convertStringToDate } from "Utils/DateFormatter";

/** Components */
import Tags from "Components/Parts/Tags";
import Avatars from "Components/Parts/Avatars";
import { EventType, EventPropsType } from "Types/Event";
import SoundPlayer from "Components/Pages/Project/Events/SoundPlayer";

import videoPosterImage from "Assets/Images/clear/default/video_default_thumb.png";

/** Redux */
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
	setFilters,
	getFilters,
	getAmounts,
} from "Redux/Reducers/Project/EventsSlice";

import {
	open,
	getStatus,
	setPictures,
	setVideo,
	setCurrent,
} from "Redux/Reducers/Lightbox/LightboxSlice";

import { getContractors } from "Redux/Reducers/Project/ProjectSlice";

function ActivitiesMediasList(props: any) {
	const { onOpen, files } = props;
	const { t } = useTranslation();
	const currentFilters = useAppSelector(getFilters);
	const amounts = useAppSelector(getAmounts);
	const dispatch = useAppDispatch();

	const handleScrollList = (e: any) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;

		if (scrollTop + clientHeight >= scrollHeight - 10) {
			// refresh events
			const newFilters = structuredClone(currentFilters);
			newFilters.page = newFilters.page ? newFilters.page + 1 : 2;

			if (newFilters.page * 10 <= amounts.events + 10) {
				//dispatch(setFilters(newFilters));
			}
		}
	};

	return (
		<>
			<div className={"listContainer"}>
				<div className="columns">
					<div className="item">
						<div className="file">
							<span>{t("LIST.file")}</span>
						</div>
						<div className="event">
							<span>{t("LIST.event")}</span>
						</div>
						<div className="date">
							<span>{t("LIST.date")}</span>
						</div>
					</div>
				</div>
				<div className="list" onScroll={handleScrollList}>
					{files.length === 0 && (
						<span className="emptyProject">
							Ce projet n'a aucun fichier. 
						</span>
					)}
					<FancyBox>
						{files.map((file: any, index: number) => {
							const event = file.event;
							return (
								<motion.div
									key={index}
									className="media_item"
									onClick={(element) => {
										!hasSomeParentTheClass(element.target, [
											"tag",
											"picture",
											"pictures",
											"video",
											"file",
										]) && onOpen(event);
									}}
								>
									<EventLine file={file} event={event} />
								</motion.div>
							);
						})}
					</FancyBox>
				</div>
			</div>
		</>
	);
}

const EventLine = (props: { file: any; event: EventType }) => {
	const { event, file } = props;
	const { t } = useTranslation();
	const contributorsList = useAppSelector(getContractors);
	const [avatarsContractors, setAvatarsContractors] = useState<any>([]);
	const [tags, setTags] = useState([]);
	const dispatch = useAppDispatch();

	useEffect(() => {
		const attributes =
			typeof event?.attr === "string" ? JSON.parse(event?.attr) : event?.attr;

		let contributorsAttr =
			typeof attributes?.contractors === "string"
				? JSON.parse(attributes?.contractors)
				: attributes?.contractors;
		let tagsAttr =
			typeof attributes?.tags === "string"
				? JSON.parse(attributes?.tags)
				: attributes?.tags;

		tagsAttr = typeof tagsAttr === "string" ? JSON.parse(tagsAttr) : tagsAttr;
		contributorsAttr =
			typeof contributorsAttr === "string"
				? JSON.parse(contributorsAttr)
				: contributorsAttr;

		setTags(tagsAttr);

		if (contributorsList && contributorsAttr) {
			let AvatarContractors: any = contributorsList.filter((item: any) => {
				return contributorsAttr.includes(item.id);
			});
			AvatarContractors = AvatarContractors.map((item: any) => {
				return { value: item.id, label: item.company, color: item.color };
			});
			setAvatarsContractors(AvatarContractors);
		}
	}, [event]);

	return (
		<>
			<div className="content listMedia">
				{file.type === "picture" && (
					<a data-fancybox="gallery" href={file.url}>
						<div className="listMedia_item">
							<img
								className="picture"
								src={file?.thumb ? file.thumb : file.url}
							/>
							<span>{file.name}</span>
						</div>
					</a>
				)}
				{file.type === "video" && (
					<a data-fancybox="gallery" href={file.url}>
						<div className="listMedia_item">
							<video width="80" height="60" preload="none" className="video clip-thumbnail" poster={videoPosterImage}>
								<source src={file.url} type="video/mp4" />
							</video>
							<span>{file.name}</span>
						</div>
					</a>
				)}
				{file.type === "sound" && (
					<audio
						id={"record_" + file.name}
						controls={true}
						src={file.url}
					></audio>
				)}
			</div>
			<div className="title">
				<span>{event.title ? event.title : t("TYPE." + event.type)}</span>
			</div>
			<div className="date">
				<span>{convertStringToDate(event.event_start)}</span>
			</div>
			<div className="tags">
				<Tags tags={tags} />
			</div>
		</>
	);
};

export default ActivitiesMediasList;
