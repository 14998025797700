/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, Link } from "react-router-dom";
import Alert from "@mui/material/Alert";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

/** Storage */
import { setItem } from "Utils/Storage";

/** Form components */
import TextField from "Components/Parts/TextField";
import FormControl from "@mui/material/FormControl";

/** Images */
import backgroundImage from "Assets/Images/backgrounds/1.jpg";

/** API */
import userApiObject from "Api/Models/User";
const userApi = new userApiObject();

function Reset() {
	const { t } = useTranslation();
	const { hash } = useParams();

	const navigate = useNavigate();
	const [pageLoading, setPageLoading] = useState(false);
	const [requestDone, setRequestDone] = useState(false);

	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [hashIsUsed, sethashIsUsed] = useState(false);

	const resetPassword = () => {
		if (hash && !hashIsUsed) {
			if (newPassword.trim() !== "") {
				userApi.postResetpassword(hash, newPassword).then(
					(result: any) => {
						sethashIsUsed(true);
						setRequestDone(true);
						if (result?.token) {
							setItem("token", result.token);
						}
					},
					(error) => {
						setError(true);
						setErrorMessage(error.message);
						console.log(error);
					}
				);
				setPageLoading(false);
			} else {
				setError(true);
				setErrorMessage("ACCOUNT.FORM.ERRORS.missing_password");
				setError(true);
			}
		} else {
			setError(true);
		}
	};

	/** Placeholders */

	const placeholder = {
		login: t("ACCOUNT.FORM.LABEL.login"),
		password: t("ACCOUNT.FORM.LABEL.password"),
	};

	return (
		<div className="login_container">
			{pageLoading && (
				<div className="loader">
					<CircularProgress />
				</div>
			)}
			<div className="form">
				<div className="content">
					{requestDone ? (
						<div className="switch_account_action success">
							<strong>{t("ACCOUNT.FORM.SUCCESS.password_renewed")}</strong>
							<span>
								{t("ACCOUNT.FORM.SUCCESS.user_can_login_with_new_password")}
							</span>
							<div className="actions">
								<Button variant="contained" onClick={() => navigate("/login")}>
									{t("ACCOUNT.ACTIONS.login")}
								</Button>
							</div>
						</div>
					) : (
						<>
							{hash || error ? (
								<>
									<div className="switch_account_action">
										<strong>
											{t("ACCOUNT.ACTIONS.create_new_password_title")}
										</strong>
										<span>
											{t("ACCOUNT.ACTIONS.create_new_password_label")}
										</span>
									</div>
									<div className="fields">
										<TextField
											error={error}
											value={newPassword}
											placeholder={placeholder.password}
											onUpdate={(password: string) => setNewPassword(password)}
											showPasswordIcon={true}
											type="password"
										/>
									</div>
									<div className="actions">
										<FormControl className="fields" sx={{ width: "80%" }}>
											<Button variant="contained" onClick={resetPassword}>
												{t("ACCOUNT.ACTIONS.create_new_password")}
											</Button>
										</FormControl>
										{error && (
											<Alert severity="error" className="error">
												{t(errorMessage)}
											</Alert>
										)}
									</div>
								</>
							) : (
								<>
									<div className="switch_account_action error">
										<strong>
											{t("ACCOUNT.FORM.ERRORS.password_creation")}
										</strong>
										<span>
											{
												"ACCOUNT.FORM.ERRORS.account_already_validate_or_missing_hash"
											}
										</span>
										<span>{"ACCOUNT.FORM.ERRORS.still_error"}</span>
										<Link to="/support">
											{t("GLOBAL.ACTIONS.contact_support")}
										</Link>
									</div>
								</>
							)}
						</>
					)}
				</div>
			</div>
			<div
				className="presentation"
				style={{
					backgroundImage: `url(${backgroundImage})`,
				}}
			></div>
		</div>
	);
}

export default Reset;
