/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import { motion } from "framer-motion";

import { amount_format } from "Utils/Locale";

/** add medias icons */
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import MicIcon from "@mui/icons-material/Mic";
import VideocamIcon from "@mui/icons-material/Videocam";
import AttachFileIcon from "@mui/icons-material/AttachFile";

/* icons */
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
/** Utils */
import { convertStringToDate, formatTime } from "Utils/DateFormatter";

/** Components */
import Tags from "Components/Parts/Tags";
import Avatars from "Components/Parts/Avatars";
import { EventType } from "Types/Event";

/** Redux */
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { setFilters, getFilters } from "Redux/Reducers/Project/EventsSlice";
import { hasSomeParentTheClass } from "Utils/Globals";

import { getContractors } from "Redux/Reducers/Project/ProjectSlice";

import FancyBox from "Components/Parts/Fancybox";

import SoundPlayer from "./SoundPlayer";
import VideoPlayer from "./VideoPlayer";

const Item = (props: { event: EventType; onOpen: Function }) => {
	const { event, onOpen } = props;
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const contributorsList = useAppSelector(getContractors);
	const filters = useAppSelector(getFilters);
	const [avatarsContractors, setAvatarsContractors] = useState<any>([]);
	const [tags, setTags] = useState([]);

	const [medias, setMedias] = useState<any>({
		picture: 0,
		video: 0,
		sound: 0,
		file: 0,
	});

	useEffect(() => {
		const attributes =
			typeof event?.attr === "string" ? JSON.parse(event?.attr) : event?.attr;

		let contributorsAttr =
			typeof attributes?.contractors === "string"
				? JSON.parse(attributes?.contractors)
				: attributes?.contractors;
		let tagsAttr =
			typeof attributes?.tags === "string"
				? JSON.parse(attributes?.tags)
				: attributes?.tags;

		tagsAttr = typeof tagsAttr === "string" ? JSON.parse(tagsAttr) : tagsAttr;
		contributorsAttr =
			typeof contributorsAttr === "string"
				? JSON.parse(contributorsAttr)
				: contributorsAttr;

		setTags(tagsAttr);

		if (contributorsList && contributorsAttr) {
			let AvatarContractors: any = contributorsList.filter((item: any) => {
				return contributorsAttr.includes(item.id);
			});
			AvatarContractors = AvatarContractors.map((item: any) => {
				return { value: item.id, label: item.company, color: item.color };
			});
			setAvatarsContractors(AvatarContractors);
		} else {
			setAvatarsContractors([]);
		}

		const newMedias: any = {
			picture: 0,
			video: 0,
			sound: 0,
			file: 0,
		};
		if (
			attributes?.files &&
			Array.isArray(attributes?.files) &&
			attributes?.files.length > 0
		) {
			attributes?.files.map((file: any) => {
				if (file.type && typeof file.type === "string") {
					if (file.type.split("image").length > 1) {
						newMedias.picture += 1;
					} else if (file.type.split("video").length > 1) {
						newMedias.video += 1;
					} else if (file.type.split("audio").length > 1) {
						newMedias.sound += 1;
					} else {
						newMedias.file += 1;
					}
				}

				return file;
			});
		}

		setMedias(newMedias);
	}, [event]);

	return (
		<motion.div
			whileTap={{ scale: 1 }}
			onClick={(element: any) => {
				!hasSomeParentTheClass(element.target, [
					"tag",
					"picture",
					"video",
					"file",
				]) && onOpen(event);
			}}
		>
			<div className="event_flux_item">
				<div className="event_header">
					<div className={"type " + event.type}>
						<Tooltip title={t("TYPE." + event.type)}>
							<div>
								{event.type === "task" && <AssignmentTurnedInIcon />}
								{event.type === "date" && <CalendarMonthIcon />}
								{event.type === "text" && <ChatBubbleOutlineIcon />}
								{event.type === "file" && <AttachFileIcon />}
								{event.type === "picture" && <InsertPhotoIcon />}
								{event.type === "video" && <VideocamIcon />}
								{event.type === "sound" && <VolumeUpIcon />}
								{event.type === "mail" && <ForwardToInboxIcon />}
							</div>
						</Tooltip>
					</div>
					<div className="event_action">

						<span className="title">
							{event.title ? (
								event.title
							) : (
								<>
									{event.attr.is_meeting ? (
										<>{t("TYPE.NEW.meeting")}</>
									) : (
										<> {t("TYPE.NEW." + event.type)}</>
									)}
								</>
							)}
						</span>

						{event.attr?.fileType && event.attr.fileType === "estimate" && (
							<div className="file_type estimate">
								<span className="label">{t("EVENT.FILTERS.estimate")}</span>
								<span>{amount_format(event.attr.amount)}</span>
							</div>
						)}
						{event.attr?.fileType && event.attr.fileType === "invoice" && (
							<div className="file_type invoice">
								<span className="label">{t("EVENT.FILTERS.invoice")}</span>
								<span>{amount_format(event.attr.amount)}</span>
							</div>
						)}
					</div>
					<div className="tags">
						{tags && tags.length > 0 ? (
							<Tags
								order="left"
								tags={tags}
								handleClick={(tag: string) => {
									const newFilters = { ...filters, search: tag };
									dispatch(setFilters(newFilters));
								}}
							/>
						) : (
							<span className="no_tag">Aucune étiquette</span>
						)}
					</div>

					<div className="contributors">
						{avatarsContractors && avatarsContractors.length > 0 ? (
							<Avatars order="right" items={avatarsContractors} />
						) : (
							<span className="no_contractor">Aucun intervenant</span>
						)}
					</div>
					<div className={event?.comments > 0 ? "comments" : "comments empty"}>
						{event?.comments > 0 ? (
							<>
								<span>{event.comments}</span>
								<ChatBubbleOutlineIcon />
							</>
						) : (
							<span>{"Aucun commentaire"}</span>
						)}
					</div>
				</div>
				<div className="event_content">
					{event.type === "picture" &&
						event.attr.files &&
						event.attr.files.length > 0 && (
							<FancyBox className="lightbox">
								{event.attr.files.map((picture: any, index: number) => (
									<>
										{index < 3 && (
											<a
												data-fancybox="gallery"
												href={picture.url}
												key={index}
											>
												<img
													className="picture"
													src={picture?.thumb ? picture.thumb : picture.url}
													alt={picture.name}
												/>
											</a>
										)}
										{index === 3 && (
											<span>+{event.attr.files.length - index}</span>
										)}
									</>
								))}
							</FancyBox>
						)}
					{event.type === "video" &&
						event.attr.files &&
						event.attr.files.length > 0 && (
							<FancyBox>
								{event.attr.files.map((video: any, index: number) => (
									<>{index < 3 && <VideoPlayer video={video} />}</>
								))}
							</FancyBox>
						)}
					{event.type === "sound" &&
						event.attr.files &&
						event.attr.files.length > 0 && (
							<>
								<SoundPlayer sounds={event.attr.files} hideDelete hideTitle />
							</>
						)}

					{event.type === "file" &&
						event.attr.files &&
						event.attr.files.length > 0 && (
							<>
								{event.attr.files.map((picture: any, index: number) => (
									<>
										{index < 3 && (
											<a target="_blank" href={picture.url} key={index}>
												<span>{picture.name}</span>
											</a>
										)}
										{index === 3 && (
											<span>+{event.attr.files.length - index}</span>
										)}
									</>
								))}
							</>
						)}
					{event.type === "date" && !event.attr.is_meeting && (
						<div className="date_range">
							{event.event_start !== event.event_end ? (
								<>
									<span>Du {convertStringToDate(event.event_start)}</span>
									<span> </span>
									<span>au {convertStringToDate(event.event_end)}</span>
								</>
							) : (
								<span>Le {convertStringToDate(event.event_end)}</span>
							)}
						</div>
					)}
					{event.type === "date" && event.attr.is_meeting && (
						<>
							<div className="date_range">
								<span>Le {convertStringToDate(event.event_start)}</span>
								{" à "}
								<span>{formatTime(new Date(event.event_start))}</span>
							</div>
						</>

					)}
				</div>
				<div className="event_footer">
					<div className="content"></div>
					{(event.type === "text" ||
						event.type === "mail" ||
						event.type === "task" ||
						event.type === "date") && (
							<>
								<div className="medias">
									{medias.picture > 0 && (
										<span>
											<InsertPhotoIcon />
											{medias.picture}
										</span>
									)}
									{medias.sound > 0 && (
										<span>
											<MicIcon />
											{medias.sound}
										</span>
									)}
									{medias.video > 0 && (
										<span>
											<VideocamIcon />
											{medias.video}
										</span>
									)}
									{medias.file > 0 && (
										<span>
											<AttachFileIcon />
											{medias.file}
										</span>
									)}
								</div>
							</>
						)}
				</div>
			</div>
		</motion.div>
	);
};

export default Item;
