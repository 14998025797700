/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { motion } from "framer-motion";
//import { useTranslation } from "react-i18next";
import { useAppSelector } from "Redux/hooks";

import FancyBox from "Components/Parts/Fancybox";
import { hasSomeParentTheClass } from "Utils/Globals";
import videoPosterImage from "Assets/Images/clear/default/video_default_thumb.png";

import { getFilters, getAmounts } from "Redux/Reducers/Project/EventsSlice";

/** Utils */
import { convertStringToDate } from "Utils/DateFormatter";

/** Types */

function Grid(props: any) {
	const { onOpen, files } = props;
	//const { t } = useTranslation();
	const amounts = useAppSelector(getAmounts);

	const currentFilters = useAppSelector(getFilters);

	const handleScrollList = (e: any) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;

		if (scrollTop + clientHeight >= scrollHeight - 10) {
			// refresh events
			const newFilters = structuredClone(currentFilters);
			newFilters.page = newFilters.page ? newFilters.page + 1 : 2;

			if (newFilters.page * 10 <= amounts.events + 10) {
				//dispatch(setFilters(newFilters));
			}
		}
	};

	const handleOpenEvent = (event: any) => {
		onOpen(event);
	};

	return (
		<div className="gridContainer" onScroll={handleScrollList}>
			{files.length === 0 && (
				<span className="emptyProject">
					Rien n'a encore été ajouté à ce projet.
				</span>
			)}
			<FancyBox>
				<div className="grid_masonry">
					{files.map((file: any, index: number) => {
						const event = file.event;
						return (
							<motion.div
								key={index}
								className={"item "}
								whileTap={{ scale: 0.95 }}
								onClick={(element) => {
									!hasSomeParentTheClass(element.target, [
										"tag",
										"picture",
										"video",
										"file",
									]) && handleOpenEvent(file.event);
								}}
							>
								<div className="content">
									<div className="topInfos">
										<div className="type">
											<span>{event.title}</span>
										</div>
										<div className="date">
											<span>{convertStringToDate(event.event_start)}</span>
										</div>
									</div>
									<div className="mainContent">
										{file.type === "sound" && (
											<div className={"sound"}>
												<audio
													id="record_audio"
													controls={true}
													src={file.url}
													preload="none"
												></audio>
											</div>
										)}

										{file.type === "video" && (
											<div className={"picture"}>
												<a data-fancybox="gallery" href={file.url} key={index}>
													<video
														width="100%"
														height="200"
														preload="none"
														className="video clip-thumbnail"
														autoPlay={false}
														poster={videoPosterImage}
													>
														<source src={file.url} type="video/mp4" />
													</video>
												</a>
											</div>
										)}

										{file.type === "picture" && (
											<div className={"picture"}>
												<a data-fancybox="gallery" href={file.url} key={index}>
													<motion.img
														key={index}
														whileTap={{ scale: 0.9 }}
														src={file.thumb ? file.thumb : file.url}
														alt={file.name}
													/>
												</a>
											</div>
										)}
									</div>
								</div>
							</motion.div>
						);
					})}
				</div>
			</FancyBox>
		</div>
	);
}

export default Grid;
