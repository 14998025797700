/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
//import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { ShepherdTour, ShepherdTourContext } from "react-shepherd";

import { useAppSelector, useAppDispatch } from "Redux/hooks";

import { getProject, setProject } from "Redux/Reducers/Project/ProjectSlice";

import { postProject } from "Api/Controllers/ProjectController";

// sheperd

const tourOptions = {
	defaultStepOptions: {
		cancelIcon: {
			enabled: true,
		},
	},
	useModalOverlay: true,
	cancel: () => {
		return true;
	},
};

function Tour() {
	const navigate = useNavigate();
	const { id } = useParams();
	const project = useAppSelector(getProject);
	const dispatch = useAppDispatch();

	return (
		<ShepherdTour
			steps={[
				{
					id: "intro",
					buttons: [
						{
							classes: "exit_button",
							text: "passer",
							type: "cancel",
						},
						{
							classes: "next_button",
							text: "Continuer",
							type: "next",
						},
					],
					classes: "",
					highlightClass: "highlight",
					scrollTo: false,
					cancelIcon: {
						enabled: false,
					},
					title: "Bienvenue dans Sayto !",
					text: [
						"Les écrans précédents vous ont permis de créer votre projet. <br />Maintenant, nous allons rapidement vous montrer comment <strong>utiliser Sayto</strong>. <br />" +
							"Vous allez voir, c'est <strong>extrêmement simple !</strong>",
					],
					when: {
						hide: () => {
							//navigate("/project/" + id + "/contractors");
						},
					},
				},
				{
					id: "intro",
					attachTo: { element: ".Project .tabs", on: "bottom" },

					buttons: [
						{
							classes: "exit_button",
							text: "Passer",
							type: "cancel",
						},
						{
							classes: "next_button",
							text: "Continuer",
							type: "next",
						},
						{
							classes: "back_button",
							text: "Retour",
							type: "back",
						},
					],
					classes: "arrowTop",
					highlightClass: "highlight",
					scrollTo: false,
					cancelIcon: {
						enabled: false,
					},
					title: "Gérez votre projet",
					text: [
						"Ce menu vous permet d'accéder à l'ensemble des informations de votre projet: <strong>documents, photos, e-mails, tâches, rendez-vous, etc.</strong><br /><br />Il vous permet aussi de contrôler votre budget et de gérer la liste de vos intervenants.",
					],
				},
				{
					id: "intro",
					attachTo: { element: ".add_to_project", on: "left" },

					buttons: [
						{
							classes: "exit_button",
							text: "Passer",
							type: "cancel",
						},
						{
							classes: "next_button",
							text: "Continuer",
							type: "next",
						},
						{
							classes: "back_button",
							text: "Retour",
							type: "back",
						},
					],
					classes: "arrowRight",
					highlightClass: "highlight",
					scrollTo: false,
					cancelIcon: {
						enabled: false,
					},
					title: "Ajouter de l'information",
					text: [
						"Ce bouton vous permet d'ajouter facilement toutes les informations utiles à votre projet. <br />" +
							"Vous pouvez ajouter des documents, des photos, des rendez-vous, des tâches, etc. <br />" +
							"L'ajout des intervenants est également très important. " +
							"<strong>Commençons par cette étape. </strong>",
					],
					when: {
						hide: () => {
							navigate("/project/" + id + "/contractors");
						},
					},
				},
				{
					id: "intro",
					attachTo: { element: ".add_contractor_button", on: "left" },
					beforeShowPromise: function () {
						return new Promise(function (resolve) {
							setTimeout(function () {
								resolve(true);
							}, 500);
						});
					},
					buttons: [
						{
							classes: "exit_button",
							text: "Passer",
							type: "cancel",
						},
						{
							classes: "next_button",
							text: "Terminer",
							type: "cancel",
						},
						{
							classes: "back_button",
							text: "Retour",
							type: "back",
						},
					],
					classes: "arrowRight",
					highlightClass: "highlight",
					scrollTo: false,
					cancelIcon: {
						enabled: false,
					},
					title: "Créez un intervenant",
					text: [
						"Ajoutez les coordonées de votre intervenant. N'oubliez pas son <strong>e-mail</strong> et son numéro de <strong>téléphone portable</strong> si vous souhaitez que Sayto puisse lui envoyer des informations ou des rappels de rendez-vous.<br />",
					],
					when: {
						complete: () => {},

						hide: function () {
							console.log("hide");
							navigate("/project/" + id);
						},
						destroy: function () {
							const newProject = structuredClone(project);

							newProject.attributes.is_new = false;
							const addButton: any = document.querySelector(
								".add_contractor_button"
							);
							addButton?.click();

							const projectToSave = {
								...newProject.datas,
								id: newProject.id,
								attr: newProject.attributes,
							};
							postProject(projectToSave);
							dispatch(setProject(newProject));
						},
						cancel: function () {
							console.log("cancel");
						},
						next: function () {
							console.log("next");
						},
						back: function () {
							console.log("back button !!");
						},
					},
				},
			]}
			tourOptions={tourOptions}
		>
			<TourInit />
		</ShepherdTour>
	);
}

const TourInit = () => {
	const tourContext: any = React.useContext(ShepherdTourContext);

	React.useEffect(() => {
		tourContext?.start();
	}, []);
	return <div></div>;
};

export default Tour;
