/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import COLORS from "Utils/colors";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventIcon from "@mui/icons-material/Event";

import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/fr";
import dayjs from "dayjs";

import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";

import {EventType} from "../../../../Types/Event";

import {
	formatTime,
	formateDateForPicker,
	formateTimeForPicker,
} from "Utils/DateFormatter";

const status = [
	{value: "pending", label: "En attente"},
	{value: "confirmed", label: "Confirmé"},
	{value: "cancelled", label: "Annulé"},
];
function DateEvent(props: {
	event: EventType;
	onEdit: Function;
	editing: boolean;
}) {
	const {event, editing, onEdit} = props;
	const {t} = useTranslation();

	const eventStart = new Date(event.event_start);
	const eventEnd = new Date(event.event_end);

	const [startDate, setStartDate] = useState(event.event_start);
	const [endDate, setEndDate] = useState(event.event_end);

	const [currentEvent, setStateCurrentEvent] = useState(event);
	const [hasChanged, setHasChanged] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState<any>(status[0]);

	const saveEvent = () => {
		onEdit(currentEvent);
	};

	useEffect(() => {
		if (event?.attr?.date_status) {
			const selectedStatus = status.find((element) => {
				return element.value === event.attr.date_status;
			});
			setSelectedStatus(selectedStatus);
		}
		setStateCurrentEvent(event);

		if (event.event_start) {
			const newDate = formateTimeForPicker(new Date(event.event_start));
			setStartDate(newDate.sql);
		} else {
			setStartDate("");
		}

		if (event.event_end) {
			const newDate = formateTimeForPicker(new Date(event.event_end));
			setEndDate(newDate.sql);
		} else {
			setEndDate("");
		}
	}, [event]);

	useEffect(() => {
		const newEvent = {...currentEvent};
		newEvent.event_start = startDate;
		newEvent.event_end = endDate;
		setStateCurrentEvent(newEvent);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate]);

	useEffect(() => {
		hasChanged && saveEvent();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentEvent]);

	return (
		<>
			<div className={"eventDate"}>
				{editing || true ? (
					<>
						{event?.attr?.is_meeting ? (
							<div className="row">
								<div className="col">
									<LocalizationProvider
										dateAdapter={AdapterDayjs}
										adapterLocale="fr"
									>
										<label>{t("TASK.start_date")}</label>

										<DatePicker
											value={dayjs(
												formateDateForPicker(new Date(startDate)).sql
											)}
											className="picker"
											onChange={(date: any) => {
												const newDate = date.$d;
												let time = startDate.split(" ");
												time = time[time.length - 1];
												const newDates = formateTimeForPicker(newDate);
												let newDateOnly = newDates.sql.split(" ");
												const newDateStart = `${newDateOnly[0]} ${time}`;
												setStartDate(newDateStart);
												setHasChanged(true);
											}}
										/>
									</LocalizationProvider>
								</div>
								<div className="col">
									<LocalizationProvider
										dateAdapter={AdapterDayjs}
										adapterLocale="fr"
									>
										<label>{t("TASK.meeting_hour")}</label>
										<TimePicker
											ampm={false}
											className="picker"
											value={dayjs(startDate)}
											onChange={(date: any) => {
												const newDate = date.$d;

												const newDates = formateTimeForPicker(newDate);
												setStartDate(newDates.sql);
												setEndDate(newDates.sql);
												setHasChanged(true);
											}}
										/>
									</LocalizationProvider>
								</div>
							</div>
						) : (
							<div className="row">
								<div className="col">
									<LocalizationProvider
										dateAdapter={AdapterDayjs}
										adapterLocale="fr"
									>
										<label>{t("TASK.start_date")}</label>
										<DatePicker
											value={dayjs(
												formateDateForPicker(new Date(startDate)).sql
											)}
											className="picker"
											onChange={(date: any) => {
												const newDate = date.$d;
												const newDates = formateDateForPicker(newDate);
												setStartDate(newDates.sql);
												setHasChanged(true);
											}}
										/>
									</LocalizationProvider>
								</div>
								<div className="col">
									<LocalizationProvider
										dateAdapter={AdapterDayjs}
										adapterLocale="fr"
									>
										<label>{t("TASK.end_date")}</label>
										<DatePicker
											value={dayjs(formateDateForPicker(new Date(endDate)).sql)}
											className="picker"
											onChange={(date: any) => {
												const newDate = date.$d;
												const newDates = formateDateForPicker(newDate);
												setEndDate(newDates.sql);
												setHasChanged(true);
											}}
										/>
									</LocalizationProvider>
								</div>
							</div>
						)}

						<Select
							classNamePrefix="react-select"
							id="select_status"
							className={"select_element "}
							options={status}
							isClearable={false}
							value={selectedStatus}
							onChange={(element: any) => {
								const newEvent = structuredClone(currentEvent);
								newEvent.attr.date_status = element.value;

								onEdit(newEvent);
								setSelectedStatus(element);
							}}
							theme={(theme) => ({
								...theme,
								borderRadius: 0,
								colors: {
									...theme.colors,
									primary25: "#e238324f",
									primary50: "#e2383287",
									primary: COLORS[0],
								},
							})}
						/>
					</>
				) : (
					<>
						<div className={"date"}>
							{event.attr.is_meeting ? (
								<>
									{eventStart && eventEnd && (
										<div className="meeting">
											<EventIcon />

											{eventStart.toLocaleDateString("fr-fr", {
												month: "numeric",
												day: "numeric",
											})}
											<AccessTimeIcon />
											<span>{formatTime(eventStart)}</span>
										</div>
									)}
								</>
							) : (
								<>
									{eventStart && eventEnd && (
										<>
											<span>du</span>{" "}
											{eventStart.toLocaleDateString("fr-fr", {
												month: "numeric",
												day: "numeric",
											})}{" "}
											<span>au</span>{" "}
											{eventEnd.toLocaleDateString("fr-fr", {
												year: "numeric",
												month: "numeric",
												day: "numeric",
											})}
										</>
									)}
								</>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default DateEvent;
