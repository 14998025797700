import { defaultReturnObject, ApiReturnDatas } from "Types/Api";

import userApiObject from "Api/Models/User";
import planApiObject from "Api/Models/Plan";

const userApi = new userApiObject();
const planApi = new planApiObject();


export const getUser = () => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.checkToken().then(
			(result: any) => {
				if (!result?.id) {
					window.location.href = "/login";
					returnDatas.message = "token_invalid";
					reject(returnDatas);
				} else {
					returnDatas.status = "success";
					returnDatas.data = result;
					returnDatas.message = "";
					resolve(returnDatas);
				}
			},
			(error) => {
				window.location.href = "/login";
				reject(error);
			}
		);
	});
};

export const putUser = (userToSave: any) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	const userClone = structuredClone(userToSave);
	userClone?.logged && delete userClone.logged;
	//userClone?.settings && delete userClone.settings;

	return new Promise((resolve, reject) => {
		userApi.put(userClone).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				resolve(returnDatas);
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const deleteUser = (idUser: number) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.delete(idUser).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				resolve(returnDatas);
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const postPassword = (password: any) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.postPassword(password).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				returnDatas.data = result;
				resolve(returnDatas);
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const postLogin = (login: string, password: string) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.postLogin(login, password).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.data = result;
				returnDatas.message = "";
				resolve(returnDatas);
			},
			(error) => {
				console.log(error);
				reject(error);
			}
		);
	});
};

export const postRegister = (
	email: string,
	password: string,
	id_country: string
) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.postRegister(email, password, id_country).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.data = result;
				returnDatas.message = "";
				resolve(returnDatas);
			},
			(error) => {
				console.log(error);
				reject(error);
			}
		);
	});
};

export const postCredentials = (provider: string, credentials: string) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		userApi.postCredentials(provider, credentials).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.data = result;
				returnDatas.message = "";
				resolve(returnDatas);
			},
			(error: any) => {
				console.log(error);
				reject(error);
			}
		);
	});
};

export const postSettings = (settings: any) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {

		userApi.postSettings(settings).then(
			(result: any) => {
				resolve(result);
			},
			(error: any) => {
				console.log(error);
				reject(error);
			}
		);
	});
};


export const cancelPlan = () => {

	return new Promise((resolve, reject) => {
		planApi.postCancel().then(
			(result: any) => {
				resolve(result);
			},
			(error: any) => {
				reject(error);
			}
		);
	});
};