import { defaultReturnObject, ApiReturnDatas } from "Types/Api";

/** Models */
import contractorApiObject from "Api/Models/Contractor";
const contractorApi = new contractorApiObject();

/** Actions */
export const search = (search: string) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;
	return new Promise((resolve, reject) => {
		contractorApi.getList(search).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				returnDatas.data = result;
				resolve(returnDatas);
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const post = (datas: any) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;
	return new Promise((resolve, reject) => {
		contractorApi.post(datas).then(
			(result: any) => {
				if (result.id) {
					returnDatas.status = "success";
					returnDatas.message = "";
					returnDatas.data = result;
					resolve(returnDatas);
				}
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};
