import React from "react";
import {useTranslation} from "react-i18next";

import Compressor from "compressorjs";

import FilePresentIcon from "@mui/icons-material/FilePresent";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import VideoFileIcon from "@mui/icons-material/VideoFile";

/** Types */

import CircularProgress from "@mui/material/CircularProgress";

const filesUpload: any = {
	file: ".xls,.xlsx,.csv,.doc,.docx,.page,.pdf, .txt, .ppt, .pptx",
	picture: "image/png, image/gif, image/jpeg,image/HEIC, image/*",
	video: "video/mp4,video/x-m4v,video/*",
	sound: ".mp3,.mp4,.flac,.aac,.wav,.flv,.ogg,.webm, .m4a",
};

const DropUploader = (props: {
	callback: Function;
	type: string;
	canClearFile: boolean;
}) => {
	const {callback, type} = props;
	const {t} = useTranslation();

	const inputRef: any = React.useRef(null);

	const [dragActive, setDragActive] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const [rawFiles, setRawFiles] = React.useState<any[]>([]);
	const [processedFiles, setProcessedFiles] = React.useState<any[]>([]);

	// handle drag events
	const handleDrag = function (e: any) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true);
		} else if (e.type === "dragleave") {
			setDragActive(false);
		}
	};

	// triggers when file is dropped
	const handleDrop = async function (e: any) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			// at least one file has been dropped so do something
			handleFiles(e.dataTransfer.files);
		}
	};

	const handleFiles = async (files: any) => {
		const newFiles: any = [];
		setRawFiles(files);

		Object.values(files).forEach((file: any, index: number) => {
			if (type === "picture") {
				new Compressor(file, {
					quality: 0.7,
					success(result) {
						processFile(result).then((processedFile: any) => {
							const files = [...newFiles];
							files.push(processedFile);
							newFiles.push(processedFile);
							setProcessedFiles(files);
						});
					},
					error(err) {
						console.log("error", err);
					},
				});
			} else {
				processFile(file).then((processedFile: any) => {
					const files = [...newFiles];
					newFiles.push(processedFile);
					files.push(processedFile);
					setProcessedFiles(files);
				});
			}
		});
	};
	// triggers when file is selected with click
	const handleChange = function (e: any) {
		e.preventDefault();
		if (e.target.files && e.target.files[0]) {
			// at least one file has been selected so do something
			handleFiles(e.target.files);
		}
	};

	// triggers the input when the button is clicked
	const onButtonClick = () => {
		inputRef?.current.click();
	};

	const processFile = (fileToConvert: any) => {
		return new Promise((resolve, reject) => {
			const newFileEvent = {
				name: fileToConvert.name,
				size: fileToConvert.size,
				uri: fileToConvert.name,
				type: fileToConvert.type,
				base64: "",
				path: "",
			};
			const reader = new FileReader();
			reader.readAsDataURL(fileToConvert);

			reader.onload = function () {
				let base64 = reader?.result?.toString() || "";
				base64 = base64.split("base64,")[1];
				newFileEvent.base64 = base64;

				if (type === "picture") {
					const img = new Image();
					let formattedBase64 = reader.result as string;
					img.src = formattedBase64;
					img.onload = function () {
						// image default size
						let MAX_WIDTH = 1200;
						let MAX_HEIGHT = 1200;

						// max quality
						MAX_WIDTH = 5000;
						MAX_HEIGHT = 5000;

						let width = img.width;
						let height = img.height;
						if (width > height) {
							if (width > MAX_WIDTH) {
								height *= MAX_WIDTH / width;
								width = MAX_WIDTH;
							}
						} else {
							if (height > MAX_HEIGHT) {
								width *= MAX_HEIGHT / height;
								height = MAX_HEIGHT;
							}
						}
						
						const canvas = document.createElement("canvas");
						canvas.width = width;
						canvas.height = height;
						const ctx = canvas.getContext("2d");
						ctx?.drawImage(img, 0, 0, width, height);

						const dataUrl = canvas.toDataURL("image/jpeg");
						newFileEvent.base64 = dataUrl.split("base64,")[1];
					};
				}

				newFileEvent.path = URL.createObjectURL(fileToConvert);
				resolve(newFileEvent);
			};
			reader.onerror = function (error) {
				console.log("Error: ", error);
				reject(error);
			};
		});
	};

	const clear = () => {
		setProcessedFiles([]);
		setRawFiles([]);
		setLoading(false);
		inputRef.current.value = "";
	};

	React.useEffect(() => {
		if (processedFiles.length === rawFiles.length) {
			setLoading(false);
			callback(processedFiles);
		} else {
			setLoading(true);
		}
	}, [processedFiles, rawFiles, callback]);

	React.useEffect(() => {
		if (props.canClearFile) {
			clear();
		}
	}, [props.canClearFile]);

	return (
		<form
			id="form-file-upload"
			onDragEnter={handleDrag}
			onSubmit={(e) => e.preventDefault()}
		>
			<input
				ref={inputRef}
				type="file"
				id="input-file-upload"
				multiple={true}
				onChange={handleChange}
				accept={filesUpload[type]}
			/>
			<label
				id="label-file-upload"
				htmlFor="input-file-upload"
				className={dragActive ? "drag-active" : ""}
			>
				{loading ? (
					<CircularProgress />
				) : (
					<div>
						{rawFiles.length > 0 ? (
							<div
								className={
									type === "picture"
										? "preview_container"
										: "preview_container_file"
								}
							>
								{Object.values(rawFiles).map((file: any, index: number) => {
									const fileUrl = URL.createObjectURL(file);

									if (type === "picture") {
										return (
											<div key={index} className="preview">
												<img src={fileUrl} alt={file.name} />
											</div>
										);
									} else {
										return (
											<div key={index} className="file_name">
												{type === "sound" && <AudioFileIcon />}
												{type === "file" && <FilePresentIcon />}
												{type === "video" && <VideoFileIcon />}
												<span>{file.name}</span>
											</div>
										);
									}
								})}
							</div>
						) : (
							<div className="message">
								<p>Glissez et déposez vos fichiers ici ou</p>
								<button className="upload-button" onClick={onButtonClick}>
									Envoyer un fichier
								</button>
							</div>
						)}
					</div>
				)}
			</label>
			{dragActive && (
				<div
					id="drag-file-element"
					onDragEnter={handleDrag}
					onDragLeave={handleDrag}
					onDragOver={handleDrag}
					onDrop={handleDrop}
				></div>
			)}
		</form>
	);
};

export default DropUploader;
