/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Logo from "Assets/Images/logo/logo.png";
import Button from "@mui/material/Button";

import { postStatus } from "Api/Controllers/EventController";
import { convertStringToDate } from "Utils/DateFormatter";

/** ICONS */
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";

function ValidationDateStatus() {
	const { hash, status } = useParams();

	const { t } = useTranslation();

	const [event, setEvent] = React.useState({} as any);

	React.useEffect(() => {
		hash &&
			status &&
			postStatus(hash, status, true).then((res: any) => {
				if (res && res.status === "success") {
					setEvent(res?.data);
				}
			});
	}, [hash, status]);


	return (
		<div className="ValidationDateStatus">
			<div className="content">
				<div className="box">
					<div className={"messageValidation " + status}>
						{status === "cancelled" ? (
							<>
								<EventBusyOutlinedIcon />
								<span>{t("EVENT.LABELS.rdv_cancelled")}</span>
							</>
						) : (
							<>
								<EventAvailableOutlinedIcon />
								<span>{t("EVENT.LABELS.rdv_confirmed")}</span>
							</>
						)}
					</div>

					<div className="event">
						<span className="title">{t("EVENT.LABELS.rdv_information_title")}</span>
						<div className="event__date">
							<div>
								<CalendarTodayIcon className="icon" />
								<strong>{convertStringToDate(event.event_start, false)}</strong>
							</div>
							<div>
								<AccessTimeIcon className="icon" />
								<strong>{convertStringToDate(event.event_start, true)}</strong>
							</div>
							<div>
								<PermIdentityIcon className="icon" />
								<strong>
									{event?.user?.firstname} {event?.user?.lastname}
								</strong>
							</div>
							<div>
								<PlaceOutlinedIcon className="icon" />
								<strong>
									{event?.project?.address ? (
										<>
											{event?.project?.address}
											{", "} {event?.project?.zipcode} {event?.project?.city}
										</>
									) : (
										t("GLOBAL.ACTIONS.to_define")
									)}
								</strong>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="about">
				<img src={Logo} alt="Sayto" />

				<span>
					{" "}
					{event?.user?.firstname} {t("GLOBAL.MARKETING.use_sayto")}
				</span>
				<span>{t("GLOBAL.MARKETING.discover_sayto_desc")}</span>
				<Button
        className="goTo"
					variant={"contained"}
					onClick={() => {
						window.location.href = "https://www.saytoapp.com";
					}}
				>
					{t("GLOBAL.MARKETING.discover_sayto")}
				</Button>
			</div>
		</div>
	);
}

export default ValidationDateStatus;
