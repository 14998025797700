/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SaveIcon from "@mui/icons-material/Save";
import Confirm from "Components/Parts/Confirm";

import EditProject from "Components/Pages/Project/EditProject";

import Editor from "Components/Parts/Editor";
import {convertStringToDate} from "Utils/DateFormatter";

import config from "Config";

import {useAppSelector, useAppDispatch} from "Redux/hooks";
import {
	getProject,
	setProject,
	getCoordinates,
	deleteProject,
} from "Redux/Reducers/Project/ProjectSlice";

import Map from "Components/Parts/Map";

function Description() {
	const {t} = useTranslation();
	const project = useAppSelector(getProject);
	const {datas} = project;
	const dispatch = useAppDispatch();
	const coord = useAppSelector(getCoordinates);
	const navigate = useNavigate();

	const [confirmOpen, setConfirmOpen] = useState(false);
	const [editing, setEditing] = useState(false);
	const [tmpProject, setTmpProject] = useState(project);
	const [copied, setCopied] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);

	const handleDescription = (description = "") => {
		return {__html: description};
	};

	const controlStateIcs = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newProject = structuredClone(project);
		newProject.datas.sharing_ics = event.target.checked;
		dispatch(setProject(newProject));
	};

	const clipboardCopyUrl = (url: string) => {
		setCopied(true);
		navigator.clipboard.writeText(url);
		setTimeout(() => {
			setCopied(false);
		}, 3000);
	};

	const placeholder = {
		delete_project: t("PROJECT.ACTIONS.delete"),
	};

	return (
		<div className="tabContent">
			{project.id > 0 && (
				<>
					<EditProject
						project={project}
						open={openEdit}
						onClose={() => {
							setOpenEdit(false);
						}}
						onSave={(projectEdit: any) => {
							dispatch(setProject(projectEdit));
						}}
					/>
					<Confirm
						status={confirmOpen}
						onClose={() => {
							setConfirmOpen(false);
						}}
						onConfirm={() => {
							dispatch(deleteProject({}));
							navigate("/dashboard/");
						}}
						title={placeholder.delete_project}
						content={
							'Vous-êtes sur le point de supprimer le projet "' +
							project.datas.name +
							'". Voulez-vous continuer ?'
						}
					/>
				</>
			)}
			<div className="row descriptionProject">
				<div className="col">
					<div className="aboutProject">
						{datas.start_date && (
							<div className="infoContent">
								<span className="label">Démarré le</span>
								<strong>
									{convertStringToDate(
										datas.start_date ? datas.start_date : ""
									)}
								</strong>
							</div>
						)}

						<div className="address infoContent">
							<div className="title">
								<span className="label">Adresse du projet</span>
								<button onClick={() => setOpenEdit(true)}>
									<EditRoundedIcon />
								</button>
							</div>

							{datas.address === "" ? (
								<p>Aucune adresse renseignée</p>
							) : (
								<p>
									{datas.address} - {datas.zip} {datas.city} ,{" "}
									{datas.id_country}
								</p>
							)}
						</div>
						{coord.lat && coord.lng && <Map coord={coord ? coord : false} />}
					</div>
				</div>
				<div className="col">
					<div className="aboutProject">
						<div className="title">
							<span>À propos du projet</span>
							{editing ? (
								<button
									onClick={() => {
										setEditing(false);
										dispatch(setProject(tmpProject));
									}}
								>
									<SaveIcon />
								</button>
							) : (
								<button onClick={() => setEditing(true)}>
									<EditRoundedIcon />
								</button>
							)}
						</div>

						{editing ? (
							<Editor
								title="Description"
								content={datas.description}
								onEdit={(newDescription: string) => {
									const newProject = structuredClone(project);
									newProject.datas.description = newDescription;
									setTmpProject(newProject);
								}}
							/>
						) : (
							<>
								{datas.description !== "" ? (
									<div
										className="desc"
										dangerouslySetInnerHTML={handleDescription(
											datas.description
										)}
									></div>
								) : (
									<div className="desc">
										<span>Description du projet</span>
									</div>
								)}
							</>
						)}
					</div>

					<div className="aboutProject">
						<div className="title">
							<span>{t("PROJECT.LABELS.delete_request_title")}</span>
						</div>
						<div className="actions">
							<span>{t("PROJECT.LABELS.delete_request_content")}</span>

							<Button onClick={() => setConfirmOpen(true)} color="error">
								<DeleteForeverRoundedIcon /> {t("GLOBAL.ACTIONS.delete")}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Description;
