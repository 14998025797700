/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";

import Editor from "../../../Parts/Editor";
import defaultUser from "Assets/Images/clear/default/comment.png";

import { useTranslation } from "react-i18next";

import { getHistory, postComment } from "Api/Controllers/EventController";
import { hasSomeParentTheClass } from "Utils/Globals";

/** Redux */

import { useAppDispatch, useAppSelector } from "Redux/hooks";

import { getCurrentEvent } from "Redux/Reducers/Project/EventsSlice";

import { getUser } from "Redux/Reducers/User/UserSlice";

/** Icons */
import SendRoundedIcon from "@mui/icons-material/SendRounded";

function Comments() {
	const dispatch = useAppDispatch();
	const event = useAppSelector(getCurrentEvent);
	const user = useAppSelector(getUser);

	const [comments, setComments] = useState([]);
	const [editorOpen, setEditorOpen] = useState(false);
	const [clearContent, setClearContent] = useState(false);

	const [newComment, setNewComment] = useState("");

	const { t } = useTranslation();
	const placeholderComment = t("GLOBAL.PLACEHOLDERS.new_comment");

	const addComment = () => {
		if (newComment !== "") {
			postComment({
				comment: newComment,
				id_event: event.id,
				id_user: user.id ? user.id : 1,
			}).then(
				(result: any) => {
					if (result) {
						setNewComment("");
						refreshComments();
						setClearContent(true);
						setEditorOpen(false);
					}
				},
				(error) => {
					console.log(error);
				}
			);
		}
	};

	const handleHtml = (content = "") => {
		return { __html: content };
	};

	const refreshComments = () => {
		setClearContent(false);
		if (event.id) {
			getHistory(event.id).then(
				(result: any) => {
					if (result) {
						setComments(result.data);
					}
				},
				(error) => {
					console.log(error);
				}
			);
		}
	};
	useEffect(() => {
		setClearContent(false);
		refreshComments();
	}, [event.id]);

	useEffect(() => {
		window.addEventListener("click", (e) => {
			if (
				!hasSomeParentTheClass(e.target, [
					"newCommentContainer",
					"triggerOpenComments",
				])
			) {
				setEditorOpen(false);
			}
		});
	});

	return (
		<div
			className={
				comments && comments.length > 0 ? "comments" : "comments empty"
			}
		>
			<div className={"listComments"}>
				{comments && comments.length > 0 ? (
					<>
						<strong className="title">Commentaires</strong>
						{comments.map((comment: any, index: number) => {
							return (
								<div key={index} className="comment">
									<div className={"infos"}>
										{comment.picture === "" ||
										comment.picture === null ||
										!comment.picture ? (
											<img
												src={defaultUser}
												alt={comment.firstname + " " + comment.name}
											/>
										) : (
											<img
												src={comment.picture}
												alt={comment.firstname + " " + comment.name}
											/>
										)}
										<span>
											{comment.firstname ? comment.firstname : "Sayto"}
										</span>
										<span>{comment.firstname ? comment.name : ""}</span>
									</div>
									<div className={"content"}>
										<span className={"date"}>{comment.creation_date}</span>
										<p
											dangerouslySetInnerHTML={handleHtml(comment.comment)}
										></p>
									</div>
								</div>
							);
						})}
					</>
				) : (
					<>
						<span className={"empty"}>{t("COMMENT.empty")}</span>

						{/*}
						<div
							className="triggerOpenComments"
							onClick={() => {
								setClearContent(false);
								setEditorOpen(true);
							}}
						>
							<span>{editorOpen ? "true" : "false"}</span>
							Ajouter un commentaire
						</div>
						*/}
					</>
				)}
			</div>

			<div
				className={
					editorOpen ? "newCommentContainer open" : "newCommentContainer"
				}
				onClick={() => {
					setClearContent(false);
					setEditorOpen(true);
				}}
			>
				<Editor
					placeholder={placeholderComment}
					content={newComment}
					clear={clearContent}
					onEdit={(value: string) => {
						setClearContent(false);
						setNewComment(value);
					}}
				/>

				{editorOpen && (
					<button
						className="addComment"
						onClick={() => {
							addComment();
							setEditorOpen(false);
						}}
					>
						{t("GLOBAL.ACTIONS.send")} <SendRoundedIcon />
					</button>
				)}
			</div>
		</div>
	);
}

export default Comments;
