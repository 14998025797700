import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import COLORS from "Utils/colors";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import Textarea from "@mui/joy/Textarea";

/** Icons */
import SendIcon from "@mui/icons-material/Send";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

/** APi */

import { postConfirmDate } from "Api/Controllers/EventController";
/** Redux */

import { useAppSelector, useAppDispatch } from "Redux/hooks";
import {
	getCurrentEvent,
	setCurrentEvent,
	saveEvent,
} from "Redux/Reducers/Project/EventsSlice";
import { setError } from "Redux/Reducers/App/AppSlice";

const ConfirmDate = (props: {
	open: boolean;
	onClose: Function;
	onReject?: Function;
}) => {
	const { open, onClose, onReject } = props;

	const event = useAppSelector(getCurrentEvent);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [message, setMessage] = useState("");

	/** Functions */

	const handleClose = () => {
		setSuccess(false);
		setMessage("");
		onClose();
		setLoading(false);
	};

	const handleConfirm = () => {
		setLoading(true);
		postConfirmDate(event.id, message).then(
			(result: any) => {
				if (result.status === "success") {
					setSuccess(true);
					setLoading(false);
          handleClose();
					const currentEvent = structuredClone(event);
					currentEvent.attr.date_status = "pending";
					dispatch(setCurrentEvent(currentEvent));
					dispatch(saveEvent({}));
				} else {
					setLoading(false);
				}
			},
			(error: any) => {
				setLoading(false);
				dispatch(setError(error));
			}
		);
	};

	const handleReject = () => {
		if (onReject) {
			onReject();
		}

		onClose();
	};

	/** Hooks */

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{t("EVENT.ACTIONS.confirm_date")}
			</DialogTitle>
			<DialogContent className="share_content">
				<DialogContentText id="alert-dialog-description">
					{success ? (
						<p className="success">
							<ThumbUpIcon />
							<span>L'évènement a été envoyé!</span>
						</p>
					) : (
						<>
							<textarea
								value={message}
								onChange={(e) => {
									setMessage(e.target.value);
								}}
								placeholder="Ajouter un message"
							></textarea>

							<div>
								<span>
									Ce message sera envoyé aux intervenants liés à cet évènement.
								</span>
							</div>
						</>
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleReject}>{t("GLOBAL.ACTIONS.cancel")}</Button>

				<LoadingButton
					size="small"
					onClick={() => {
						handleConfirm();
					}}
					endIcon={<SendIcon />}
					loading={loading}
					loadingPosition="end"
					variant="contained"
					disabled={!event.id}
				>
					<span>{t("GLOBAL.ACTIONS.send")}</span>
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmDate;
