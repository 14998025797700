import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';

const UpgradeDialog = (props: {
  open: boolean;
  onClose: Function;
}) => {
  const { open, onClose } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  /** Functions */
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="upgradeDialog"
    >
      <DialogTitle id="alert-dialog-title">
      <AutoAwesomeTwoToneIcon /> {t("GLOBAL.MARKETING.upgrade_dialog_title")} <AutoAwesomeTwoToneIcon />
      </DialogTitle>
      <DialogContent className="share_content">
        <DialogContentText id="alert-dialog-description">
          
            <p><strong>{t("GLOBAL.MARKETING.upgrade_dialog_desc")}</strong></p>

            <p>{t("GLOBAL.MARKETING.upgrade_dialog_desc2")}</p>

            <p>
            <div
                className="need_upgrade"
                onClick={() => {
                    navigate("/account/subscription");
                }}
                >
                    
                    <span>Upgrade</span>
                </div>
            </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("GLOBAL.ACTIONS.close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpgradeDialog;
