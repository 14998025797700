import Config from "Config";
import { Loader } from "@googlemaps/js-api-loader";

const API_KEY = Config.googleApi;

export const getPosition = async (address: string) => {

    if (!address) {
        return Promise.reject(new Error("Provided address is invalid"));
    }
    const endpoint = "https://maps.googleapis.com/maps/api/geocode/json";

    const response = await fetch(`${endpoint}?address=${encodeURIComponent(address)}&key=${API_KEY}`);
    const data = await response.json();

    if (data.status === "OK") {
        return {
            lat: data.results[0].geometry.location.lat,
            lng: data.results[0].geometry.location.lng
        }

    } else {
        return { lat: false, lng: false }
    };


}

export const findByPlace = async (search: string) => {

    if (!search) {
        return Promise.reject(new Error("Provided search is invalid"));
    }

    const endpoint = "https://maps.googleapis.com/maps/api/place/findplacefromtext/json";
    const response = await fetch(`${endpoint}?inputtype=textquery&fields=Basic&input=${encodeURIComponent(search)}&key=${API_KEY}`);
    const data = await response.json();

    if (data.status === "OK") {
        return {
            lat: data.results[0].geometry.location.lat,
            lng: data.results[0].geometry.location.lng
        }

    } else {
        return { lat: false, lng: false }
    };


}

export const getGoogleMapsApiClient = async () => {

    type PlacesServiceStatus =
        | "INVALID_REQUEST"
        | "NOT_FOUND"
        | "OK"
        | "OVER_QUERY_LIMIT"
        | "REQUEST_DENIED"
        | "UNKNOWN_ERROR"
        | "ZERO_RESULTS";

    type Prediction = {
        description: string;
        place_id: string;
    };

    type PlaceDetail = {
        // fields here depend on the fields param passed to getDetails
        formatted_address?: string;
        geometry?: {
            location: { lat: () => number; lng: () => number };
        };
        name?: string;
        place_id?: string;
    };

    type GoogleApiClient = {
        maps: {
            places: {
                AutocompleteSessionToken: { new(): string };
                AutocompleteService: {
                    new(): {
                        getPlacePredictions: (
                            params: { input: string; sessionToken: string | undefined },
                            callback: (
                                predictions: Prediction[],
                                status: PlacesServiceStatus
                            ) => void
                        ) => void;
                    };
                };
                PlacesService: {
                    new(attributionNode: HTMLElement): {
                        getDetails: (
                            params: {
                                placeId: string;
                                fields?: string[];
                                sessionToken: string | undefined;
                            },
                            callback: (place: PlaceDetail, status: PlacesServiceStatus) => void
                        ) => void;
                    };
                };
                PlacesServiceStatus: {
                    [key in PlacesServiceStatus]: PlacesServiceStatus;
                };
            };
            [key: string]: any;
        };
    };

    let googleApiClient: GoogleApiClient | undefined;
    const loader = new Loader({
        apiKey: API_KEY,
        version: "weekly",
        libraries: ["places"],
    });
    googleApiClient = (await loader.load()) as unknown as GoogleApiClient;
    return googleApiClient;

};

export const getSessionToken = async () => {
    const google: any = await getGoogleMapsApiClient();
    return new google.maps.places.AutocompleteSessionToken();

};

export const getSuggestions = async (search: string, sessionToken: any) => {
    const google: any = await getGoogleMapsApiClient();

    return new Promise((resolve, reject) => {
        // @see https://developers.google.com/maps/documentation/javascript/place-autocomplete
        new google.maps.places.AutocompleteService().getPlacePredictions(
            {
                input: search,
                sessionToken: sessionToken
            },
            (predictions: any, status: any) => {
                if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                    reject([]);
                }
                if (
                    status !== google.maps.places.PlacesServiceStatus.OK ||
                    !predictions
                ) {
                    reject([]);
                }
                resolve(predictions);
            }
        );

    });

};


export const getPlaceDetails = async (placeId: string, sessionToken: any) => {
    const google: any = await getGoogleMapsApiClient();

    return new Promise((resolve, reject) => {
        // @see https://developers.google.com/maps/documentation/javascript/places
        new google.maps.places.PlacesService(
            document.createElement("div")!
        ).getDetails(
            {
                placeId: placeId,
                fields: [
                    // @see https://developers.google.com/maps/documentation/javascript/place-data-fields
                    "formatted_address",
                    "address_components",
                    "name",
                    "place_id",
                    "geometry.location",
                ],
                sessionToken,
            },
            (place: any, status: any) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    resolve(place);
                } else {
                    reject(new Error("Place not found"));
                }
            }
        );

    });

};

