/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Confirm from "Components/Parts/Confirm";

import Details from "../Events/Details";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

import Filters from "Components/Pages/Project/Views/ActivitiesFilters";
import AddEvent from "Components/Pages/Project/Events/AddEvent";

import Empty from "Components/Parts/Empty";
import { convertStringToDatetime } from "Utils/DateFormatter";
import { formatFileSize } from "Utils/Locale";

/** API */
import {
	getEvents,
	deleteFileByName,
	getFilesByTypes,
	getEvent,
} from "Api/Controllers/EventController";

/** Redux */
import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { getProject } from "Redux/Reducers/Project/ProjectSlice";
import {
	getFilters,
	getAmounts,
	setCurrentEvent,
	setIsOpen,
	clearEvents,
	getModalAction,
	getCurrentEvent,
	setModalAction,
	loadEvents,
} from "Redux/Reducers/Project/EventsSlice";

const limitFiles = 100;

function Files() {
	const { t } = useTranslation();
	const project = useAppSelector(getProject);
	const amounts = useAppSelector(getAmounts);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(true);
	const filters = useAppSelector(getFilters);
	const modalAction = useAppSelector(getModalAction);
	const currentEvent = useAppSelector(getCurrentEvent);

	const [ActiveFilters, setActiveFilters] = useState({} as any);
	const [events, setEvents] = useState([] as any);

	const [files, setFiles] = useState([] as any);

	const [fileToDelete, setFileToDelete] = useState({} as any);

	const [showConfirmDelete, setShowConfirmDelete] = useState(false);

	const handleDeleteFile = (file: any) => {
		deleteFileByName(file.event.id, file.name).then((result: any) => {
			dispatch(loadEvents({}));
			reloadEvents({ ...filters });
		});
	};

	/** Functions */

	const handleScrollList = (e: any) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;
		const newFilters = structuredClone(filters);

		if (scrollTop <= 5) {
			newFilters.page = newFilters.page ? newFilters.page - 1 : 1;
			//dispatch(setFilters(newFilters));
			increaseEvents(false);
		}
		if (scrollTop + clientHeight >= scrollHeight - 10) {
			// refresh events
			newFilters.page = newFilters.page ? newFilters.page + 1 : 2;
			increaseEvents(true);
		}
	};

	const reloadEvents = (currentFilters: any) => {
		setLoading(true);
		const activeFilters = structuredClone(currentFilters);
		activeFilters.id_project = project.id;
		activeFilters.page = 1;
		activeFilters.limit = limitFiles;
		//dispatch(setFilters(activeFilters));

		if (
			(activeFilters.search && activeFilters.search !== "") ||
			activeFilters.event_start ||
			activeFilters.event_end ||
			activeFilters.event_type ||
			activeFilters?.attr?.tags
		) {
			activeFilters.limit = limitFiles * limitFiles;
		}

		if (project.id) {
			getFilesByTypes(project.id, ["file"], activeFilters).then(
				(result: any) => {
					const newEvents = Object.values(result);
					setFiles(newEvents);

					setLoading(false);
				}
			);
		}
	};

	const increaseEvents = (add: boolean) => {
		const activeFilters = structuredClone(filters);
		if (add) {
			activeFilters.page = activeFilters.page ? activeFilters.page + 1 : 2;
		} else {
			activeFilters.page = activeFilters.page ? activeFilters.page - 1 : 1;
		}
		activeFilters.id_project = project.id;
		activeFilters.limit = limitFiles;
		if (
			activeFilters.page > 1 &&
			(activeFilters.page - 1) * limitFiles <= amounts.events
		) {
			//dispatch(setFilters(activeFilters));
			setLoading(true);
			getEvents(activeFilters).then(
				(returnDatas: any) => {
					if (returnDatas?.status === "success") {
						const newEvents = [...events, ...returnDatas?.data.events];
						setEvents(newEvents);
						setLoading(false);
					}
				},
				(error: any) => {
					console.log("error", error);
				}
			);
		}
	};

	/** Hooks */
	useEffect(() => {
		dispatch(clearEvents({}));
		setLoading(true);
	}, [dispatch, project.id]);

	useEffect(() => {
		setLoading(false);
	}, [events]);

	useEffect(() => {
		setLoading(true);
		//dispatch(setFilters(ActiveFilters));
		reloadEvents(ActiveFilters);
	}, [project.id, ActiveFilters]);

	// listen to event modification
	useEffect(() => {
		if (modalAction && modalAction !== "waiting") {
			modalAction === "add" && reloadEvents(filters);

			const updatingEvents = { ...events };

			const index = Object.values(updatingEvents).findIndex(
				(event: any) => event.id === currentEvent.id
			);

			if (modalAction === "edit" && currentEvent.id) {
				updatingEvents[index] = currentEvent;
				const eventsList = Object.values(updatingEvents);
				setEvents(eventsList);
			}

			if (modalAction === "delete" && currentEvent.id) {
				delete updatingEvents[index];
				const eventsList = Object.values(updatingEvents);
				setEvents(eventsList);
				setTimeout(() => {
					dispatch(loadEvents({}));
				}, 300);
			}

			dispatch(setModalAction("waiting"));
		}
	}, [modalAction]);

	const placeholders = {
		delete_confirm: t("EVENT.FILE.LABELS.confirm_delete"),
		delete_content: t("EVENT.FILE.LABELS.delete_content"),
		delete_account: t("GLOBAL.ACTIONS.confirm"),
	};

	return (
		<div className="tabContent files">
			<Details />

			<div className="filters container">
				<Filters
					hideTypes
					filtersParams={ActiveFilters}
					onFilter={(filters: any) => {
						setActiveFilters(filters);
						//setFromAnotherPage(false);
					}}
				/>
			</div>
			<div className="filters title_actions">
				<h3 className="title with_spacer">{t("EVENT.LABELS.list_files")}</h3>
				<div className="spacer"></div>
				<AddEvent selected={["file"]} />
			</div>

			{loading ? (
				<Empty empty_label={t("EVENT.LABELS.loading_files")} />
			) : (
				<>
					{files.length === 0 ? (
						<span className="emptyProject">
							Ce projet n'a pas encore d'évènement.
						</span>
					) : (
						<div className={"listContainer"}>
							<div className="columns">
								<div className="item">
									<div className="size">
										<span>{t("LIST.title")}</span>
									</div>
									<div className="task">
										<span>{t("LIST.file")}</span>
									</div>
									<div className="due_date">
										<span>{t("LIST.date")}</span>
									</div>
									<div className="size">
										<span>{t("LIST.size")}</span>
									</div>
									<div className="actions">
										<span>{t("LIST.actions")}</span>
									</div>
								</div>
							</div>
							<div className="list" onScroll={handleScrollList}>
								{loading && (
									<div className="loading">
										<CircularProgress />
									</div>
								)}
								<Confirm
									status={showConfirmDelete}
									title={placeholders.delete_confirm}
									content={
										placeholders.delete_content + fileToDelete.name + " ?"
									}
									onConfirm={() => {
										handleDeleteFile(fileToDelete);
										setShowConfirmDelete(false);
									}}
									onClose={() => setShowConfirmDelete(false)}
									confirmButton={placeholders.delete_account}
								/>
								{files.map((file: any, index: number) => {
									const event = file.event;
									return (
										<div className="item file" key={index}>
											<div className="size">
												<strong
													className="open_details_action"
													onClick={() => {
														getEvent(event.id).then((result: any) => {
															if (result.data.id === event.id) {
																dispatch(setIsOpen(true));
																dispatch(setCurrentEvent(result.data));
															} else {
																dispatch(setIsOpen(true));
																dispatch(setCurrentEvent(event));
															}
														});
													}}
												>
													{event.title}
												</strong>
											</div>
											<div className="task">
												<a
													className="open_file_action"
													href={file.url}
													download={true}
													target="_blank"
													rel="noreferrer"
												>
													{file.name}
												</a>
											</div>
											<div className="due_date">
												<span>
													{convertStringToDatetime(event.event_start)}
												</span>
											</div>
											<div className="size">
												<span className="filesize">
													{formatFileSize(file.size)}
												</span>
											</div>
											<div className="actions">
												<a href={file.url} target="_blank" rel="noreferrer">
													<Tooltip title="Télécharger le fichier">
														<DownloadRoundedIcon />
													</Tooltip>
												</a>
												<span
													className={"deleteButton"}
													onClick={() => {
														setShowConfirmDelete(true);
														setFileToDelete(file);
													}}
												>
													<Tooltip title="Supprimer le fichier">
														<HighlightOffOutlinedIcon />
													</Tooltip>
												</span>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default Files;
