import { defaultReturnObject, ApiReturnDatas } from "Types/Api";
import planApiObject from "Api/Models/Plan";
import categoryApiObject from "Api/Models/Categories";

const categoryApi = new categoryApiObject();
const planApi = new planApiObject();

export const getProfessions = () => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;

	return new Promise((resolve, reject) => {
		categoryApi.getProfessions().then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.data = result;
				resolve(returnDatas);
			},
			(error) => {
				returnDatas.message = "request_failure";
				reject(returnDatas);
			}
		);
	});
};

export const postTips = (props: {
	id_project: number;
	id_profession: number;
	lang: string;
}) => {
	const returnDatas: ApiReturnDatas = defaultReturnObject;
	return new Promise((resolve, reject) => {
		categoryApi.postTips(props).then(
			(result: any) => {
				returnDatas.status = "success";
				returnDatas.message = "";
				resolve(returnDatas);
			},
			(error: any) => {
				reject(returnDatas);
			}
		);
	});
};

export const getPlans = () => {
	return new Promise((resolve, reject) => {
		planApi.getList().then(
			(result: any) => {
				resolve(result);
			},
			(error: any) => {
				reject(error);
			}
		);
	});
};

export const getTags = (id_project: number) => {
	return new Promise((resolve, reject) => {
		categoryApi.getTags(id_project).then(
			(result: any) => {
				resolve(result);
			},
			(error: any) => {
				reject(error);
			}
		);
	});
};
