import React from "react";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import SendSharpIcon from "@mui/icons-material/SendSharp";
import Tooltip from "@mui/material/Tooltip";

/** Components */
import ConfirmDate from "../Modals/ConfirmDate";
import CancelDate from "../Modals/CancelDate";

const HeaderDate = (props: {event: any}) => {
	const {event} = props;
	const t = useTranslation();

	const [confirmDateOpen, setConfirmDateOpen] = React.useState(false);
	const [confirmCancelDate, setConfirmCancelDate] = React.useState(false);
	const [isPassed, setIsPassed] = React.useState(false);

	const handleConfirmCancelDate = () => {
		setConfirmCancelDate(true);
	};

	React.useEffect(() => {
		const dateToCheck =
			event?.attr.is_meeting || event?.attr.isMeeting
				? event.event_start
				: event.event_end;

		const date = new Date(dateToCheck);
		const currentDate = new Date();
		if (date < currentDate) {
			setIsPassed(true);
		} else {
			setIsPassed(false);
		}
	}, [event]);

	return (
		<>
			<ConfirmDate
				open={confirmDateOpen}
				onClose={() => {
					setConfirmDateOpen(false);
				}}
			/>
			<CancelDate
				open={confirmCancelDate}
				onClose={() => {
					setConfirmCancelDate(false);
				}}
			/>
			<div className="date_actions">
				<div className="row">
					<div className="col">
						<div className="item">
							<div className="action">
								{!isPassed ? (
									<>
										{event.attr?.date_status === "confirmed" ? (
											<div className="status confirmed">
												<div className="label">
													<EventAvailableIcon />
													<span className="">Confirmé</span>
												</div>

												<Button
													variant="text"
													onClick={handleConfirmCancelDate}
													color="error"
													disableElevation
													style={{color: "#fff"}}
												>
													Annuler
												</Button>
											</div>
										) : event.attr?.date_status === "cancelled" ? (
											<div className="status cancelled">
												<div className="label">
													<EventBusyIcon />
													<span>Annulé</span>
												</div>
											</div>
										) : event.attr?.date_status === "pending" ? (
											<div className="status">
												<div className="label">
													<ForwardToInboxIcon />
													<span>Invitation envoyée</span>
												</div>
											</div>
										) : (
											<div className="status">
												<Tooltip
													title={
														"Envoyer la demande de confirmation à un intervenant"
													}
												>
													<Button
														variant="contained"
														onClick={() => setConfirmDateOpen(true)}
														color="success"
														style={{color: "#fff"}}
														disableElevation
														size="small"
													>
														<SendSharpIcon
															style={{marginRight: 5, fontSize: 16}}
														/>
														confirmation
													</Button>
												</Tooltip>
											</div>
										)}
									</>
								) : (
									<>
										<span>
											{event.attr?.isMeeting
												? "Ce rendez-vous est terminé"
												: "Cette phase est terminée"}
										</span>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default HeaderDate;
