/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { setItem } from "Utils/Storage";
import { availableLanguages } from "Utils/enums/Language";
import COLORS from "Utils/colors";

import Switch from "@mui/material/Switch";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

/** Modules  */
import AccountIcon from "Components/Parts/Account";

/** Redux */

import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
	getUser,
	setSettings,
	getSettings,
} from "Redux/Reducers/User/UserSlice";

import Logo from "Assets/Images/logo/logo.png";

/** Images */
const settingsIcon = require("Assets/Images/clear/navigation/settings_icon.png");

function Settings() {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const user = useAppSelector(getUser);

	const availableCurrency = [
		{ value: "euro", label: "Euro (€)" },
		{ value: "dollar", label: "Dollar ($)" },
		{ value: "chf", label: "Franc (fr.)" },
	];

	const availablePhotoQuality = [
		{ value: "low", label: t("SETTINGS.DESCRIPTION.photo_qualitylow") },
		{ value: "medium", label: t("SETTINGS.DESCRIPTION.photo_qualitymedium") },
		{ value: "high", label: t("SETTINGS.DESCRIPTION.photo_qualityhigh") },
	];

	const availableVideoQuality = [
		{ value: "low", label: t("SETTINGS.DESCRIPTION.video_qualitylow") },
		{ value: "medium", label: t("SETTINGS.DESCRIPTION.video_qualitymedium") },
		{ value: "high", label: t("SETTINGS.DESCRIPTION.video_qualityhigh") },
	];

	const [notifications, setNotifications] = React.useState({
		push_when_receive_answer: 1,
		mail_when_receive_answer: 1,
	});

	const [photoQuality, setPhotoQuality] = React.useState(
		availablePhotoQuality[1]

	);
	const [videoQuality, setVideoQuality] = React.useState(
		availableVideoQuality[1]

	);

	const [loading, setLoading] = React.useState(false);
	const [selectedLanguage, setSelectedLanguage] = React.useState(
		availableLanguages[0]
	);
	const [selectedCurrency, setSelectedCurrency] = React.useState(
		availableCurrency[0]
	);


	const saveLanguage = (language: string) => {
		setItem("language", language);
		const updatedLocale = { lang: language };
		dispatch(
			setSettings({ locale: { ...user.settings.locale, ...updatedLocale } })
		);
	};

	React.useEffect(() => {
		let currentLang = user?.settings?.language;
		if (!currentLang) currentLang = localStorage.getItem("language");

		if (currentLang) {
			const selectedLang = availableLanguages.filter(
				(item: any, index: number) => {
					return item.value === currentLang;
				}
			);
		}

	}, [user]);

	return (
		<div className="Page settings">
			<header>
				<div className="pageName">
					<Link to="/project">
						<img className="app_icon" src={Logo} alt="Sayto" />
					</Link>
					<div className="icon">
						<img src={settingsIcon} alt="Settings" />
					</div>
					<strong>{t("SETTINGS.title")}</strong>
				</div>

				<AccountIcon />
			</header>

			<div className="content">
				<div className="tabContent row">
					<div className="col">
						<Card className="settings_card">
							<CardHeader title="Paramètres régionaux" />

							<CardContent>
								<div className="row">
									<div className="col">
										<strong className="title">
											{t("SETTINGS.LABELS.select_language")}
										</strong>
										<Select
											classNamePrefix="react-select"
											id="select_language"
											options={availableLanguages}
											value={
												availableLanguages.find(availableLanguages => availableLanguages.value === user.settings?.locale?.lang)
											}
											onChange={(element: any) => {
												//setSelectedLanguage(element);
												saveLanguage(element.value);
											}}
											theme={(theme) => ({
												...theme,
												borderRadius: 0,
												colors: {
													...theme.colors,
													primary25: "#e238324f",
													primary50: "#e2383287",
													primary: COLORS[0],
												},
											})}
										/>

										<div className="separator"></div>

										<strong className="title">{t("SETTINGS.LABELS.currency")}</strong>
										<Select
											classNamePrefix="react-select"
											id="select_currency"
											options={availableCurrency}
											value={
												availableCurrency.find(availableCurrency => availableCurrency.value === user.settings?.locale?.currency)
											}
											onChange={(element: any) => {
												setSelectedCurrency(element.value);
												const updatedLocale = { currency: element.value };
												dispatch(
													setSettings({ locale: { ...user.settings.locale, ...updatedLocale } })
												);
											}}
											theme={(theme) => ({
												...theme,
												borderRadius: 0,
												colors: {
													...theme.colors,
													primary25: "#e238324f",
													primary50: "#e2383287",
													primary: COLORS[0],
												},
											})}
										/>
									</div>
								</div>
							</CardContent>
						</Card>
					</div>
					<div className="col">
						<Card className="settings_card">
							<CardHeader title="Notifications" />

							<CardContent>
								<FormGroup>
									<FormControlLabel
										control={
											<Switch
												checked={user?.settings?.notifications?.mail_when_receive_answer == 1}
												onChange={(element) => {
													setNotifications({
														...notifications,
														mail_when_receive_answer: element.target.checked ? 1 : 0,
													});
													dispatch(
														setSettings({
															notifications: {
																...notifications,
																mail_when_receive_answer:
																	element.target.checked ? 1 : 0,
															},
														})
													);
												}}

											/>
										}
										labelPlacement="end"
										label="Recevoir une notification par email lorsque vous recevez une réponse"
									/>
									<FormControlLabel
										control={
											<Switch
												checked={user?.settings?.notifications?.push_when_receive_answer == 1}
												onChange={(element) => {
													setNotifications({
														...notifications,
														push_when_receive_answer: element.target.checked ? 1 : 0,
													});
													dispatch(
														setSettings({
															notifications: {
																...notifications,
																push_when_receive_answer:
																	element.target.checked ? 1 : 0,
															},
														})
													);
												}}

											/>
										}
										labelPlacement="end"
										label="Recevoir une notification push lorsque vous recevez une réponse"
									/>
								</FormGroup>
							</CardContent>
						</Card>

						<Card className="settings_card">
							<CardHeader title="Photos & Vidéos" />

							<CardContent>
								<strong className="title">
									Qualité des images enregistrées
								</strong>
								<Select
									classNamePrefix="react-select"
									id="select_photo_quality"
									options={availablePhotoQuality}
									value={
										availablePhotoQuality.find(availablePhotoQuality => availablePhotoQuality.value === user.settings?.photo?.quality)
									}
									onChange={(element: any) => {
										setPhotoQuality(element.value);
										dispatch(
											setSettings({ photo: { quality: element.value } })
										);
									}}
									theme={(theme) => ({
										...theme,
										borderRadius: 0,
										colors: {
											...theme.colors,
											primary25: "#e238324f",
											primary50: "#e2383287",
											primary: COLORS[0],
										},
									})}
								/>

								<div className="separator"></div>

								<strong className="title">
									Qualité des vidéos enregistrées
								</strong>
								<Select
									classNamePrefix="react-select"
									id="select_video_quality"
									options={availableVideoQuality}
									value={
										availableVideoQuality.find(availableVideoQuality => availableVideoQuality.value === user.settings?.video?.quality)
									}
									onChange={(element: any) => {
										setVideoQuality(element.value);
										dispatch(
											setSettings({ video: { quality: element.value } })
										);
									}}
									theme={(theme) => ({
										...theme,
										borderRadius: 0,
										colors: {
											...theme.colors,
											primary25: "#e238324f",
											primary50: "#e2383287",
											primary: COLORS[0],
										},
									})}
								/>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Settings;
