/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, createRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import SkeletonDetails from "../Skeletons/Details";
import { convertStringToDate, formatTime } from "Utils/DateFormatter";
import Tooltip from "@mui/material/Tooltip";

/** MUI Components */
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import MuiTextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddIcon from "@mui/icons-material/Add";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

/** add medias icons */
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import MicIcon from "@mui/icons-material/Mic";
import VideocamIcon from "@mui/icons-material/Videocam";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FilePresentIcon from "@mui/icons-material/FilePresent";

/** Icons */
import StartIcon from "@mui/icons-material/Start";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";

import StarIcon from "@mui/icons-material/Star";

/** Components */
import Editor from "Components/Parts/Editor";
import Confirm from "Components/Parts/Confirm";
import Comments from "./Comments";
import Gallery from "./Gallery";
import SoundPlayer from "./SoundPlayer";
import VideoPlayer from "./VideoPlayer";
import DateEvent from "./DateEvent";
import TaskEvent from "./TaskEvent";
import ShareEvent from "./Modals/ShareEvent";
import Avatars from "Components/Parts/Avatars";
import FancyBox from "Components/Parts/Fancybox";
import AddFiles from "Components/Pages/Project/Events/AddFiles";
import UpgradeDialog from "Components/Parts/UpgradeDialog";

/** Redux */

import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
	getContractors,
	getProject,
	getTags,
} from "Redux/Reducers/Project/ProjectSlice";

import {
	getCurrentEvent,
	saveEvent,
	deleteEvent,
	setCurrentEvent,
	setIsOpen,
	isOpen,
	setModalAction,
} from "Redux/Reducers/Project/EventsSlice";

import { setPictures, setVideo } from "Redux/Reducers/Lightbox/LightboxSlice";

import { getPlan, checkToken } from "Redux/Reducers/User/UserSlice";

import COLORS from "Utils/colors";
import { hasSomeParentTheClass } from "Utils/Globals";

import { postDuplicate, deleteFileByName } from "Api/Controllers/EventController";
import HeaderDate from "./Details/HeaderDate";
import HeaderTypeSelector from "./Details/HeaderTypeSelector";
import HeaderTask from "./Details/HeaderTask";

const commentType: any = [];
const contributorIdsArray = [] as any[];
const tagsArray = [] as string[];

const fileTypes = [
	{ value: "estimate", label: "Estimation" },
	{ value: "invoice", label: "Dépense" },
	{ value: "other", label: "Autre" },
];

function Details() {
	/** VARS */
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const currentPlan = useAppSelector(getPlan);
	const project = useAppSelector(getProject);
	const event = useAppSelector(getCurrentEvent);
	const contributorsList = useAppSelector(getContractors);
	const open = useAppSelector(isOpen);
	const allTags = useAppSelector(getTags);

	/** Refs */

	const eventTitleInput: any = createRef();
	const filter = createFilterOptions<any>();

	/** STATE */
	const [showNav, setShowNav] = useState(false);
	const [editContractors, setEditContractors] = useState(false);
	const [editTitle, setEditTitle] = useState(false);
	const [editContent, setEditContent] = useState(false);
	const [clearContent, setClearContent] = useState(false);
	const [loading, setLoading] = useState(true);
	const [avatarsContractors, setAvatarsContractors] = useState<any>([]);
	const [contributorsOptions, setContributorsOptions] =
		useState(contributorIdsArray);
	const [selectedContributors, setSelectedContributors] =
		useState(contributorIdsArray);

	const [tags, setTags] = useState(tagsArray);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [shareOpen, setShareOpen] = useState(false);
	const [fileOpen, setFileOpen] = useState(false);
	const [fileType, setFileType] = useState("picture");

	const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false);
	const [editorContent, setEditorContent] = useState("");
	const [isEditing, setIsEditing] = useState(false);

	const [hasChanged, setHasChanged] = useState(false);

	const [detailsMediaActive, setDetailsMediaActive] = useState("picture");

	const [fileToDelete, setFileToDelete] = useState({} as any);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);

	const { t } = useTranslation();

	const boxControl = useAnimation();
	const detailsControl = useAnimation();

	const tooltipSave = t("EVENT.ACTIONS.save_tooltip");
	const tooltipShare =
		parseInt(currentPlan?.email) === 0
			? t("EVENT.ACTIONS.share_with_contractor_tooltip_upgrade")
			: t("EVENT.ACTIONS.share_with_contractor_tooltip");

	const [medias, setMedias] = useState<any>({
		picture: [],
		video: [],
		sound: [],
		file: [],
	});

	/** FUNCTIONS */

	/** Modal functions */
	const openDetails = () => {
		setClearContent(false);
		setHasChanged(false);

		boxControl.start({
			zIndex: 9,
			opacity: 1,
			transition: { duration: 0.3 },
		});
		setTimeout(() => {
			detailsControl.start({
				x: 0,
				opacity: 1,
				transition: { duration: 0.2 },
			});
		}, 400);
	};

	const closeDetails = () => {
		setIsEditing(false);
		setShowNav(false);
		setEditContractors(false);
		setSelectedContributors(contributorIdsArray);
		setEditTitle(false);
		setEditContent(false);
		setClearContent(true);
		dispatch(setIsOpen(false));

		// Clean gallery
		dispatch(setVideo({} as any));
		dispatch(setPictures([] as any));

		hasChanged && dispatch(setModalAction("edit"));
		hasChanged && dispatch(saveEvent({}));

		//!hasChanged && dispatch(setCurrentEvent({}));

		detailsControl.start({
			x: "50%",
			opacity: 0,
			transition: { duration: 0.3 },
		});

		setTimeout(() => {
			boxControl.start({
				zIndex: -1,
				opacity: 0,
				transition: { duration: 0.2 },
			});
		}, 400);
	};

	/** content Functions */
	const handleHtml = (content = "") => {
		return { __html: content };
	};

	const handleChangeContributors = (selected: any) => {
		const newEvent: any = structuredClone(event);
		!newEvent.attr && (newEvent.attr = {});
		newEvent.attr.contractors = selected.map((element: any) => {
			return element.value;
		});
		setHasChanged(true);
		dispatch(setCurrentEvent(newEvent));
		setEditContractors(false);
	};

	const handleCloseOpenElements = (element: any) => {
		const classForNav = ["event_actions_menu", "event_actions_trigger"];
		!hasSomeParentTheClass(element.target, classForNav) && setShowNav(false);
	};

	/** Event edition functions */

	const requestUpdate = () => {
		dispatch(setModalAction("edit"));
		dispatch(saveEvent({}));
		closeDetails();
	};

	const requestDelete = () => {
		closeDetails();

		setTimeout(() => {
			dispatch(setModalAction("delete"));
			dispatch(deleteEvent({}));
			dispatch(checkToken({}));
		}, 500);
	};

	const requestDuplicate = () => {
		postDuplicate(event.id).then((response: any) => {
			if (response.status === "success") {
				dispatch(setModalAction("add"));
				closeDetails();
			}
		});
	};

	const requestTransform = (type: string) => {
		const currentEvent = { ...event, type: type };
		dispatch(setCurrentEvent(currentEvent));
		dispatch(saveEvent({}));
	};

	const adaptMedias = (mediasArray: any) => {
		const newMedias: any = {
			picture: [],
			video: [],
			sound: [],
			file: [],
		};

		let array =
			typeof mediasArray == "object" ? Object.values(mediasArray) : mediasArray;
		if (array && Array.isArray(array)) {
			mediasArray.map((file: any) => {
				if (file.type && typeof file.type === "string") {
					if (file.type.split("image").length > 1) {
						newMedias.picture.push(file);
					} else if (file.type.split("video").length > 1) {
						newMedias.video.push(file);
					} else if (file.type.split("audio").length > 1) {
						newMedias.sound.push(file);
					} else {
						newMedias.file.push(file);
					}
				}

				return file;
			});
		}
		setMedias(newMedias);
	};

	const handleDeleteFile = (file: any) => {
		deleteFileByName(event.id, file.name).then((result: any) => {
			const returnedFiles =
				typeof result.attr === "string"
					? JSON.parse(result.attr).files
					: result.attr.files;

			adaptMedias(returnedFiles);
			const newEvent = structuredClone(event);
			newEvent.attr.files = returnedFiles;
			dispatch(setCurrentEvent(newEvent));
			setHasChanged(true);
			setShowConfirmDelete(false);
			dispatch(checkToken({}));
		});
	};

	const deleteVideo = (video: any) => {
		setShowConfirmDelete(true);
		setFileToDelete(video);
	};
	/** HOOKS */

	// prepare contractors
	useEffect(() => {
		const contributorsListArray =
			typeof contributorsList === "string"
				? JSON.parse(contributorsList)
				: contributorsList;
		if (contributorsListArray && Array.isArray(contributorsListArray)) {
			const contributorsArray: any = [];
			contributorsListArray.map((contributor: any, index: number) => {
				if (contributor?.email) {
					const label =
						contributor.company !== ""
							? contributor.company
							: contributor.firstname + " " + contributor.lastname;
					contributorsArray.push({
						value: contributor.id,
						label: label,
					});
				}

				return contributor;
			});
			setContributorsOptions(contributorsArray);
		}
	}, [contributorsList]);

	// toggle modal view
	useEffect(() => {
		if (open) {
			openDetails();
		}
	}, [open]);

	// Prepare events
	useEffect(() => {
		if (
			event.type === "picture" ||
			event.type === "sound" ||
			event.type === "video" ||
			event.type === "file"
		) {
			setDetailsMediaActive(event.type);
		}

		if (event.attr) {
			const attributes =
				typeof event.attr === "string" ? JSON.parse(event.attr) : event.attr;

			if (attributes) {
				let tagsAttr =
					typeof attributes.tags === "string"
						? JSON.parse(attributes.tags)
						: attributes.tags;
				let contributorsAttr =
					typeof attributes.contractors === "string"
						? JSON.parse(attributes.contractors)
						: attributes.contractors;

				if (tagsAttr) {
					setTags(tagsAttr);
				} else {
					setTags(tagsArray);
				}

				if (contributorsAttr && Array.isArray(contributorsAttr)) {
					const contractors = contributorsOptions.filter((option: any) => {
						return contributorsAttr.includes(option.value);
					});

					if (contributorsList && contributorsAttr) {
						let AvatarContractors: any = contributorsList.filter(
							(item: any) => {
								return contributorsAttr.includes(item.id);
							}
						);
						AvatarContractors = AvatarContractors.map((item: any) => {
							return { value: item.id, label: item.company, color: item.color };
						});
						setAvatarsContractors(AvatarContractors);
						setSelectedContributors(contractors);
					} else {
						setSelectedContributors(contributorIdsArray);
					}
				}

				if (attributes?.files?.length > 0) {
					adaptMedias(attributes.files);
				} else {
					setMedias({ pictures: [], video: [], sound: [], file: [] });
				}
			}
		}

		setTimeout(() => {
			setLoading(false);
		}, 1500);
	}, [event]);

	// prepare Editor
	useEffect(() => {
		event?.content ? setEditorContent(event.content) : setEditorContent("");
	}, [event]);

	/** Placeholders */

	const placeholders = {
		no_options: t("EVENT.ACTIONS.no_options"),
		delete_event_title: t("EVENT.ACTIONS.delete"),
		delete_event_confirm: t("EVENT.ACTIONS.delete_confirm_message"),
		delete_event_continue: t("EVENT.ACTIONS.delete_event_continue"),
		delete_confirm: t("EVENT.FILE.LABELS.confirm_delete"),
		delete_content: t("EVENT.FILE.LABELS.delete_content"),
		delete_confirm_action: t("GLOBAL.ACTIONS.confirm"),
	};

	return (
		<motion.div animate={boxControl} className={"eventDetailsContainer"}>
			<>
				<AddFiles
					type={fileType}
					isOpen={fileOpen}
					onClose={(newMediasAttribute: any) => {
						setDetailsMediaActive(fileType);
						adaptMedias(newMediasAttribute);
						const newEvent = structuredClone(event);
						newEvent.attr.files = newMediasAttribute;
						dispatch(setCurrentEvent(newEvent));
						setShareOpen(false);
						setFileOpen(false);
						setHasChanged(true);
						dispatch(checkToken({}));
					}}
				/>
				<ShareEvent
					open={shareOpen}
					onClose={() => {
						setShareOpen(false);
					}}
				/>
				<UpgradeDialog
					open={upgradeDialogOpen}
					onClose={() => {
						setUpgradeDialogOpen(false);
					}}
				/>

				<Confirm
					status={confirmOpen}
					onClose={() => {
						setConfirmOpen(false);
					}}
					onConfirm={requestDelete}
					title={placeholders.delete_event_title}
					content={
						placeholders.delete_event_confirm +
						' "' +
						event.title +
						'" ' +
						placeholders.delete_event_continue
					}
				/>
				<Confirm
					status={showConfirmDelete}
					title={placeholders.delete_confirm}
					content={placeholders.delete_content + fileToDelete.name + " ?"}
					onConfirm={() => {
						handleDeleteFile(fileToDelete);
					}}
					onClose={() => setShowConfirmDelete(false)}
					confirmButton={placeholders.delete_confirm_action}
				/>
			</>

			<div
				className={"overlay"}
				onClick={() => {
					closeDetails();
				}}
			></div>

			<motion.div
				animate={detailsControl}
				className={"eventDetails"}
				onClick={handleCloseOpenElements}
			>
				{loading ? (
					<SkeletonDetails />
				) : (
					<>
						<div className="details_header">
							<div className="details_infos">
								<div className="actions">
									{event.type === "task" && (
										<HeaderTask
											event={event}
											onChange={(change: boolean) => setHasChanged(change)}
										/>
									)}

									{(event.type === "picture" ||
										event.type === "file" ||
										event.type == "text") && (
											<HeaderTypeSelector
												event={event}
												onChange={(change: boolean) => setHasChanged(change)}
											/>
										)}

									{event.type === "date" && <HeaderDate event={event} />}
								</div>
							</div>
							<div className="details_actions">
								{parseInt(currentPlan?.email) === 0 && (
									<div
										className="need_upgrade"
										onClick={() => {
											navigate("/account/subscription");
										}}
									>
										<StarIcon />
										<span>Upgrade</span>
									</div>
								)}

								<Tooltip title={tooltipSave}>
									<Button
										variant="contained"
										color="secondary"
										onClick={() => {
											closeDetails();
										}}
										className="save_action"
										disableElevation
									>
										<SaveIcon />
										<span>{t("GLOBAL.ACTIONS.save")}</span>
									</Button>
								</Tooltip>

								<Tooltip title={tooltipShare}>
									<Button
										variant="contained"
										onClick={() => {
											parseInt(currentPlan?.email) === 0
												? setUpgradeDialogOpen(true)
												: setShareOpen(true);
										}}
										className="share_action"
										disableElevation
									>
										<ShareIcon />
										<span>{t("EVENT.ACTIONS.share_with_contractor")}</span>
									</Button>
								</Tooltip>

								<Button
									className="event_actions_trigger"
									onClick={() => {
										setShowNav(!showNav);
									}}
								>
									<MoreHorizIcon />
								</Button>
								<Button
									className={"closeButton"}
									onClick={() => {
										closeDetails();
									}}
								>
									<CloseIcon />
								</Button>
								{showNav && (
									<Paper
										className="event_actions_menu"
										sx={{ width: 250, maxWidth: "100%" }}
									>
										<MenuList>
											{isEditing ? (
												<MenuItem
													onClick={() => {
														dispatch(saveEvent({}));
														setIsEditing(false);
													}}
												>
													<ListItemIcon>
														<SaveIcon fontSize="small" />
													</ListItemIcon>
													<ListItemText>
														{t("GLOBAL.ACTIONS.save")}
													</ListItemText>
												</MenuItem>
											) : (
												<MenuItem
													onClick={() => {
														setIsEditing(true);
														setShowNav(false);
													}}
												>
													<ListItemIcon>
														<EditRoundedIcon fontSize="small" />
													</ListItemIcon>
													<ListItemText>
														{t("GLOBAL.ACTIONS.edit")}
													</ListItemText>
												</MenuItem>
											)}

											<MenuItem onClick={requestDuplicate}>
												<ListItemIcon>
													<ContentCopy fontSize="small" />
												</ListItemIcon>
												<ListItemText>
													{t("GLOBAL.ACTIONS.duplicate")}
												</ListItemText>
											</MenuItem>
											{(event.type === "task" || event.type === "mail") && (
												<>
													<Divider />

													<MenuItem onClick={() => requestTransform("text")}>
														<ListItemIcon>
															<ChatBubbleIcon fontSize="small" />
														</ListItemIcon>
														<ListItemText>
															{t("GLOBAL.ACTIONS.transform")} {t("TYPE.text")}
														</ListItemText>
													</MenuItem>
												</>
											)}
											{(event.type === "text" || event.type === "mail") && (
												<>
													<Divider />

													<MenuItem onClick={() => requestTransform("task")}>
														<ListItemIcon>
															<AssignmentTurnedInIcon fontSize="small" />
														</ListItemIcon>
														<ListItemText>
															{t("GLOBAL.ACTIONS.transform")} {t("TYPE.task")}
														</ListItemText>
													</MenuItem>
												</>
											)}

											<Divider />
											<MenuItem
												className="delete_action"
												onClick={() => setConfirmOpen(true)}
											>
												<ListItemIcon>
													<DeleteForeverRoundedIcon fontSize="small" />
												</ListItemIcon>
												<ListItemText>
													{t("GLOBAL.ACTIONS.delete")}
												</ListItemText>
											</MenuItem>
										</MenuList>
									</Paper>
								)}
							</div>
						</div>

						<div className={"infos"}>
							<div className="creation_action">
								<div className="creation">
								<span>
									le {convertStringToDate(event.creation_date)} à{" "}
									{formatTime(event.creation_date)}
								</span>
							</div>
							{(event.type === "text" ||
								event.type === "task" ||
								event.type === "date") && (
									<div className="add_container">
										<span>Ajouter</span>
										<Tooltip title={"ajouter une image"}>
											<button
												className="add"
												onClick={() => {
													setFileOpen(true);
													setFileType("picture");
												}}
											>
												<InsertPhotoIcon />
											</button>
										</Tooltip>

										<Tooltip title={"ajouter un enregistrement audio"}>
											<button
												className="add"
												onClick={() => {
													setFileOpen(true);
													setFileType("sound");
												}}
											>
												<MicIcon />
											</button>
										</Tooltip>
										<Tooltip title={"ajouter une vidéo"}>
											<button
												className="add"
												onClick={() => {
													setFileOpen(true);
													setFileType("video");
												}}
											>
												<VideocamIcon />
											</button>
										</Tooltip>
										<Tooltip title={"ajouter un fichier"}>
											<button
												className="add"
												onClick={() => {
													setFileOpen(true);
													setFileType("file");
												}}
											>
												<AttachFileIcon />
											</button>
										</Tooltip>
									</div>
								)}
						</div>

						<input
							className={
								isEditing || editTitle ? "edit input_title" : " input_title"
							}
							ref={eventTitleInput}
							type="text"
							value={event.title ? event.title : ""}
							placeholder="Aucun titre"
							onClick={() => {
								setEditTitle(true);
							}}
							onChange={(element: any) => {
								const newEvent = { ...event, title: element.target.value };
								setHasChanged(true);
								dispatch(setCurrentEvent(newEvent));
							}}
							onBlur={() => {
								setEditTitle(false);
							}}
						/>
						<div className="event_general_details">
							{!editContent && !isEditing && (
								<div
									className="description"
									onClick={() => setEditContent(true)}
									dangerouslySetInnerHTML={handleHtml(
										editorContent !== "<p></p>" && editorContent !== ""
											? editorContent
											: "<span style='color:grey;font-style:italic'>Cliquez ici pour entrer une description</span>"
									)}
								></div>
							)}
							{(editContent || isEditing) && (
								<Editor
									placeholder={"Description"}
									content={event.content}
									clear={clearContent}
									onEdit={(value: string) => {
										const newEvent = { ...event, content: value };
										setHasChanged(true);
										dispatch(setCurrentEvent(newEvent));
									}}
								/>
							)}
							<div className="row">
								<div className="col">
									<div
										className="item contractors"
										onClick={(element) => {
											!hasSomeParentTheClass(element.target, [
												"select_field",
												"react-select__control",
											]) && setEditContractors(!editContractors);
										}}
									>
										<strong>Intervenant(s)</strong>
										<div className="action ">
											{!editContractors && !isEditing && (
												<>
													{contributorsOptions.length === 0 ? (
														<div className="empty_contributors">
															<span>{t("EVENT.LABELS.no_contributor")}</span>
															<Link
																to={"/project/" + project.id + "/contractors"}
															>
																<Button
																	size="small"
																	variant="contained"
																	onClick={requestUpdate}
																>
																	<AddIcon />
																	{t("EVENT.ACTIONS.add_contributor")}
																</Button>
															</Link>
														</div>
													) : (
														<>
															{event.attr?.contractors &&
																event.attr?.contractors.length > 0 ? (
																<Avatars items={avatarsContractors} />
															) : (
																<Button size="small" variant="outlined">
																	+ Ajouter un intervenant
																</Button>
															)}
														</>
													)}
												</>
											)}
											{(editContractors || isEditing) && (
												<FormControl
													className="select_field"
													sx={{ m: 1, minWidth: 120 }}
												>
													<Select
														classNamePrefix="react-select"
														aria-label="Selectionner un ou plusieurs intervenants"
														placeholder="Selectionner un ou plusieurs intervenants"
														options={contributorsOptions}
														name="Contributors"
														isMulti={true}
														isSearchable={true}
														isClearable={false}
														value={selectedContributors}
														onChange={handleChangeContributors}
														onBlur={() => setEditContractors(false)}
														theme={(theme) => ({
															...theme,
															borderRadius: 0,
															colors: {
																...theme.colors,
																primary25: "#e238324f",
																primary50: "#e2383287",
																primary: COLORS[0],
															},
														})}
													/>
												</FormControl>
											)}
										</div>
									</div>
								</div>
								<div className="col">
									<div className="tags">
										<strong>Étiquettes </strong>

										<div className="action">
											<Autocomplete
												multiple
												onChange={(_event, tags) => {
													const newEvent: any = { ...event };

													const newTags = tags.map((tag: any) => {
														if (typeof tag === "string") {
															return tag;
														} else {
															return tag.inputValue;
														}
													});
													const newAttributes = {
														...newEvent?.attr,
														tags: newTags,
													};

													newEvent.attr = newAttributes;

													dispatch(setCurrentEvent(newEvent));
													setHasChanged(true);
												}}
												filterOptions={(options, params) => {
													const filtered = filter(options, params);

													const { inputValue } = params;
													// Suggest the creation of a new value
													const isExisting = options.some(
														(option) => inputValue === option?.title
													);
													if (inputValue !== "" && !isExisting) {
														filtered.push({
															inputValue,
															title: `Ajouter "${inputValue}"`,
														});
													}

													return filtered;
												}}
												selectOnFocus
												clearOnBlur
												handleHomeEndKeys
												options={allTags}
												freeSolo
												defaultValue={tags}
												value={tags}
												id="tags_event"
												getOptionLabel={(option) => {
													// Value selected with enter, right from the input
													if (typeof option === "string") {
														return option;
													}
													// Add "xxx" option created dynamically
													if (option.inputValue) {
														return option.inputValue;
													}
													// Regular option
													return option.title;
												}}
												renderOption={(props, option, { selected }) => {
													return (
														<li {...props}>
															<span>
																{typeof option === "string"
																	? option
																	: option.title}
															</span>
														</li>
													);
												}}
												renderInput={(params) => (
													<MuiTextField
														{...params}
														variant="outlined"
														placeholder="Ex.: bois, peinture"
													/>
												)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="row"></div>
						</div>

						<div className={"content " + event.type}>
							{event.type === "date" && (
								<DateEvent
									event={event}
									editing={isEditing}
									onEdit={(newEvent: any) => {
										setHasChanged(true);
										dispatch(setCurrentEvent(newEvent));
									}}
								/>
							)}
							{event.type === "task" && (
								<TaskEvent
									event={event}
									onEdit={(newEvent: any) => {
										setHasChanged(true);
										dispatch(setCurrentEvent(newEvent));
									}}
								/>
							)}
						</div>

						<div className="medias">
							<div className="tabs">
								{event.type !== "picture" &&
									event.type !== "sound" &&
									event.type !== "file" &&
									event.type !== "video" && (
										<div className="header">
											<div
												onClick={() => setDetailsMediaActive("picture")}
												className={
													detailsMediaActive === "picture"
														? "tab_title active"
														: "tab_title"
												}
											>
												<span>Image</span>
												<span className="count">
													{medias?.picture?.length
														? medias?.picture?.length
														: 0}
												</span>
											</div>
											<div
												onClick={() => setDetailsMediaActive("sound")}
												className={
													detailsMediaActive === "sound"
														? "tab_title active"
														: "tab_title"
												}
											>
												<span>Enregistrements audio</span>
												<span className="count">{medias?.sound?.length}</span>
											</div>
											<div
												onClick={() => setDetailsMediaActive("video")}
												className={
													detailsMediaActive === "video"
														? "tab_title active"
														: "tab_title"
												}
											>
												<span>vidéo</span>
												<span className="count">{medias?.video?.length}</span>
											</div>
											<div
												onClick={() => setDetailsMediaActive("file")}
												className={
													detailsMediaActive === "file"
														? "tab_title active"
														: "tab_title"
												}
											>
												<span>Fichiers</span>
												<span className="count">{medias?.file?.length}</span>
											</div>
										</div>
									)}
								<div className="content">
									<div
										className={
											detailsMediaActive === "picture"
												? "item active"
												: "item"
										}
									>
										{medias?.picture?.length > 0 ? (
											<div className="items">
												<Gallery
													pictures={medias.picture}
													onDelete={(picture: any) => {
														setShowConfirmDelete(true);
														setFileToDelete(picture);
													}}
												/>
											</div>
										) : (
											<div className="items">
												<span className="emptyMessage">Aucune image</span>
											</div>
										)}
									</div>
									<div
										className={
											detailsMediaActive === "sound" ? "item active" : "item"
										}
									>
										{medias?.sound?.length > 0 ? (
											<div className="items">
												<SoundPlayer
													sounds={medias.sound}
													onDelete={(sound: any) => {
														setShowConfirmDelete(true);
														setFileToDelete(sound);
													}}
												/>
											</div>
										) : (
											<div className="items">
												<span className="emptyMessage">
													Aucun enregistrement audio
												</span>
											</div>
										)}
									</div>
									<div
										className={
											detailsMediaActive === "video" ? "item active" : "item"
										}
									>
										{medias?.video?.length > 0 ? (
											<div className="items">
												<FancyBox>
													{medias?.video.map((video: any, index: number) => (
														<a
															className="clip-content"
															data-fancybox="gallery"
															data-width="640"
															data-height="360"
															href={video.url}
															key={index}
														>
															<VideoPlayer
																video={video}
																deletable
																onDelete={deleteVideo}
															/>
														</a>
													))}
												</FancyBox>
											</div>
										) : (
											<div className="items">
												<span className="emptyMessage">Aucune vidéo</span>
											</div>
										)}
									</div>
									<div
										className={
											detailsMediaActive === "file" ? "item active" : "item"
										}
									>
										{medias?.file?.length > 0 ? (
											<div className=" items fileList">
												{medias?.file.map((file: any, index: number) => {
													return (
														<div className="file">
															<a
																target="_blank"
																download={file.name}
																href={file.url}
																key={index}
															>
																<FilePresentIcon />
																{file.name}
															</a>

															<button
																onClick={() => {
																	setShowConfirmDelete(true);
																	setFileToDelete(file);
																}}
															>
																<HighlightOffOutlinedIcon />
															</button>
														</div>
													);
												})}
											</div>
										) : (
											<div className="items">
												<span className="emptyMessage">Aucun fichier</span>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>

						<Comments />
					</div>
			</>
				)}
		</motion.div>
		</motion.div >
	);
}

export default Details;
