import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function Confirm(props: {
	status: boolean;
	onConfirm: Function;
	onReject?: Function;
	onClose: Function;
	title?: string | undefined;
	content?: string | undefined;
	cancelButton?: string | undefined;
	confirmButton?: string | undefined;
	loading?: boolean | undefined;
}) {
	const {
		status,
		onConfirm,
		onReject,
		onClose,
		title,
		content,
		cancelButton,
		confirmButton,
		loading,
	} = props;

	const { t } = useTranslation();

	useEffect(() => {
		setOpen(status);
	}, [status]);

	const [open, setOpen] = useState(status);

	const handleClose = () => {
		onClose();
	};

	const handleConfirm = () => {
		onConfirm();
		onClose();
	};

	const handleReject = () => {
		if (onReject) {
			onReject();
		}

		onClose();
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{title ? title : ""}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{content ? content : "Confirm action"}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleReject}>
						{cancelButton ? cancelButton : t("GLOBAL.ACTIONS.cancel")}
					</Button>
					<LoadingButton
						loading={loading}
						color="error"
						onClick={handleConfirm}
						autoFocus
					>
						{confirmButton ? confirmButton : t("GLOBAL.ACTIONS.continue")}
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default Confirm;
