import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

/** Components */
import Navigation from "Components/Parts/Navigation";
import LightBox from "Components/Parts/Lightbox";
import AppNotifications from "Components/Parts/AppNotifications";

/** Pages */

import Project from "./Project/Project";
import Dashboard from "./Dashboard/Index";
import Account from "./Account/Index";
import Settings from "./Settings/Index";

/** Redux */
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { isSync } from "Redux/Reducers/App/AppSlice";
import { getUser, checkToken } from "Redux/Reducers/User/UserSlice";
import { clearEvents } from "Redux/Reducers/Project/EventsSlice";
import { getProject } from "Redux/Reducers/Project/ProjectSlice";

/** Pages */
const pagesRoutes = ["project", "dashboard", "settings", "account"];

function Page() {
	const { page } = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const projectSync = useAppSelector(isSync);
	const project = useAppSelector(getProject);

	const [loaded, setLoaded] = React.useState(false);

	const currentUser = useAppSelector(getUser);

	let currentComponent: string = page ? page : "";

	if (currentComponent === "" || currentComponent === undefined) {
		currentComponent = "project";
	}

	useEffect(() => {
		dispatch(checkToken({}));
	}, [dispatch]);
	
	useEffect(() => {
		if (projectSync) {
			setLoaded(true);
			dispatch(clearEvents({}));
			if (!pagesRoutes.includes(currentComponent)) {
				navigate("/notfound");
			} else {
				if (currentUser.id > 0 && !currentUser.wizard_done) {
					navigate("/welcome");
				}
			}
		}
	}, [projectSync]);

	useEffect(() => {
		if (project.id === -1) {
			console.log("no project");
		}
	}, [project.id]);

	return (
		<div className="App">
			{loaded ? (
				<>
					<LightBox />
					<AppNotifications />
					<div className={"applicationContainer"}>
						<Navigation current={currentComponent} />
						{currentComponent === "project" && <Project />}
						{currentComponent === "account" && <Account />}
						{currentComponent === "settings" && <Settings />}
						{currentComponent === "dashboard" && <Dashboard />}
					</div>
				</>
			) : (
				<div className="loader">
					<CircularProgress />
				</div>
			)}
		</div>
	);
}

export default Page;
