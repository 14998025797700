/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import Confirm from "Components/Parts/Confirm";

import {
	convertStringToDate,
	convertStringToDatetime,
} from "Utils/DateFormatter";

import { Grid, Paper, Button, Divider, Alert, AlertTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";

import { useAppSelector, useAppDispatch } from "Redux/hooks";
import { getUser, getPlan, checkToken } from "Redux/Reducers/User/UserSlice";

import Config from "Config";

import PlanItem from "./PlanItem";

/** API */

import { getPlans } from "Api/Controllers/CategoriesController";
import { cancelPlan } from "Api/Controllers/UserController";

/** Stripe  */

function Subscription() {
	const { tab } = useParams();
	const dispatch = useAppDispatch();

	const { t } = useTranslation();
	const currentUser = useAppSelector(getUser);
	const currentPlan = useAppSelector(getPlan);

	const [plans, setPlans] = useState([]);
	const [monthlyView, setMonthlyView] = useState(true);
	const [selectedPlan, setSelectedPlan] = useState({} as any);
	const [showMessageBox, setShowMessageBox] = React.useState(true);
	const [openCancelConfirm, setOpenCancelConfirm] = useState(false);

	const reduction = true;

	const requestDelete = () => {
		cancelPlan().then(
			(result: any) => {
				dispatch(checkToken({}));
			},
			(error: any) => {
				console.log(error);
			}
		);
	};

	useEffect(() => {
		getPlans().then(
			(results: any) => {
				setPlans(results);
			},
			(error) => {
				console.log(error);
			}
		);
	}, [currentUser.id_plan]);

	const placeholders = {
		delete_confirm: t("PLAN.LABELS.confirm_cancel"),
		delete_content: t("PLAN.LABELS.cancel_content"),
		delete_account: t("PLAN.ACTIONS.cancel_request"),
	};

	return (
		<div className="subscription">
			<Confirm
				status={openCancelConfirm}
				title={placeholders.delete_confirm}
				content={placeholders.delete_content}
				onConfirm={() => {
					requestDelete();
					setOpenCancelConfirm(false);
				}}
				onClose={() => setOpenCancelConfirm(false)}
				confirmButton={placeholders.delete_account}
			/>

			{currentPlan?.status === "canceled" && (
				<Collapse in={showMessageBox}>
					<Alert variant="outlined" severity="info" onClose={() => {}}>
						<AlertTitle>{t("ACCOUNT.LABELS.cancel_request_title")}</AlertTitle>
						<p>
							{t("ACCOUNT.LABELS.cancel_request_content")}{" "}
							{convertStringToDate(currentPlan?.end_period_date)}
						</p>
					</Alert>
				</Collapse>
			)}

			{tab && tab === "success" && (
				<Collapse in={showMessageBox}>
					<Alert
						severity="success"
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => {
									setShowMessageBox(false);
								}}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
					>
						<AlertTitle>{t("ACCOUNT.PAYMENT.SUCCESS.title")}</AlertTitle>
						<p>{t("ACCOUNT.PAYMENT.SUCCESS.desc")}</p>
					</Alert>
				</Collapse>
			)}
			{tab && tab === "cancel" && (
				<Collapse in={showMessageBox}>
					<Alert
						severity="warning"
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => {
									setShowMessageBox(false);
								}}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
					>
						<AlertTitle>{t("ACCOUNT.PAYMENT.CANCEL.title")}</AlertTitle>
						<p>{t("ACCOUNT.PAYMENT.CANCEL.desc")}</p>
					</Alert>
				</Collapse>
			)}
			<div className="row row_actual_plan">
				<div className="col">
					<h3 className="title">
						{t("PLAN.LABELS.actual")}:
						<span className="actual_plan_label">{currentPlan?.name}</span>
					</h3>
				</div>
			</div>
			<Divider light />

			<div className="row row_selector_title">
				<h3 className="title">
					{t("PLAN.LABELS.select_label_1")}{" "}
					<span>{t("PLAN.LABELS.select_label_2")}</span>
				</h3>
				<div className="toggle">
					<Button
						className={monthlyView ? "active" : ""}
						onClick={() => setMonthlyView(true)}
					>
						Mois
					</Button>
					<Button
						className={!monthlyView ? "active" : ""}
						onClick={() => setMonthlyView(false)}
					>
						an
					</Button>
				</div>
			</div>
			<div className="row">
				<Grid className="plan_selector" container spacing={6}>
					{plans.map((plan: any, index: number) => {
						return (
							<PlanItem
								plan={plan}
								key={index}
								monthlyView={monthlyView}
								reduction={reduction}
								requestCancel={() => {
									setOpenCancelConfirm(true);
									console.log("request cancel");
								}}
							/>
						);
					})}
				</Grid>
			</div>
		</div>
	);
}

export default Subscription;
