/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import {motion} from "framer-motion";
import Tooltip from "@mui/material/Tooltip";

/** Components */
import AddContractor from "./ContributorsAdd";
import EditContributor from "./ContributorsEdit";

import {useAppSelector, useAppDispatch} from "Redux/hooks";
import {
	getContractors,
	setContractors,
	getProject,
} from "Redux/Reducers/Project/ProjectSlice";

import {getProfessionIcon} from "Utils/Globals";
import {defaultContractor, ContractorType} from "Types/Project";

function Contributors() {
	const {t} = useTranslation();

	const dispatch = useAppDispatch();

	const contributors = useAppSelector(getContractors);
	const project = useAppSelector(getProject);

	const [contributorDetail, setContributorDetail] = useState(defaultContractor);

	const [selectedIndex, setSelectedIndex] = useState(-1);

	const deleteContributor = (index: number) => {
		let newArray = [...contributors];
		newArray = newArray.filter(function (item, filterIndex) {
			return filterIndex !== index;
		});
		dispatch(setContractors(newArray));
	};

	return (
		<div className="tabContent">
			<EditContributor
				contributor={contributorDetail}
				open={contributorDetail.id ? true : false}
				onClose={(refresh: boolean) => {
					setContributorDetail(defaultContractor);
					setSelectedIndex(-1);
				}}
				onDelete={(index: number) => deleteContributor(index)}
				onSave={(contributor: any, index: number) => {
					const newContributors = [...contributors];
					newContributors[index] = contributor;
					dispatch(setContractors(newContributors));
				}}
				index={selectedIndex}
			/>

			<AddContractor
				externalButton={false}
				visibility={false}
				onClose={() => {}}
			/>

			<div className="contributors_content">
				<h3>Liste des intervenants</h3>
				{!contributors || contributors.length === 0 ? (
					<span className="emptyProject">
						Ce projet n'a pas encore d'intervenants.
					</span>
				) : (
					<div className={"listContainer"}>
						<div className="columns">
							<div className="item">
								<div className="function">
									<span>{t("LIST.function")}</span>
								</div>
								<div className="firstname"></div>
								<div className="company">
									<span>{t("LIST.company")}</span>
								</div>
								<div className="firstname">
									<span>{t("LIST.firstname")}</span>
								</div>
								<div className="lastname">
									<span>{t("LIST.lastname")}</span>
								</div>
								<div className="email">
									<span>{t("LIST.email")}</span>
								</div>
								<div className="phone">
									<span>{t("LIST.phone")}</span>
								</div>
							</div>
						</div>
						<div className="list">
							{Array.isArray(contributors) &&
								contributors.map((contributor: any, index: number) => {
									return (
										<motion.div
											key={index}
											className="item"
											whileTap={{scale: 0.95}}
											onClick={() => {
												setSelectedIndex(index);
												setContributorDetail(contributor);
											}}
										>
											<div className="function">
												<Tooltip title={contributor.function}>
													<div
														className="contractor_thumbnail"
														style={{
															backgroundColor: contributor.color,
															color: "#FFF",
														}}
													>
														{contributor.function_id &&
														contributor.function_id > 0 ? (
															<img
																src={getProfessionIcon(contributor.function_id)}
																alt={contributor.function}
																title={contributor.function_id}
															/>
														) : (
															<span>{contributor.company.substring(0, 2)}</span>
														)}
													</div>
												</Tooltip>
											</div>
											<div className="firstname">
												<span>{contributor.function}</span>
											</div>
											<div className="company">
												<span>{contributor.company}</span>
											</div>
											<div className="firstname">
												<span>{contributor.firstname}</span>
											</div>
											<div className="lastname">
												<span>{contributor.name}</span>
											</div>
											<div className="email">
												<span>{contributor.email}</span>
											</div>
											<div className="phone">
												<span>{contributor.phone}</span>
											</div>
										</motion.div>
									);
								})}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default Contributors;
