const config = {
	/** Google API */
	googleApi: "AIzaSyB8qhtIGUbu9IjoloWV0yJRBDwO8BfJCLc",
	googleClientId:
		"806881025493-p1en6mkfveb3n6buga3ucrulvqd2ui1q.apps.googleusercontent.com",

	/** Stripe */
	stripe: {
		publicKey:
			//"pk_live_51N1ToPAOOYbwIaEOiOmdeYrQVloZrskdnHmFFcqtsvCJEnoIA2hIMDVb86wkEto9voUMkwZhlmIz0SAY2j238grU003IVZRpKQ"
			"pk_test_51N1ToPAOOYbwIaEOhaVm0C8bknXr3ys7iNHoiZiH3WSfhIXFay2Q5mMSmqEYkpIYo8wWMnWXiSd58UBbfGLbJNCI00tz4PBsYD"
	},
	/** Facebook API */
	facebookAppId: "1220240088693614",

	/** Apple ID*/
	appleClientId: "com.sayto.mobile",
	appleRedirectUri: "https://my.saytoapp.com/login",


	/** API URL */
	apiUrl: "https://api.saytoapp.com/",
	baseUrl: "https://my.saytoapp.com/",
};

export default config;
